import { TrashIcon } from '@heroicons/react/24/outline'
import React from 'react'
import CopyButton from '~/components/copy-button'
import type { Friend } from '~/entities/friend'
import { useStore } from '~/store'
import Button from '../button'

export default function FriendList({ friends }: { friends: Friend[] }) {
  const removeFriend = useStore((state) => state.removeFriend)
  return (
    <div className="py-4">
      <div className="py-4">
        <p className="mb-4 text-xl font-semibold">Friend list</p>
        <ol className="">
          {friends.map((friend) => (
            <li className="mb-4 flex w-full flex-col" key={friend.id}>
              <div className="flex items-center justify-between gap-1">
                <p className="font-semibold">{friend.name}</p>
                <Button
                  onClick={() => {
                    if (window.confirm(`Remove ${friend.name}?`)) {
                      removeFriend(friend)
                    }
                  }}
                >
                  <TrashIcon className="h-4 w-4 text-red-500" />
                </Button>
              </div>
              <div className="mt-2 flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <input
                    type="text"
                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={friend.bitField.asBase64Url()}
                  />
                </div>
                <CopyButton value={friend.bitField.asBase64Url()} size="sm" />
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}
