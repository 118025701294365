class BitField {
  private buffer: ArrayBuffer
  private view: Uint8Array
  private size: number

  constructor(size: number) {
    // Calculate the number of bytes needed to store the given number of bits
    const byteLength = Math.ceil(size / 8)
    this.buffer = new ArrayBuffer(byteLength)
    this.view = new Uint8Array(this.buffer)
    this.size = size // Store the size (number of bits)
  }

  set(bitIndex: number, value: boolean): void {
    if (bitIndex < 0 || bitIndex > this.size) {
      throw new RangeError(`bitIndex ${bitIndex} is out of bounds`)
    }

    const byteIndex = Math.floor(bitIndex / 8)
    const bitPosition = bitIndex % 8

    if (value) {
      this.view[byteIndex] |= 1 << bitPosition // Set the bit
    } else {
      this.view[byteIndex] &= ~(1 << bitPosition) // Clear the bit
    }
  }

  get(bitIndex: number): boolean {
    if (bitIndex < 0 || bitIndex >= this.size) {
      throw new RangeError(`bitIndex ${bitIndex} is out of bounds`)
    }

    const byteIndex = Math.floor(bitIndex / 8)
    const bitPosition = bitIndex % 8

    const byte = this.view[byteIndex]
    if (byte === undefined) {
      throw new Error(`byteIndex ${byteIndex} is undefined`)
    }

    return (byte & (1 << bitPosition)) !== 0
  }

  asBase64Url(): string {
    const base64 = btoa(String.fromCharCode(...this.view))
    return base64.replace(/\+/g, '-').replace(/\//g, '_')
  }

  static fromBase64Url(base64Url: string, size: number): BitField {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const paddedBase64 = base64.padEnd(base64.length + ((4 - (base64.length % 4)) % 4), '=')

    const binaryString = atob(paddedBase64)
    const byteLength = Math.ceil(size / 8)
    const buffer = new ArrayBuffer(byteLength)
    const view = new Uint8Array(buffer)

    for (let i = 0; i < binaryString.length; i++) {
      view[i] = binaryString.charCodeAt(i)
    }

    const bitField = new BitField(size)
    bitField.buffer = buffer
    bitField.view = view

    return bitField
  }
}

export { BitField }
