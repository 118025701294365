import { useEffect, useState } from 'react'
import { actSchema, dtrhDayBounds, type Act, type DTRHDay, type DTRHStageName } from '~/data/dtrh2024'
import { useStore } from '~/store'
import acts from '../data/acts.json'

export interface ActFilters {
  stage: DTRHStageName[]
  day?: DTRHDay
  startTime?: Date
  endTime?: Date
  name?: string
  liked?: boolean
  likedByFriends?: boolean
  likedFilterBehavior?: 'union' | 'intersection'
}

export const allActs = acts.map((act) => actSchema.parse(act))

export const useFilteredActs = (initialFilters: ActFilters) => {
  const [filteredActs, setFilteredActs] = useState<Act[]>(allActs)

  const personalBitField = useStore((state) => state.getPersonalBitField())
  const friends = useStore((state) => state.getFriends())

  const [filters, setFilters] = useState<ActFilters>(initialFilters)

  const nameFilter = (act: Act) => {
    if (!filters.name) return true

    return act.name.toLowerCase().trim().includes(filters.name?.toLowerCase().trim())
  }

  const dayFilter = (act: Act) => {
    if (!filters.day) return true

    const dayBounds = dtrhDayBounds.find((bounds) => bounds.start.toISOString().split('T')[0] === filters.day)

    return dayBounds && act.startTime >= dayBounds.start && act.endTime <= dayBounds.end
  }

  const stageFilter = (act: Act) => {
    return filters.stage.includes(act.stage)
  }

  const startTimeFilter = (act: Act) => {
    if (!filters.startTime) return true

    return filters.startTime && act.startTime >= filters.startTime
  }

  const endTimeFilter = (act: Act) => {
    if (!filters.endTime) return true

    return filters.endTime && act.endTime <= filters.endTime
  }

  const likedFilter = (act: Act) => {
    if (!filters.liked && !filters.likedByFriends) return true

    if (filters.liked && filters.likedByFriends) {
      if (!filters.likedFilterBehavior) return true

      if (filters.likedFilterBehavior === 'union') {
        return personalBitField.get(act.id) || friends.find((friend) => friend.bitField.get(act.id))
      } else {
        return personalBitField.get(act.id) && friends.find((friend) => friend.bitField.get(act.id))
      }
    }

    if (filters.liked) {
      return personalBitField.get(act.id)
    }

    if (filters.likedByFriends) {
      return friends.find((friend) => friend.bitField.get(act.id))
    }
  }

  useEffect(() => {
    setFilteredActs(
      allActs.filter(
        (act) =>
          nameFilter(act) &&
          dayFilter(act) &&
          stageFilter(act) &&
          startTimeFilter(act) &&
          endTimeFilter(act) &&
          likedFilter(act)
      )
    )
  }, [filters])

  return { filteredActs, filters, setFilters }
}
