import React, { useEffect } from 'react'
import ArtistGrid from '~/components/artist-grid'
import Header from '~/components/header'
import { PageContainer } from '~/components/page-container'
import PageTitle from '~/components/page-title'
import { dayPickerDays, dtrhStages } from '~/data/dtrh2024'
import { useFilteredActs } from '~/hooks/useFilteredActs'
import { useStickyState } from '~/hooks/useStickyState'

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PhotoIcon as OutlinePhotoIcon,
  Squares2X2Icon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'
import { PhotoIcon as SolidPhotoIcon } from '@heroicons/react/24/solid'
import Button from '~/components/button'
import FilterSidebar from '~/components/filter-sidebar'
import SortingMenu, { sortingOptions, type SortingOption } from '~/components/sorting-menu'
import { useStore } from '~/store'

function DayPicker() {
  const selectedDay = useStore((state) => state.programDayFilter)
  const setSelectedDay = useStore((state) => state.setProgramDayFilter)
  const previousDay = () => {
    if (selectedDay === 'all') {
      setSelectedDay('2024-07-07')
    } else if (selectedDay === '2024-07-07') {
      setSelectedDay('2024-07-06')
    } else if (selectedDay === '2024-07-06') {
      setSelectedDay('2024-07-05')
    } else if (selectedDay === '2024-07-05') {
      setSelectedDay('all')
    }
  }

  const nextDay = () => {
    if (selectedDay === 'all') {
      setSelectedDay('2024-07-05')
    } else if (selectedDay === '2024-07-05') {
      setSelectedDay('2024-07-06')
    } else if (selectedDay === '2024-07-06') {
      setSelectedDay('2024-07-07')
    } else if (selectedDay === '2024-07-07') {
      setSelectedDay('all')
    }
  }

  return (
    <div className="flex items-center gap-x-4">
      <Button onClick={() => previousDay()} title="Previous day">
        <ArrowLeftIcon className="h-4 w-4 sm:h-5 sm:w-5" />
      </Button>
      <p className="select-none text-lg font-semibold tracking-tight sm:text-2xl">{dayPickerDays[selectedDay].label}</p>
      <Button onClick={() => nextDay()} title="Next day">
        <ArrowRightIcon className="h-4 w-4 sm:h-5 sm:w-5" />
      </Button>
    </div>
  )
}

export default function Program() {
  const programDayFilter = useStore((state) => state.programDayFilter)
  const programFilterBehaviour = useStore((state) => state.programFilterBehaviour)
  const programShowOnlyConfiguredActs = useStore((state) => state.programShowOnlyConfiguredActs)
  const programShowOnlyFriendlyConfiguredActs = useStore((state) => state.programShowOnlyFriendlyConfiguredActs)
  const { filters, filteredActs, setFilters } = useFilteredActs({
    day: programDayFilter === 'all' ? undefined : programDayFilter,
    liked: programShowOnlyConfiguredActs,
    likedByFriends: programShowOnlyFriendlyConfiguredActs,
    likedFilterBehavior: programFilterBehaviour,
    stage: dtrhStages,
  })
  const [sorting, setSelectedSorting] = useStickyState<SortingOption>(sortingOptions[0], 'sorting')
  const compactMode = useStore((state) => state.compactMode)
  const setCompactMode = useStore((state) => state.setCompactMode)
  const hidePhotos = useStore((state) => state.hidePhotos)
  const setHidePhotos = useStore((state) => state.setHidePhotos)

  useEffect(() => {
    setFilters({
      ...filters,
      likedFilterBehavior: programFilterBehaviour,
    })
  }, [programFilterBehaviour])

  useEffect(() => {
    setFilters({
      ...filters,
      likedByFriends: programShowOnlyFriendlyConfiguredActs,
    })
  }, [programShowOnlyFriendlyConfiguredActs])

  useEffect(() => {
    setFilters({
      ...filters,
      liked: programShowOnlyConfiguredActs,
    })
  }, [programShowOnlyConfiguredActs])

  useEffect(() => {
    setFilters({ ...filters, day: programDayFilter === 'all' ? undefined : programDayFilter })
  }, [programDayFilter])

  const sortedActs = filteredActs.sort((a, b) => {
    if (sorting === 'Alphabetical') return a.name.localeCompare(b.name)
    if (sorting === 'Stage') return a.stage.localeCompare(b.stage)
    if (sorting === 'Time') return Number(a.startTime) - Number(b.startTime)
    return 0
  })

  return (
    <>
      <Header>
        <PageTitle>Program</PageTitle>
      </Header>
      <PageContainer>
        <div className="flex w-full items-center justify-between gap-2 sm:justify-start">
          <PageTitle className="hidden sm:ml-0 sm:mr-auto sm:block">Program</PageTitle>
          <DayPicker />
          <div className="flex items-center gap-2">
            <SortingMenu selectedSorting={sorting} setSelectedSorting={setSelectedSorting} />
            <FilterSidebar filters={filters} setFilters={setFilters} />
            <Button onClick={() => setCompactMode(!compactMode)} title="Set compact mode">
              {!compactMode ? (
                <TableCellsIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              ) : (
                <Squares2X2Icon className="h-4 w-4 sm:h-5 sm:w-5" />
              )}
            </Button>
            <Button onClick={() => setHidePhotos(!hidePhotos)} title="Set compact mode">
              {hidePhotos ? (
                <OutlinePhotoIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              ) : (
                <SolidPhotoIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              )}
            </Button>
          </div>
        </div>
      </PageContainer>
      <ArtistGrid acts={sortedActs} compactMode={compactMode} />
    </>
  )
}
