import React from 'react'
import FriendsComponent from '~/components/friends/index'
import Header from '~/components/header'
import { PageContainer } from '~/components/page-container'
import PageTitle from '~/components/page-title'

export default function Friends() {
  return (
    <>
      <Header>
        <PageTitle>Friends</PageTitle>
      </Header>
      <PageContainer>
        <FriendsComponent />
      </PageContainer>
    </>
  )
}
