import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Friends from './routes/friends'
import Home from './routes/index'
import Program from './routes/program'
import TimetablePage from './routes/timetable'

const container = document.getElementById('root')

if (!container) {
  throw new Error('No root element found')
}

// TODO: For now this holds all of the routing logic. We can probably render some static stuff such as
// the header and footer on all routes, and then render the actual content based on the route.
const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/friends', element: <Friends /> },
  { path: '/program', element: <Program /> },
  { path: '/timetable', element: <TimetablePage /> },
])

const root = createRoot(container)
root.render(<RouterProvider router={router} />)
