import { format } from 'date-fns'
import React from 'react'
import { type DTRHAct } from '~/data/dtrh2024'
import { useStore } from '~/store'
import { ArtistStar } from './artist-star'
import Popup from './popup'

interface ArtistDetailPopupProps {
  artist: DTRHAct
  isOpen: boolean
  onClose: () => void
}

function ArtistDetailPopup({ artist, isOpen, onClose }: ArtistDetailPopupProps) {
  const isLiked = useStore((state) => state.getArtistConfiguration(artist.id))
  const toggleArtistConfiguration = useStore((state) => state.toggleArtistConfiguration)
  const interestedFriends = useStore((state) => state.getInterestedFriends(artist.id))
  const timeString = `${format(artist.startTime, 'eeee HH:mm')} - ${format(artist.endTime, 'HH:mm')}`

  return (
    <Popup isOpen={isOpen} closePopup={() => onClose()}>
      <div className="flex w-full flex-col gap-4">
        <img
          src={`${artist.id}.webp`}
          alt={artist.name}
          className="h-[190px] w-full rounded-md object-cover shadow-md md:h-[300px]"
        />
        <div className="mx-auto flex w-full max-w-md flex-col gap-4">
          <div className="flex items-center justify-between gap-4">
            <h3 className="text-md font-semibold leading-6 text-gray-900">{artist.name}</h3>
            <button onClick={() => toggleArtistConfiguration(artist.id)}>
              <ArtistStar isLiked={isLiked} />
            </button>
          </div>
          <p className="text-sm font-semibold">{artist.stage}</p>
          <p className="text-sm font-semibold">{timeString}</p>
          {interestedFriends.length > 0 && (
            <div>
              <h4 className="text-md font-semibold leading-6 text-gray-900">Friends who are interested:</h4>
              <ul>
                {interestedFriends.map((friend) => (
                  <li key={friend.id}>{friend.name}</li>
                ))}
              </ul>
            </div>
          )}
          <p className="text-base">{artist.description}</p>
          <ArtistSocials socials={artist.socials} />
        </div>
      </div>
    </Popup>
  )
}

function ArtistSocials({ socials }: { socials: DTRHAct['socials'] }) {
  if (!socials) {
    return null
  }

  return (
    <div className="my-4 flex gap-4">
      {Object.entries(socials).map(([platform, url]) => {
        return (
          <a
            key={url}
            className="text-white hover:text-yellow-500"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={`${platform}.svg`} className="h-6 w-6" />
          </a>
        )
      })}
    </div>
  )
}

export default ArtistDetailPopup
