[
  {
    "id": 0,
    "description": "Met de gelijknamige muziekstijl heeft het Londense Jungle niets van doen. En met exotisch swingende klanken uit verre beboste oorden evenmin. Maar daarmee vertellen we niets nieuws, althans niet aan de vele miljoenen fans van dit in bloedaanstekelijke soul, funk en – vooral – disco gespecialiseerde herendubbel. De weg naar de top verloopt intussen crescendo, mede dankzij de euforische liveshows in volledige bandbezetting. Dan blijkt nog eens extra hoe geniaal hun gelaagde en piekfijn gearrangeerde dancepop in elkaar steekt, goed voor heup, hart én hoofd. Keep moving!",
    "startTime": "2024-07-06T21:45:00.000Z",
    "endTime": "2024-07-06T22:55:00.000Z",
    "name": "JUNGLE",
    "socials": {
      "instagram": "https://www.instagram.com/jungle4eva/",
      "facebook": "https://www.facebook.com/jungle4eva/",
      "twitter": "https://twitter.com/jungle4eva",
      "website": "https://www.junglejunglejungle.com/",
      "spotify": "https://open.spotify.com/artist/59oA5WbbQvomJz2BuRG071?si=rQKOtKpAQ8yv7_o4UqbUlw"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2481_crop_1600-696-144-607_fit_1400-540-center_85-JUNGLE%20PRESS%2024%20FULL_site.jpg"
  },
  {
    "id": 1,
    "description": "Alweer lang niets nieuws vernomen van LCD Soundsystem, het New Yorkse dancerockmonster rond meesterproducer/zanger James Murphy, afgezien van de filmsoundtracksingle ‘New Body Rhumba’ (2022). Niet dat de band ook live stil heeft gezeten, integendeel, ook al liet Murphy in de nasleep van het laatste album ‘American Dream’ (2017) en bijbehorende tour weten dat optredens wat hem betreft pas weer zin hadden als er nieuw plaatwerk te promoten viel. Maar het livebloed blijft bij hem gelukkig spetteren waar het niet gaan kan, plaat of geen plaat.Foto door Tori McGraw",
    "startTime": "2024-07-05T21:00:00.000Z",
    "endTime": "2024-07-05T22:55:00.000Z",
    "name": "LCD Soundsystem",
    "socials": {
      "instagram": "https://www.instagram.com/lcdsoundsystem/",
      "facebook": "https://www.facebook.com/lcdsoundsystem/",
      "twitter": "https://twitter.com/lcdsoundsystem",
      "website": "https://lcdsoundsystem.com/",
      "spotify": "https://open.spotify.com/artist/066X20Nz7iquqkkCW6Jxy6?si=0JISq5XTQF6FyawQ10nxyw"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2462_crop_1600-695-194-390_fit_1400-540-center_85-LCD%20Soundsystem%20-%20Live%20Photo%20%28Credit%20Tori%20McGraw%29%20%281_site.jpg"
  },
  {
    "id": 2,
    "description": "Michael Kiwanuka, de tijdloze Londense soulman met de behaaglijke stem, weet je zelfs op de warmste zomerdag een knus openhaardgevoel te bezorgen. Dat komt natuurlijk ook door zijn melancholieke liedjes met 70’s-vibe en knapperende melodieën die zich al bij eerste beluistering diep in je binnenste nestelen. De doorbraakhit ‘Cold Little Heart’ (2017) bijvoorbeeld, of het hypnotiserend mooie ‘I’ve Been Dazed’ van zijn Mercury Prize-winnende album ‘Kiwanuka’ (2019). Muziek kortom als een thuis weg van huis. Wees welkom.",
    "startTime": "2024-07-06T19:30:00.000Z",
    "endTime": "2024-07-06T20:45:00.000Z",
    "name": "Michael Kiwanuka",
    "socials": {
      "instagram": "https://www.instagram.com/michaelkiwanuka/",
      "facebook": "https://www.facebook.com/MichaelKiwanuka/",
      "twitter": "https://twitter.com/michaelkiwanuka",
      "website": "https://www.michaelkiwanuka.com/",
      "spotify": "https://open.spotify.com/artist/0bzfPKdbXL5ezYW2z3UGQj?si=6ypZwwsATuCU3xwnI_XImQ"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2466_crop_1600-695-249-497_fit_1400-540-center_85-O_160719_04%20%28Credit%20Olivia%20Rose%29_site.jpg"
  },
  {
    "id": 3,
    "description": "Bij The National weet je precies wat je krijgt: bezielde topkwaliteit. Geen surprises dus in de bedachtzame, melancholieke indierock van de vijf New Yorkers? Misschien niet qua muziek, maar de plotselinge release van het bejubelde nieuwe album ‘Laugh Track’ – afgelopen september, nog geen half jaar na de vorige plaat – verraste zelfs de best ingevoerde fan. Sinterklaas viel dus vroeg, maar om niet af te dwalen: beide albums zullen de setlist op DTRH ongetwijfeld uitgebreid kleuren. Een fenomenale droomheadliner om naar uit te kijken.Foto door Graham MacIndoe",
    "startTime": "2024-07-07T19:00:00.000Z",
    "endTime": "2024-07-07T20:55:00.000Z",
    "name": "The National",
    "socials": {
      "instagram": "https://www.instagram.com/thenational/",
      "facebook": "https://www.facebook.com/thenationalofficial",
      "twitter": "https://twitter.com/TheNational",
      "website": "https://www.americanmary.com/",
      "spotify": "https://open.spotify.com/artist/2cCUtGK9sDU2EoElnk0GNB?si=1tUvnuMTTkeYi-cvCIxMmA"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2458_crop_1600-696-208-1_fit_1400-540-center_85-The_National_GrahamMacIndoe_site.jpg"
  },
  {
    "id": 4,
    "description": "Al twee keer betoverde Eefje de Visser de Groene Heuvels met haar liedjes lichter dan de lucht, waarin dagdromen de realiteit verdringen en al het tastbare doorzichtig wordt. Dit jaar zal ze haar innige band met het festival nog verder uitdiepen. Alleen op Down The Rabbit Hole speelt ze haar nieuwe show in Nederland deze zomer. En dat belooft op zijn minst groots te worden: dompel je nog eens onder in de bekroonde concertregistratie van ‘Bitterzoet’ en je weet welk ambitieniveau je mag verwachten. Foto door Alexander D'Hiet",
    "startTime": "2024-07-05T18:55:00.000Z",
    "endTime": "2024-07-05T20:00:00.000Z",
    "name": "Eefje de Visser",
    "socials": {
      "instagram": "https://www.instagram.com/eefjedevisser/",
      "facebook": "https://www.facebook.com/eefjedevisser",
      "twitter": "https://twitter.com/eefjedevisser",
      "website": "https://www.eefjedevisser.com/#home",
      "spotify": "https://open.spotify.com/artist/33KABng8GO42ojFJVcABxQ?si=BOFcII5FQVmhs8BxDANkjg"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2482_fit_1400-540-center_85-TWSS_Eefje_Pers_1_1500px_site.jpg"
  },
  {
    "id": 5,
    "description": "Haar muziek was altijd al smakelijk, dankzij de overtuigende manier waarop de Londense zangeres haar innerlijke soulpopdiva vrij baan gaf. Maar sinds Jessie Ware op haar vorige plaat ‘What’s The Pleasure?’ ook de eerste schreden op de dansvloer zette, is de beer los. Wat heet, het hek is dik van de discodam op het jongste succesalbum ‘That! Feels Good!’, met vloerkrakers als ‘Free Yourself’ en ‘Begin Again’. Live vertaald in een wervelende show vol glamour, glitter en dolle dansers. En ja, dat voelt heel goed.",
    "startTime": "2024-07-07T15:00:00.000Z",
    "endTime": "2024-07-07T16:00:00.000Z",
    "name": "Jessie Ware",
    "socials": {
      "instagram": "https://www.instagram.com/jessieware/",
      "facebook": "https://www.facebook.com/JessieWare",
      "twitter": "https://twitter.com/jessieware",
      "website": "https://www.jessieware.com/",
      "spotify": "https://open.spotify.com/artist/5Mq7iqCWBzofK39FBqblNc?si=l2hoXVbISqC3RdeicSF-aw"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2452_crop_1600-696-278-379_fit_1400-540-center_85-20230106_Jessie_Pearls_01_0103_fin%20%281%29_site.jpg"
  },
  {
    "id": 6,
    "description": "Als je je oor op de borstkas van KAYTRANADA legt, schijn je de instrumentale versie van ‘Rapper’s Delight’ te horen. Broodjeaapverhaal of niet, zeker is dat je zijn sound kunt samenvatten in één woord: groove. Hij put uit een eeuw muziekgeschiedenis en navigeert soepel van jazz en soul via disco en funk naar eigentijdse R&B en hiphop in al zijn geledingen. Verwacht een tent vol swingende heupen en tevreden knikkende nekjes. Ga er lekker tussen staan. Je doet vanzelf mee.",
    "startTime": "2024-07-06T20:45:00.000Z",
    "endTime": "2024-07-06T21:45:00.000Z",
    "name": "KAYTRANADA",
    "socials": {
      "instagram": "https://www.instagram.com/kaytranada/",
      "facebook": "https://www.facebook.com/Kaytranada",
      "twitter": "https://twitter.com/KAYTRANADA",
      "website": "https://www.kaytranada.com/",
      "spotify": "https://open.spotify.com/artist/6qgnBH6iDM91ipVXv28OMu?si=Hf0XZ5fUREmV2yPF8nJ5LQ"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2506_crop_1600-695-229-739_fit_1400-540-center_85-kay_site.jpg"
  },
  {
    "id": 7,
    "description": "Het lukt niet iedereen om de naam te onthouden, maar een optreden van Khruangbin vergeet je nooit meer. De muziek van het Texaanse trio brengt je onherroepelijk in diepe trance. Je ontspant, terwijl je er op hetzelfde moment niet bij kunt hoe die gitaar maar rondjes om die verslavend strakke basloopjes blijft hollen. Popprofessoren ruziën al jaren of je het nou psychedelische soul of soulvolle psychedelica moet noemen. In Beuningen zijn we er allang uit: dit is ronduit geniaal.Foto door David Black",
    "startTime": "2024-07-07T17:00:00.000Z",
    "endTime": "2024-07-07T18:00:00.000Z",
    "name": "Khruangbin",
    "socials": {
      "instagram": "https://www.instagram.com/khruangbin/?hl=en",
      "facebook": "https://www.facebook.com/khruangbin",
      "twitter": "https://twitter.com/khruangbin",
      "website": "https://www.khruangbin.com/",
      "spotify": "https://open.spotify.com/artist/2mVVjNmdjXZZDvhgQWiakk?si=iramqzz7S_a3dO5g01DB9A"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2479_crop_1600-696-188-256_fit_1400-540-center_85-Khruangbin_site.jpg"
  },
  {
    "id": 8,
    "description": "In de geschiedenisboeken staat PinkPantheress onder het kopje ‘schoolvoorbeeld van de TikTok-doorbraak’, maar dat leidt toch af van de kern. We hebben hier te maken met een uitzonderlijk getalenteerde vrouw die ook zonder sociale media wel boven was komen drijven. Haar idee om poppy R&B te mixen met full-spectrum drum & bass – van loom tot vernietigend – was ronduit geniaal. Dat geldt ook voor uitvoering, vol onverwachte zanglijnen en productionele geintjes. Haar liveshows zijn een masterclass multitasking: lukt het jou om mee te zingen terwijl je lijf het tempo bijbeent?",
    "startTime": "2024-07-06T18:30:00.000Z",
    "endTime": "2024-07-06T19:30:00.000Z",
    "name": "PinkPantheress",
    "socials": {
      "instagram": "https://www.instagram.com/pinkpantheress/",
      "twitter": "https://twitter.com/pinkpantheress2?lang=nl",
      "website": "https://www.youtube.com/channel/UCufuq24Cef6-CdmEiZmXTBg",
      "spotify": "https://open.spotify.com/artist/78rUTD7y6Cy67W1RVzYs7t?si=hC8ddRWFQHqOEURfzXpmlg"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2498_fit_1400-540-center_85-230718_PINKPANTHRESS_SHOT9_0014_V01_site_v3.jpg"
  },
  {
    "id": 9,
    "description": "Songschrijver, producer, zangeres extraordinaire: twintiger RAYE loopt de kantjes er bepaald niet vanaf. Aanvankelijk vooral ten dienste van anderen (van Beyoncé tot Mahalia), maar in toenemende mate voor zichzelf, als strijdbaar slachtoffer van een getroebleerd leven en seksueel grensoverschrijdend gedrag. Afgelopen jaar piekend met de wereldhit ‘Escapism’ en het indrukwekkende debuutalbum ‘My 21st Century Blues’ – een in R&B, soul, dancepop en jazz gemarineerde bekentenisplaat die de Londense diva onmiddellijk verhief tot sterrenstatus, als powervrouwboegbeeld van generatie Z. En dat met die acrobatische superstem. Puur goud.Foto door Callum Walker Hutchinson",
    "startTime": "2024-07-05T17:00:00.000Z",
    "endTime": "2024-07-05T18:00:00.000Z",
    "name": "RAYE",
    "socials": {
      "instagram": "https://www.instagram.com/raye/",
      "facebook": "https://www.facebook.com/raye",
      "twitter": "https://twitter.com/raye",
      "website": "https://rayeofficial.com/",
      "spotify": "https://open.spotify.com/artist/5KKpBU5eC2tJDzf0wmlRp2?si=IgJJ1YeFSau-uBtg0_NAxA"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2448_crop_1600-695-217-153_fit_1400-540-center_85-Press%20Shot%205%20credit%20Callum%20Walker%20Hutchinson_site%20.jpg"
  },
  {
    "id": 10,
    "description": "Al anderhalf decennium kijkt Action Bronson lachend toe hoe collega-rappers van de ene stijl naar de andere hoppen om maar populair te blijven. Zelf trekt hij zich geen klap aan van wat de massa wenst. Juist dat maakt hem zo geliefd. Zijn rapstijl is geworteld in de vroege jaren 90, maar hij laat zich niet vastpinnen op de oldschool. Action Bronson bepaalt zijn eigen regels. Als hij een gelikt R&B-refrein of een gedateerde raprockbeat wil, dan houdt niemand hem tegen. Het staat hem allemaal fantastisch.",
    "startTime": "2024-07-05T20:00:00.000Z",
    "endTime": "2024-07-05T21:00:00.000Z",
    "name": "Action Bronson",
    "socials": {
      "instagram": "https://www.instagram.com/bambambaklava/",
      "facebook": "https://www.facebook.com/ActionBronsonMusic/",
      "twitter": "https://twitter.com/actionbronson",
      "website": "https://www.actionbronson.com/",
      "spotify": "https://open.spotify.com/artist/7BMccF0hQFBpP6417k1OtQ?si=lUmOqRIxTCKIbafcWNjnYA"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2532_fit_1400-540-center_85-AB%20Headshot%202_site_v3.png"
  },
  {
    "id": 11,
    "description": "Niets kriebelt zo lekker aan de donshaartjes in je gehoorgang als die kraakheldere, iets kinderlijke stem van de Ghanees-Amerikaanse Amaarae. Niet gek dus dat haar liedjes juist in 2020 als een virus om zich heen grepen, toen we allemaal thuiszaten met een koptelefoon op onze harses. Live komt haar muziek gelukkig minstens zo goed uit de verf. De mix van R&B, pop en alté (een West-Afrikaans alles-op-een-hoopgenre dat onder meer dancehall, reggae en afrobeat herbergt) wordt fabelachtig bijeengehouden door – daar is hij weer – die uit duizenden herkenbare stem.",
    "startTime": "2024-07-07T16:00:00.000Z",
    "endTime": "2024-07-07T17:00:00.000Z",
    "name": "Amaarae",
    "socials": {
      "instagram": "https://www.instagram.com/amaarae/",
      "facebook": "https://www.facebook.com/iamamaarae",
      "twitter": "https://twitter.com/amaarae",
      "website": "https://www.amaarae.world/",
      "spotify": "https://open.spotify.com/artist/21UPYSRWFKwtqvSAnFnSvS?si=PU2zuSEMTu2HHwLCPTXDyw"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2530_crop_1600-695-209_fit_1400-540-center_85--LDU_230116_AMAARAE_05_120%20%281%29color2_site.jpg"
  },
  {
    "id": 12,
    "description": "Deze Noorse singer/songwriter was in 2016 al te zien in de Groene Heuvels, maar heeft sindsdien een komeetachtige ontwikkeling doorgemaakt – van een Oscar-genomineerd duet met Elsa in ‘Frozen II’ tot haar magistrale derde album ‘The Gods We Can’t Touch’ (2022). Dat haar etherische electropop heerlijk wegluistert en -danst, doet niets af aan het feit dat AURORA ook nogal wat te zeggen heeft: haar poëtische teksten over de kronkels in mens en maatschappij zijn even bespiegelend als meedogenloos. Een must-see dus, alleen al omdat ze met hagelnieuwe muziek naar Down The Rabbit Hole komt.",
    "startTime": "2024-07-06T14:30:00.000Z",
    "endTime": "2024-07-06T15:30:00.000Z",
    "name": "AURORA",
    "socials": {
      "instagram": "https://www.instagram.com/auroramusic/",
      "facebook": "https://www.facebook.com/iamAURORA",
      "twitter": "https://twitter.com/auroramusic",
      "website": "https://www.aurora-music.com/",
      "spotify": "https://open.spotify.com/artist/1WgXqy2Dd70QQOU7Ay074N?si=XqiKgz-dRlSqiugyy3g96g"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2528_crop_1280-557-86-547_fit_1400-540-center_85-Main%20Press%20Shot%20%281%29_site_v2.jpg"
  },
  {
    "id": 13,
    "description": "Als een van de grootste indierockfenomenen aankondigt weer te gaan musiceren na een vijfjarige pauze is dat reden voor een feestje. De slingers hingen al toen het Britse Bombay Bicycle Club ‘Everything Else Has Gone Wrong’ uitbracht, maar de versiering werd weer opgeborgen toen een zekere pandemie uitbrak. Het viertal maakte van de nood een deugd, dook de studio in voor de opnames van ‘My Big Day’ en staat te trappelen om hun lekker in het gehoor liggende laidbackliedjes live aan je te laten horen.Foto door Tom Oxley",
    "startTime": "2024-07-06T13:30:00.000Z",
    "endTime": "2024-07-06T14:30:00.000Z",
    "name": "Bombay Bicycle Club",
    "socials": {
      "instagram": "https://www.instagram.com/bombayinsta",
      "facebook": "https://www.facebook.com/bombaybicycleclub",
      "twitter": "https://twitter.com/BombayBicycle",
      "website": "https://bombaybicycle.club/",
      "spotify": "https://open.spotify.com/artist/3pTE9iaJTkWns3mxpNQlJV?si=Hkz4QAAMS9Cbz83OAKmiuw"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2518_crop_1600-695-212-26_fit_1400-540-center_85-Bombay%20Bicycle%20Club_551%20RGB%20-%20Credit%20Tom%20Oxley%20New%20Press%20Shot_site.jpg"
  },
  {
    "id": 14,
    "description": "Een kwart eeuw op aarde waarvan tien jaar actief als britpopfenomeen: Declan McKenna viert in 2024 met 'What Happened to the Beach?' een dubbel jubileum. Deze plaat verraadt wederom liefde voor ongeveer alles wat Damon Albarn doet, en klinkt daar nou een onvervalste Beatles-hook op 'Sympathy'? Maar hoho, de Brit is meer dan een optelsom van opa's platenkast. Zijn vernieuwende zangtechniek en dansbare electro-invloeden zouden door veel van zijn voorbeelden hoofdschuddend zijn afgeschoten. Moraal van dit verhaal: Declan McKenna is vooral Declan McKenna: eigenzinnig, ongefilterd en een gegarandeerde gangmaker.",
    "startTime": "2024-07-06T15:30:00.000Z",
    "endTime": "2024-07-06T16:30:00.000Z",
    "name": "Declan McKenna",
    "socials": {
      "instagram": "https://www.instagram.com/thedeclanmckenna/",
      "facebook": "https://www.facebook.com/DeclanMckennaMusic",
      "twitter": "https://twitter.com/declanmckenna",
      "website": "https://www.declanmckenna.net/",
      "spotify": "https://open.spotify.com/artist/2D4FOOOtWycb3Aw9nY5n3c?si=6DfrPGo0QQOc7wMRX1ZlDQ"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2514_crop_1600-695-287-155_fit_1400-540-center_85-Declan%20McKenna%20-%20Album%20-%20Finals%20-%20Cover%20%5BJPEG%5D_site.jpg"
  },
  {
    "id": 15,
    "description": "In het begin schiep dEUS uit een chaos van genres ‘Worst Case Scenario’ en werden ook met de twee volgende voltrefferplaten wereldwijd zieltjes gewonnen. Tijdelijk verdwenen in de liefhebberluwte, maar diverse albums en bezettingswisselingen later werd in 2023 een nieuw boek aan de goddelijke discografie toegevoegd: ‘How to Replace It’. Alles viel op zijn plaats en dEUS zag dat het goed was. Wie Tom Barman & Co onlangs aan het werk heeft gezien, weet dat de alternatieve rockband 34 jaar na oprichting beter in zijn vel zit dan ooit.",
    "startTime": "2024-07-06T16:30:00.000Z",
    "endTime": "2024-07-06T17:30:00.000Z",
    "name": "dEUS",
    "socials": {
      "instagram": "https://www.instagram.com/deus_band/",
      "facebook": "https://www.facebook.com/dEUSmusic",
      "twitter": "https://twitter.com/deusbe?lang=nl",
      "website": "https://www.deus.be/",
      "spotify": "https://open.spotify.com/artist/3zMir00BoCKhwNTjlT61KN?si=jDxCzTPjTO60Y4RNd45Cvw"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2446_fit_1400-540-center_85-jc_220629_dEUS_Bologna_022_site.jpg"
  },
  {
    "id": 16,
    "description": "Optimisme is het keyword dat in je opkomt wanneer je Folamours nieuwe producersvrucht 'Manifesto' aanzwengelt. Deze staat vol funky tracks die invloeden van vele, vele airmiles verraden. Afgelopen zomer ging DGTL nog plat voor de Fransman: soulvocalen om verliefd op te worden, exotische percussie die doet denken aan een eeuwigdurend strandfeest en blazers die zelfs doodgeslagen bier tot leven wekken. 'Friends', 'Amore', 'Isla Nostalgia' en 'Voyage' – het is zomer en dat gaan we vieren ook.",
    "startTime": "2024-07-07T20:00:00.000Z",
    "endTime": "2024-07-07T21:00:00.000Z",
    "name": "Folamour (A/V)",
    "socials": {
      "instagram": "https://www.instagram.com/folamour_fhuo/",
      "facebook": "https://www.facebook.com/folamourmusic",
      "twitter": "https://twitter.com/folamourbb",
      "website": "https://www.folamour.love/",
      "spotify": "https://open.spotify.com/artist/6pJY5At9SiMpAOBrw9YosS?si=XyppZ_4-Rw-IVCuPfvVHgQ",
      "soundcloud": "https://soundcloud.com/folamour"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2444_crop_1601-695-199-902_fit_1400-540-center_85-Copy%20of%20Folamour_1211%C2%A9Koria_site.jpg"
  },
  {
    "id": 17,
    "description": "Nadat The Gaslight Anthem in 2015 uit elkaar ging, drukte zanger Brian Fallon de hoop op nieuw materiaal stellig de kop in. De ideeën waren op, het was niet anders. Inmiddels hangt de vlag er anders bij. Een van de meest toonaangevende rockbands van dit millennium is terug met een album dat niet onderdoet voor zijn beste werk. Op ‘History Books’ vinden we een gastoptreden van Bruce Springsteen, de zanger met wie de band tot vervelens toe werd vergeleken. Cirkel rond? Noem het liever een nieuw begin.",
    "startTime": "2024-07-05T15:00:00.000Z",
    "endTime": "2024-07-05T16:00:00.000Z",
    "name": "The Gaslight Anthem",
    "socials": {
      "instagram": "https://www.instagram.com/thegaslightanthem/?hl=en",
      "facebook": "https://www.facebook.com/thegaslightanthem",
      "twitter": "https://twitter.com/gaslightanthem",
      "website": "https://www.thegaslightanthem.com/",
      "spotify": "https://open.spotify.com/artist/7If8DXZN7mlGdQkLE2FaMo?si=dwJhFyNLSL-A_8xoxKhEXg"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2442_crop_1600-695-187-1057_fit_1400-540-center_85-The%20Gaslight%20Anthem_site.jpg"
  },
  {
    "id": 18,
    "description": "Tijdens haar jeugd vol verhuizingen tussen Frankrijk, de Emiraten en Centraal-Afrika leerde Jain drummen, muziek opnemen en vooral zingen. Op een uitbundige manier, ook op derde album 'The Fool'. Reizen doet de als Jeanne Louise Galice geboren multitasker eveneens door de muziekgeschiedenis. Langs hedonistische eurodance, neofolk, heropgeleefde songfestival-euforie en Kate Bush-achtige sferen – luister de titeltrack maar. In de studio vormen al die souvenirs een verrassende collage. De orkestrale, gelaagde en opgewekte dancepop werd logischerwijs opgepikt door Jools Holland én de grootste festivals op aarde.",
    "startTime": "2024-07-07T14:00:00.000Z",
    "endTime": "2024-07-07T15:00:00.000Z",
    "name": "Jain",
    "socials": {
      "instagram": "https://www.instagram.com/jainmusic/",
      "facebook": "https://www.facebook.com/JAINMUSIC",
      "twitter": "https://twitter.com/Jainmusic",
      "website": "https://www.jain-music.com/",
      "spotify": "https://open.spotify.com/artist/2HHmvvSQ44ePDH7IKVzgK0?si=azDTePlNQGKChFVlU_VflA"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2440_crop_1600-696-259-1116_fit_1400-540-center_85-jain_site.jpg"
  },
  {
    "id": 19,
    "description": "Uitzicht op zee, voetjes omhoog, drankje in de hand; het enige dat nog mist bij deze zomerse dagdroom is de juiste soundtrack. Entrez L’Impératrice! Dit Parijse collectief maakt discopareltjes om je onder het gesmolten ijs zittende vingers bij af te likken. Live komt je innerlijke dansdiva of swingking tot leven wanneer de ritmesectie de toon zet, de keyboards hun eerste licks spuien en Flore Benguigui haar verleidelijke stemgeluid toevoegt. Tiens, ruk jezelf uit die gefantaseerde strandstoel en kom in de keizerlijke groove van L’Impératrice.Foto door Skyler Dahan",
    "startTime": "2024-07-06T17:30:00.000Z",
    "endTime": "2024-07-06T18:30:00.000Z",
    "name": "L'Impératrice",
    "socials": {
      "instagram": "https://www.instagram.com/l.imperatrice",
      "facebook": "https://www.facebook.com/Je.Suis.Imperatrice",
      "website": "https://l-imperatrice.cool/en/home/",
      "spotify": "https://open.spotify.com/artist/4PwlsrN0t5mLN0C827cbEU?si=NL8UYU7EQ5ak9vTJaD6d5w"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2502_fit_1400-540-center_85-2.%20L_Imp%C3%A9ratrice%20%E2%80%94%202022%20_%20%C2%A9%20SKYLER%20DAHAN_site.jpg"
  },
  {
    "id": 20,
    "description": "Er schuilt iets gevaarlijks in de muziek van The Last Dinner Party. Hoe vriendelijk ze er ook bij glimlachen, je hebt voortdurend het gevoel dat ze van het ene op het andere moment in een stel vampiers kunnen veranderen. De jonge Britse band combineert de branie van de vroege Arctic Monkeys met de gothic esthetiek van Kate Bush en de geniale gekte van David Bowie. Even wat namedrops om je een idee te geven, nu het nog kan. Na deze zomer is The Last Dinner Party zelf de referentie. Foto door Cal McIntyre",
    "startTime": "2024-07-07T16:00:00.000Z",
    "endTime": "2024-07-07T17:00:00.000Z",
    "name": "The Last Dinner Party",
    "socials": {
      "instagram": "https://www.instagram.com/thelastdinnerparty/",
      "facebook": "https://www.facebook.com/TheLastDinnerParty/",
      "twitter": "https://twitter.com/lastdinnerparty",
      "website": "https://www.thelastdinnerparty.co.uk/",
      "spotify": "https://open.spotify.com/artist/5SHgclK1ZpTdfdAmXW7J6s?si=ygtATswuRyWfkryglh-fEg"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2436_crop_1600-696-224-338_fit_1400-540-center_85-TLDP%20PRESS%20SHOT%20-%20Cal%20McIntyre_site.jpg"
  },
  {
    "id": 21,
    "description": "De koningin van de moderne jungle bouwde haar kasteel op de fundering die Roni Size en Shy FX legden. Nia Archives put uit de rijke traditie van het genre, zit vuistdiep in het heden en ziet van verre aankomen welke kant het op gaat – wat natuurlijk vooral komt doordat ze zelf het stuur in handen heeft. Even stijlvast als onvoorspelbaar speelt ze haar tracks soms net een tandje sneller dan je gewend bent. Als ze denkt dat jij daar behoefte aan hebt, tenminste. Dat voelt ze feilloos aan.",
    "startTime": "2024-07-05T16:00:00.000Z",
    "endTime": "2024-07-05T17:00:00.000Z",
    "name": "Nia Archives",
    "socials": {
      "instagram": "https://www.instagram.com/archives.nia/",
      "facebook": "https://www.facebook.com/archives.nia",
      "twitter": "https://twitter.com/archives_nia",
      "website": "https://www.niaarchives.com/",
      "spotify": "https://open.spotify.com/artist/7BMR0fwtEvzGtK4rNGdoiQ?si=jN7a0IaOQ3CZScWKVgmo_A"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2434_crop_1600-695-122-396_fit_1400-540-center_85-NIA_ARCHIVES_site.jpg"
  },
  {
    "id": 22,
    "description": "Een onrustige jeugd in Chicago en voortwoekerend racisme maakten van Fatimah Nyeema Warner alias Noname een strijdbare rapper en activiste. Sinds debuutmixtape 'Telefone' en het oprichten van de Noname Book Club is haar handelsmerk bekend. Een unieke en soms razendsnelle flow, ingekleurd met oprechte verontwaardiging dan wel beteugelde agressie. Die opvallende stijl floreert op een loom-jazzy ondergrond waar neosoulsister Erykah Badu allerminst patent op heeft. Sterker nog, nieuwe langspeler 'Sundial' is onmiskenbaar Noname: elke track is spatzuiver afgevuurd, recht tussen de ogen.",
    "startTime": "2024-07-05T21:45:00.000Z",
    "endTime": "2024-07-05T22:45:00.000Z",
    "name": "Noname",
    "socials": {
      "instagram": "https://www.instagram.com/nonamehiding/",
      "facebook": "https://www.facebook.com/noname/",
      "website": "https://www.nonamedimension.com/",
      "spotify": "https://open.spotify.com/artist/1EpyA68dKpjf7jXmQL88Hy?si=Ho81pp7NRN6wKqo_keQfWA"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2432_crop_1600-696-203-894_fit_1400-540-center_85-Noname%20image_6487327_site.jpg"
  },
  {
    "id": 23,
    "description": "De kroonprinses van de Britse (neo)soul noemde haar debuutalbum ‘Messy’. Dat is nogal misleidend: zelden klonk soul zo strak en goed verzorgd. Fun fact: de tweede voornaam van Olivia Dean is Lauryn, als in Lauryn Hill. Haar ouders hadden niet kunnen bevroeden dat hun dochter een kwart eeuw later met hun idool vergeleken zou worden. Met haar grandioze band zet Dean live vooral in op lagere tempo’s. Ideaal als je conditioneel een stapje terug wil doen, maar emotioneel nog wel een stootje kan hebben.",
    "startTime": "2024-07-07T18:00:00.000Z",
    "endTime": "2024-07-07T19:00:00.000Z",
    "name": "Olivia Dean",
    "socials": {
      "instagram": "https://www.instagram.com/oliviadeano/?hl=en",
      "facebook": "https://www.facebook.com/oliviadeanmusic/",
      "twitter": "https://twitter.com/oliviadeano?lang=en",
      "website": "https://www.oliviadeano.com/",
      "spotify": "https://open.spotify.com/artist/00x1fYSGhdqScXBRpSj3DW?si=Ukbx_Dm4RiiUNvHQQMCCHQ"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2534_crop_1360-591-161-325_fit_1400-540-center_85-OD_-6_site_V3.jpg"
  },
  {
    "id": 24,
    "description": "Is het Arctic Monkeys on acid? Nee, het is Psychedelic Porn Crumpets. Dit doldwaze gezelschap uit Perth kijkt met bewondering naar stadsgenoot Tame Impala, maar heeft een andere benadering van psychedelische rock. Niks wegdromen, maar dansen als een malle. Deze trippende hitsige broodjes werden dan ook al meerdere malen genomineerd als beste live-act van Australië. Op nieuwste plaat ‘Fronzoli’ heeft mastercrumpet Jack McEwan zijn meest aanstekelijke hersenspinsels vastgelegd. Warm je spieren op en sluit aan bij het bizarre feest op planeet Psychedelic Porn Crumpets.",
    "startTime": "2024-07-05T20:00:00.000Z",
    "endTime": "2024-07-05T21:00:00.000Z",
    "name": "Psychedelic Porn Crumpets",
    "socials": {
      "instagram": "https://www.instagram.com/psychedelicprncrumpets/?hl=en",
      "facebook": "https://www.facebook.com/psychedelicprncrumpets/",
      "website": "https://www.psychedelicporncrumpets.com/",
      "spotify": "https://open.spotify.com/artist/7GRrLcUxwtQLvOpO7OHO80?si=98ttMTdpQQaevuAYw-VFHg"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2430_crop_1600-696-247-1070_fit_1400-540-center_85-3d5868b2_DSC_1521_site.jpg"
  },
  {
    "id": 25,
    "description": "Yousef Gnaoui alias Sef nader introduceren? Vooruit dan, een worp uit de losse pols in Slimste Mens-stilo: Rapper, Top Notch, Flinke Namen, Tijdmachine, De Leven. En niet te vergeten de bekroonde langspeler 'Ik Zou Voor Veel Kunnen Sterven Maar Niet Voor Een Vlag'. De man die hiphop in Nederland al twee decennia lang opwindender maakt is tijdens deze editie van Down The Rabbit Hole special guest en popup-mc tegelijk. Overal en nergens, het hele weekend lang, word je verrast met zijn beats, flow en filosofische oneliners. Foto door Eva Roefs",
    "startTime": "2024-07-05T13:00:00.000Z",
    "endTime": "2024-07-05T14:00:00.000Z",
    "name": "Sef",
    "socials": {
      "instagram": "https://www.instagram.com/yousefgnaoui/",
      "facebook": "https://www.facebook.com/LikeSef/?locale=nl_NL",
      "twitter": "https://twitter.com/YousefGnaoui",
      "spotify": "https://open.spotify.com/artist/5VGhS6nHpXPbXGhku9RRB0?si=MUd_CXI8TMGGb6MZPgNwAQ"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2485_crop_1600-695-196-83_fit_1400-540-center_85-Yousef%2C%202023%20-%20Eva%20Roefs0200083_site.jpg"
  },
  {
    "id": 26,
    "description": "Zo lieflijk als de Amerikaanse Ty Segall de snaren van zijn akoestische gitaar beroert, zo hard laat hij de elektrische variant knetteren. Zijn stem is al even flexibel. Vergelijkingen met John Lennon en Thom Yorke liggen op de loer, maar Segall heeft meer noten op zijn zang. Het ene moment een streling voor je trommelvliezen, dan weer bijtend en dwingend. In januari verscheen nieuwste plaat ‘Three Bells’, vol nummers met de beproefde Segall-dressing. Succesrecept: pittige Hawkwind-mosterd, zoete Beatles-honing en een snuif psychedelisch rockende jaren 60-peper.Foto door Den e Segall",
    "startTime": "2024-07-07T18:00:00.000Z",
    "endTime": "2024-07-07T19:00:00.000Z",
    "name": "Ty Segall",
    "socials": {
      "instagram": "https://www.instagram.com/tysegall/",
      "facebook": "https://www.facebook.com/tysegall666/",
      "twitter": "https://twitter.com/dragcityrecords",
      "website": "https://ty-segall.com/pages/home",
      "spotify": "https://open.spotify.com/artist/58XGUNsRNu3cVOIOYk5chx?si=l5iL_jMoRJ62wBFt7hQeqg"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2428_fit_1400-540-center_85-c4d57bbb_Ty_Segall_Promo_1_by_Den_e_Segall_site.jpg"
  },
  {
    "id": 27,
    "description": "Je kunt je geen festivalwereld zonder The Vaccines voorstellen. Vroege hits als ‘Post Break-Up Sex’ en ‘If You Wanna’ komen al bijna vijftien jaar op ieder indiefeestje voorbij. Inmiddels dienen de eerste grijze haren zich aan, maar het schrijven van pakkende songs is de Britse band nooit verleerd. Het nieuwe album ‘Pick-Up Full Of Pink Carnations’ staat er vol mee. Terwijl hun teksten meegroeien met hun levensfase klinken ze nog altijd net zo energiek en enthousiast als altijd.",
    "startTime": "2024-07-07T13:00:00.000Z",
    "endTime": "2024-07-07T14:00:00.000Z",
    "name": "The Vaccines",
    "socials": {
      "instagram": "https://www.instagram.com/thevaccines/",
      "facebook": "https://www.facebook.com/thevaccines/",
      "twitter": "https://twitter.com/thevaccines",
      "website": "https://www.thevaccines.com/",
      "spotify": "https://open.spotify.com/artist/0Ak6DLKHtpR6TEEnmcorKA?si=8mCFV912TMCKWzocWeeNOA"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2493_crop_1600-696-217_fit_1400-540-center_85-LEAD%201_site.jpg"
  },
  {
    "id": 28,
    "description": "Waarom zou je rommelen aan een succesformule? Nou, bijvoorbeeld omdat je vader bent geworden. Frontman James Smith van Yard Act ervoer sinds de geboorte van zijn zoon een heel scala aan nieuwe emoties om te bezingen. Bovendien putte dit viertal uit Leeds genoeg zelfvertrouwen uit het succes van debuutalbum ‘The Overload’ om van het postpunkpad af te wijken en nieuwe invloeden toe te voegen. Het resultaat: ‘Where’s My Utopia’. Dikke kans dat het antwoord op die vraag in de Groene Heuvels gevonden gaat worden.Foto door Rory Barnes",
    "startTime": "2024-07-05T18:00:00.000Z",
    "endTime": "2024-07-05T19:00:00.000Z",
    "name": "Yard Act",
    "socials": {},
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2539_crop_1120-487-170-704_fit_1400-540-center_85-05f32589_Yard%20Act%20approved%201%20%28credit%20Phoebe%20Fox%29_site_v2.jpg"
  },
  {
    "id": 29,
    "description": "De meeste drumsolo’s zijn maar voor één persoon leuk: voor de drummer zelf. Maar dan is er Yussef Dayes. Je beledigt niemand als je zegt dat hij de beste drummer is die ooit voet zette in Beuningen. Virtuoos? Dat is een understatement: zo’n muzikant zie je maar eens in je leven. Hij komt met band en geeft zijn muzikanten alle ruimte, zoals het een topdrummer betaamt. Ondertussen is zijn signatuur voortdurend aanwezig. Uitermate geschikt om met open mond naar te kijken, maar stiekeme dansjes zijn geoorloofd.",
    "startTime": "2024-07-06T12:30:00.000Z",
    "endTime": "2024-07-06T13:30:00.000Z",
    "name": "Yussef Dayes",
    "socials": {
      "instagram": "https://www.instagram.com/yussefdayes/",
      "facebook": "https://www.facebook.com/yussefdayes/",
      "twitter": "https://twitter.com/yussefdayes",
      "website": "https://yussefdayes.com/",
      "spotify": "https://open.spotify.com/artist/2rspptKP0lPBdlJJAJHqht?si=guQ_SF6cQEelJNjVJyUr8Q"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2487_fit_1400-540-center_85-DSC_9350_site.jpg"
  },
  {
    "id": 30,
    "description": "Jezelf een bastaardkind noemen klinkt niet positief, maar als je net als Bad Nerves The Strokes en Ramones als fictieve ouders kiest, verandert dat de zaak. Aan goede muzikale genen geen gebrek bij dit rock-‘n-rollende gezelschap uit het Britse Essex. Het vijftal heeft een neusje voor puntige powerpopsongs. Live worden deze strak uitgevoerd door een band die zich op ieder podium, klein of groot, de ballen uit de trousers speelt. Combineer dit met oorwurmwaardige melodieën en je zou willen dat Bad Nerves nog een broertje of zusje heeft.Foto door Bekky Calver",
    "startTime": "2024-07-05T14:00:00.000Z",
    "endTime": "2024-07-05T15:00:00.000Z",
    "name": "Bad Nerves",
    "socials": {
      "instagram": "https://www.instagram.com/badbadnerves",
      "facebook": "https://www.facebook.com/badnerves",
      "twitter": "https://twitter.com/badbadnerves",
      "website": "https://badnerves.co.uk/",
      "spotify": "https://open.spotify.com/artist/7IPyXY4ZHkuvQY1ny8TnMQ?si=vzrhcB5zQBK4g5OLlJMJgw"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2526_fit_1400-540-center_85-Promo%201%20Credit_%20Bekky%20Calver_site_v2.jpg"
  },
  {
    "id": 31,
    "description": "Stranden doen wel meer relaties, maar berg je maar als je een van The Beaches dumpt. Met 'Blame My Ex' maakte het viertal een furieuze popplaat waarop de punk-attitude nooit ver weg is. In overzeese contreien heeft de band al een culthit te pakken met 'T-Shirt' en mensenmassa's opgepookt voor Foo Fighters, Alanis Morissette en Eagles Of Death Metal. Precies daartussenin manoeuvreert hun sound, en live krijg je een goudeerlijk geheel – zonder sequencers, samples of sessiemuzikanten achter een gordijn. Inpluggen, aftikken en raggen met die handel.",
    "startTime": "2024-07-07T12:00:00.000Z",
    "endTime": "2024-07-07T13:00:00.000Z",
    "name": "The Beaches",
    "socials": {
      "instagram": "https://www.instagram.com/thebeachesband?igshid=YmMyMTA2M2Y%3D",
      "facebook": "https://www.facebook.com/thebeachesband/",
      "twitter": "https://twitter.com/thebeaches?s=21&t=KwDiic-Dapdbwp54iJRtxA",
      "website": "https://www.thebeachesband.com/",
      "spotify": "https://open.spotify.com/artist/6ws5XBA70XgeBpnLZhQBoy?si=fTnDjtltRuOcNNBHk-vHIg"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2524_crop_1360-591-159-210_fit_1400-540-center_85-Main%20Press%20Photo%20sq_site_v2.jpg"
  },
  {
    "id": 32,
    "description": "De veelvuldig rondzingende dj-tip BELLA zet dit jaar met haar debuut-EP zowel jonge dancehonden als oude housebroeken in het zonnetje. Het energieverbruik van de early adopters sloeg al in het rood tijdens een dijk van een set op Lowlands – deze shinet integraal op SoundCloud. Al stuisterend (luisteren en stuiteren tegelijk) snap je meteen haar upcycling-filosofie, want er kan zomaar een typische 90s-rapper, saxofoonriff of vocaal intermezzo opduiken. Kijk ook niet raar op van een paar venijnige Eurohouse-synthakkoorden. En die beat, die beukt maar door. Oldschool genieten.",
    "startTime": "2024-07-06T22:30:00.000Z",
    "endTime": "2024-07-06T23:45:00.000Z",
    "name": "BELLA",
    "socials": {
      "instagram": "https://www.instagram.com/bellaburen/",
      "facebook": "https://www.facebook.com/bellaburenmusic/",
      "soundcloud": "https://soundcloud.com/bellaburen"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2522_fit_1400-540-center_85-BELLA%201_site.jpg"
  },
  {
    "id": 33,
    "description": "Het Rotterdamse aanjagersduo Black Cadmium, voor al uw technowerkzaamheden, neemt je mee naar het Detroit van de jaren 80. Nu een stad in verval, toen een bruisende dancemetropool waar de beats machinaler waren dan de autofabrieken. Luister naar het dit jaar uitgekomen album 'Dream Computer', waarvan de furieuze titeltrack en het razendsnelle '6th Gear' meteen het gas erop knallen. De twee creatieve geesten, Joginda Macnack en Mike Richards, olieën hun motor verder met een dot UK techno, maar deze internationale botsing kent geen slachtoffers – alleen maar winnaars.Foto door 36framez",
    "startTime": "2024-07-07T01:15:00.000Z",
    "endTime": "2024-07-07T02:30:00.000Z",
    "name": "Black Cadmium",
    "socials": {
      "instagram": "https://www.instagram.com/black.cadmium/",
      "facebook": "https://www.facebook.com/blackcadmium",
      "spotify": "https://open.spotify.com/artist/16fquxlaJUDTOQZEw49B8t?si=_l7ApgdES5uJUZbcmsWwMA"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2520_fit_1400-540-center_85-by%2036framez%203_site.jpg"
  },
  {
    "id": 34,
    "description": "Is Blondshell blond? Wel qua krullen. Maar met de muzikale capaciteiten van deze Amerikaanse indiezangeres is niets mis, zoals blijkt uit haar enthousiast ontvangen, titelloze debuutalbum dat afgelopen voorjaar op de nietsvermoedende muziekliefhebber werd losgelaten. Een parel van een plaat, vol diep doorvoelde liedjes in de kleuren hardblauw en zachtroze, ergens tussen Courtney Love en PJ Harvey in. Met haar expressieve voordracht lijmt Blondshell je bovendien onverbiddelijk aan het podium, waarop ook haar veterstrakke band keihard het spotlicht opeist. Dus wel blond, maar niet bleu.Foto door Daniel Topete",
    "startTime": "2024-07-05T16:00:00.000Z",
    "endTime": "2024-07-05T16:45:00.000Z",
    "name": "Blondshell",
    "socials": {
      "instagram": "https://www.instagram.com/blondshell/",
      "facebook": "https://www.facebook.com/blondshe11",
      "twitter": "https://twitter.com/Blondshe11",
      "website": "https://www.youtube.com/channel/UCJ1FMy0FK0aSeXosWvGXShg",
      "spotify": "https://open.spotify.com/artist/7qrEXiLLnWkkYHhadZ1Oij?si=Q_eCNXriS868zVss30ZNvA"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2426_crop_1601-695-399-36_fit_1400-540-center_85-Blondshell_Daniel%20Topete_site.jpg"
  },
  {
    "id": 35,
    "description": "Bolis Pupul kwam op tijdens de HUMO Rock Rally, waar de Gentenaar met de band Hong Kong Dong de aandacht van de gebroeders Dewaele trok. Vervolgens maakte Pupul met zangeres Charlotte Adigéry ‘Topical Dancer’ (2022): een meesterlijke electroplaat met dito liveshow, die als een komeet bij kenners en liefhebbers insloeg. Op Down The Rabbit Hole presenteert Pupul zijn nieuwste soloalbum ‘Letter to Yu’, waarop hij aan de hand van sprankelende, melancholische analoogsynthpop op zoek gaat naar de roots van zijn moeder. En daarbij mag zeker gedanst worden.",
    "startTime": "2024-07-06T16:30:00.000Z",
    "endTime": "2024-07-06T17:30:00.000Z",
    "name": "Bolis Pupul",
    "socials": {
      "instagram": "https://www.instagram.com/bolispupul/?hl=en",
      "facebook": "https://www.facebook.com/bolispupul",
      "twitter": "https://twitter.com/charlottebolis",
      "website": "https://deew.ee/bolispupul",
      "spotify": "https://open.spotify.com/artist/0dSnTqwXok006MwsjjlzUl?si=T8o2Zl9cTcSQ44TiS4K87A",
      "soundcloud": "https://soundcloud.com/bolispupul"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2802_crop_1816-790-81-458_fit_1400-540-center_85-PERS%201_site%282%29.jpg"
  },
  {
    "id": 36,
    "description": "Britse postpunksensatie DEADLETTER heeft een handjevol singles en een EP uitgebracht, maar hun naam heeft de overkant van de plas al lang en breed bereikt. De zes leden, waarvan drie jeugdvrienden, nemen het leven serieus, maar verwacht geen donkere-wolkenformatie. Wat wel? Teksten met bijtende humor, een saxofoon die net zo makkelijk de hoofdrol pakt als figureert en een ritmefundament dat zo goed op elkaar ingespeeld is dat je er een complete woontoren op kunt bouwen. Luister naar single ‘Binge’ om in de stemming te komen.",
    "startTime": "2024-07-06T20:30:00.000Z",
    "endTime": "2024-07-06T21:30:00.000Z",
    "name": "DEADLETTER",
    "socials": {
      "instagram": "https://www.instagram.com/_deadletter/",
      "facebook": "https://www.facebook.com/DEADLETTERTHEBAND/",
      "twitter": "https://twitter.com/_DEADLETTER",
      "website": "https://www.youtube.com/@deadletter7445",
      "spotify": "https://open.spotify.com/artist/4MfUQ99z2kgMnl9hAwffBx?si=kXcFztrfSgGGdnBCgmavkw"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2516_crop_1601-696-180-47_fit_1400-540-center_85-Deadletter_site.jpg"
  },
  {
    "id": 37,
    "description": "Een staaltje mixkunst van de buitencategorie: het gaspedaal gaat horizontaal met DJ HEARTSTRING, uw distributeur van gloeiend hete tracks voor elke trancevloer. Hun adrenaline-aanwakkerende beats worden steeds verder ingekleurd met slagwerk, synths en slim getimede samples, zoals de knipoog naar Madonna's 'Vogue' in 'Will You Remember Me When I'm Gone'. Vanuit Berlijn – waar anders? – stroopt dit duo de dancehoofdsteden af om de boel met hun Trance Dance Music hoog in de bpm's te jagen, net zolang totdat je door de juichende armen de euforische menigte niet meer ziet.",
    "startTime": "2024-07-06T00:30:00.000Z",
    "endTime": "2024-07-06T02:30:00.000Z",
    "name": "DJ HEARTSTRING",
    "socials": {
      "instagram": "https://www.instagram.com/djheartstring/",
      "website": "https://www.youtube.com/@djheartstring4633",
      "spotify": "https://open.spotify.com/artist/5tcwaJBUyEdxQxvieuQxU7?si=0zbzbJQBQbaU_xexJnlL1Q",
      "soundcloud": "https://soundcloud.com/djheartstring"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2424_crop_2190-952-268-776_fit_1400-540-center_85-djh_site.jpeg"
  },
  {
    "id": 38,
    "description": "Soms zorgt een omarmd dilemma voor dubbel succes. Elias Mazian is gek op synthpop, maar kan zijn achtergrond als internationaal vermaarde dj maar moeilijk verbergen. Dat muzikale luxeprobleem komt op fenomenale wijze tot uiting op de ijzersterke Nederlandstalige platen die hij sinds al een paar jaar uitbrengt. Op album 'Alleen Bij Mij' staat het songschrijverschap centraal en dienen milde, dromerige dancebeats vooral als aangever voor stem en synths. Oudere luisteraars genieten van de Toontje Lager-echo in zijn sound, jongeren herkennen de invloed van Eefje de Visser.",
    "startTime": "2024-07-06T14:30:00.000Z",
    "endTime": "2024-07-06T15:30:00.000Z",
    "name": "Elias Mazian (Live)",
    "socials": {
      "instagram": "https://www.instagram.com/eliasmazian/",
      "twitter": "https://twitter.com/EliasMazian?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
      "website": "https://www.youtube.com/@izanbaby/videos",
      "spotify": "https://open.spotify.com/artist/5bcee1i2yLpnhwm5Nbz3vU?si=fHycePjcQEK1AJaAQBwY9w",
      "soundcloud": "https://soundcloud.com/eliasmazian"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2422_crop_1600-696-162-175_fit_1400-540-center_85-ELIAS-site.jpg"
  },
  {
    "id": 39,
    "description": "Tweede albumtitel 'Something Out Of Nothing' slaat niet helemaal op het rappe succes van Goldkimono, want frontman Martijn Konijnenburg is een bewezen meesterliedjessmid. Eerder kwamen voltreffers voor onder meer Kygo en Diggy Dex uit zijn gouden ganzenveer. Het hitjesbrein resideerde jarenlang in Californië en heeft de lokale hiphop- en popvibes ingepakt en verscheept richting Nederland. Met oude Leaf-maatjes Ocker Gevaerts en Jeroen Blumers laat Konijnenburg een heerlijk lome feelgoodbries over de festivalweides waaien, inclusief zonnige flarden Red Hot Chili Peppers en Everlast. Petje af, zomerhoedje op!",
    "startTime": "2024-07-06T11:30:00.000Z",
    "endTime": "2024-07-06T12:30:00.000Z",
    "name": "Goldkimono",
    "socials": {
      "instagram": "https://www.instagram.com/goldkimono/",
      "facebook": "https://www.facebook.com/goldkimono/",
      "website": "https://goldkimono.com/",
      "spotify": "https://open.spotify.com/artist/3krHIfEy37pVe0zjdisDBk?si=jYHgoAuMT--SdplBtQiJhA"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2512_fit_1400-540-center_85-Goldkimono%20Press%20Photo%204_site.jpg"
  },
  {
    "id": 40,
    "description": "Ze groeide op in het oosten van Nederland, maar de kamerbrede platencollectie van dj/eventproducer Hellie staat alweer jaren in Amsterdam. En daar gebeuren dankzij Hellie de mooiste dingen, waaronder Voortouw: ooit begonnen als females-first party, tegenwoordig een radioshow op het onafhankelijke station Echobox. Dat geeft je een hint over de voorkeuren van deze vinylfanaat, maar op de immer stampvolle dansvloer met Hellie krijg je geheid de vettigste grooves voor je voeten – van classic disco, funk en tropicalia tot bezielde techno en house. Hellie yeah!Foto door Merette Uiterwaal",
    "startTime": "2024-07-07T20:30:00.000Z",
    "endTime": "2024-07-07T23:00:00.000Z",
    "name": "Hellie",
    "socials": {
      "instagram": "https://www.instagram.com/hellieberry/",
      "facebook": "https://www.facebook.com/Helliedj/",
      "soundcloud": "https://soundcloud.com/hellie-berry"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2510_crop_1600-696-167-690_fit_1400-540-center_85-Hellie%20_%20Pressphoto%203%20%28by%20Merette%20Uiterwaal%29_site.jpg"
  },
  {
    "id": 41,
    "description": "No man is an island, zeggen ze. Behalve Islandman natuurlijk. De muziek van dj, producer en meesterbrein Tolga Böyük staat compleet op zichzelf. Hij mixt de Turkse psychedelica uit de jaren zeventig, die we dankzij Altin Gün allemaal leerden kennen, met de elektronische muziek uit de toekomst. Op Down The Rabbit Hole komt Islandman als trio, met synths, snaren en percussie. Ze zetten een heerlijke transcendentale sfeer neer, die beelden oproept van desolate landschappen, eindeloze Turkse bruiloftsfeesten en een safari tussen de sterren.",
    "startTime": "2024-07-06T10:30:00.000Z",
    "endTime": "2024-07-06T11:30:00.000Z",
    "name": "Islandman",
    "socials": {
      "instagram": "https://www.instagram.com/islandman_/",
      "facebook": "https://www.facebook.com/islandmanmusic/",
      "website": "https://www.youtube.com/channel/UCgl_QsqbZdIy7SoY2zrgmfw",
      "spotify": "https://open.spotify.com/artist/3So3vlAfrEh2FbpvG7sxO4?si=gyf0b4ZPQdmiQpWnbZcXmA"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2508_crop_1599-695-185_fit_1400-540-center_85-ISLANDMAN_PHOTO_site.jpg"
  },
  {
    "id": 42,
    "description": "De jaren 60 en 70 stralen je tegemoet vanaf langspeler 'Come Around And Love Me' van dit vocale mirakel. Zijn wieg stond niet ver van Marvin Gayes geboorteplaats Washington, maar eigenlijk weerklinken álle soulhelden van weleer in Jalen Ngonda's unieke stem. En net als toen biedt dit zalvende en tegelijk swingende geluid hoopvolle liefde in tijden van oorlog. Live begeleidt Ngonda zichzelf op gitaar, geflankeerd door muzikanten met onvervalst Motown-DNA. Haal dat 'neo' maar weg uit het etiket neosoul: oldskooler wordt het niet.",
    "startTime": "2024-07-06T12:30:00.000Z",
    "endTime": "2024-07-06T13:30:00.000Z",
    "name": "Jalen Ngonda",
    "socials": {
      "instagram": "https://www.instagram.com/jalen_ngonda/?hl=en",
      "facebook": "https://www.facebook.com/p/Jalen-Ngonda-100069230930680/",
      "twitter": "https://twitter.com/JalenNgonda",
      "website": "https://jngonda.com/",
      "spotify": "https://open.spotify.com/artist/2kEDso93O2hDgCbnuiSkkZ?si=nKkzwR_sQkeivmGu5jCJRw"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2420_crop_1600-696-203-872_fit_1400-540-center_85-Jalen%20-%20Festival%20Lead%20Shot_site.jpg"
  },
  {
    "id": 43,
    "description": "Wat was de wereld veel mooie liedjes misgelopen als de leden van Kingfishr hadden besloten iets met hun studie te doen. Gelukkig werd de Universiteit van Limerick het startpunt van een folkrockband in plaats van carrières als computerprogrammeurs. Eddie Keogh laat zijn stembanden ingehouden croonen, dan wel uitbundig scheuren, terwijl zijn lads zorgen voor een Mumford & Sons-achtige ondergrond. Andere inspiratiebronnen: Bon Iver, Ben Howard en Hans Zimmer. Een melancholische, hoopgevende sound die zich zowel in de Ierse groene heuvels als die van Gelderland helemaal thuis voelt.",
    "startTime": "2024-07-05T12:00:00.000Z",
    "endTime": "2024-07-05T13:00:00.000Z",
    "name": "Kingfishr",
    "socials": {
      "instagram": "https://www.instagram.com/kingfishrofficial",
      "facebook": "https://www.facebook.com/kingfishrofficial",
      "twitter": "https://twitter.com/KingfishrBand",
      "website": "https://www.kingfishr.ie/",
      "spotify": "https://open.spotify.com/artist/6c2qQFq3xfxFJndX6wSe4f?si=ktkm9gggQkiCl_8KHKNzAQ"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2504_crop_1561-679-164-87_fit_1400-540-center_85-dda0bef9_kingfishr15_sitev2.jpg"
  },
  {
    "id": 44,
    "description": "Code rood voor de Fuzzy-moshpit. Kneecap dropt spijkerharde Noord-Ierse hiphop met een absurdistisch vetrandje – zie ook hun trippy biopic ‘KNEECAP’, die vorig jaar hoge ogen gooide op het toonaangevende filmfestival Sundance. Laat je echter niet foppen door de offbeat ongein die deze drie jonge honden uit Belfast graag mogen schoppen, want Mo Chara, Móglaí Bap en DJ Próvaí – wiens balaclava weinig aan de verbeelding overlaat – blaffen ook bijtende raps over politiek, maatschappij en de schoonheid van de Ierse taal en cultuur.Foto door Sarah Ellis",
    "startTime": "2024-07-06T18:30:00.000Z",
    "endTime": "2024-07-06T19:30:00.000Z",
    "name": "Kneecap",
    "socials": {
      "instagram": "https://www.instagram.com/kneecap32",
      "facebook": "https://www.facebook.com/KNEECAP32/",
      "twitter": "https://twitter.com/KNEECAPCEOL",
      "website": "https://www.kneecap.ie/",
      "spotify": "https://open.spotify.com/artist/1ZVACPeq7ccGCoUXwtafUU?si=pxPjSrtJTtOb92NdFAWFIA"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2805_crop_2873-1248-523_fit_1400-540-center_85-MAIN%20Kneecap%20Press%20Shot%20-%20Photo%20credit%20Sarah%20Ellis%282%29.jpg"
  },
  {
    "id": 45,
    "description": "Sommige artiesten zijn zo uniek dat er een nieuwe genrenaam moet worden bedacht om ze te kunnen omschrijven. Nana Benz du Togo kwam zelf met ‘digital voodoo’ en dat dekt de lading. De groep combineert traditionele vraag-antwoordzang uit de voodooreligie met onnavolgbare Togolese percussie en dat retro synthgeluid waar hippe technoproducers zo verzot op zijn. Het resultaat is verslavend, dansbaar en nadrukkelijk feministisch. Het eerste deel van de naam verwijst niet voor niets naar de succesvolle zakenvrouwen in het Togo van de jaren 70.",
    "startTime": "2024-07-07T10:00:00.000Z",
    "endTime": "2024-07-07T11:00:00.000Z",
    "name": "Nana Benz du Togo",
    "socials": {
      "spotify": "https://open.spotify.com/artist/4GhejcivCG3Eyp9iMwnxEp?si=iIHBAgOXQAy8LNGSUbbvHw"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2416_crop_1600-696-219-81_fit_1400-540-center_85-Arnold%20ANANI%20%285%29_site.jpg"
  },
  {
    "id": 46,
    "description": "De soul van Naomi Sharon is vrij letterlijk geboren uit bloed, zweet en tranen. Nadat haar musicalcarrière bruut werd onderbroken door een ingrijpende stemoperatie herrees de Rotterdamse singer/songwriter als een feniks uit de as. Op de vleugels van haar genre-overstijgende debuutplaat ‘Obsidian’ – als eerste vrouwelijke artiest op het platenlabel van Drake – verovert ze inmiddels in straf tempo de wereld. Meesterlijke move van Champagne Papi: met haar hypnotiserende podiumprésence en fenomenale muzikale instinct is Naomi Sharon voorbestemd voor de absolute top.",
    "startTime": "2024-07-05T18:00:00.000Z",
    "endTime": "2024-07-05T19:00:00.000Z",
    "name": "Naomi Sharon",
    "socials": {
      "instagram": "https://www.instagram.com/naomisharon/?hl=en",
      "facebook": "https://www.facebook.com/naomisharonw/",
      "twitter": "https://twitter.com/NaomiSharon?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
      "website": "https://naomisharonmusic.com/",
      "spotify": "https://open.spotify.com/artist/27WVFBOddxovimxMmBN4fb?si=elvL_EEMQLag4WRcru4UqA",
      "soundcloud": "https://soundcloud.com/user-228992185"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2829_crop_1250-544-152_fit_1400-540-center_85-Naomi%20Sharon_site%282%29.png"
  },
  {
    "id": 47,
    "description": "Gelouterde muzikanten van onder andere Jungle By Night, Altin Gün en The Mysterons bundelen hun Indonesische krachten in rootsband Nusantara Beat, een verfrissend kijkje in de internationale popgeschiedenis. Voor menigeen herkenbare klanken, teksten en ritmes uit de gordel van smaragd worden even wonderlijk als naadloos aan moderne invloeden gekoppeld. Ook komen de jaren 60 en 70 voorbij, wanneer de band afslaat richting surfbaai of psychedelica-town. Tijdens hun hartverwarmende set op ESNS23, in guur Groningen, brak zowel dit gezelschap als de zon door. Geniet straks in juli van beide!Foto door Pasqual Amade",
    "startTime": "2024-07-05T12:00:00.000Z",
    "endTime": "2024-07-05T13:00:00.000Z",
    "name": "Nusantara Beat",
    "socials": {
      "instagram": "https://www.instagram.com/nusantara_beat/?hl=nl",
      "website": "https://www.youtube.com/@NusantaraBeat",
      "spotify": "https://open.spotify.com/artist/6iQZl7RmTVznyFCrML933x?si=S-hLijZsTpqNB0RZGhrKyA"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2415_crop_1600-695-202-59_fit_1400-540-center_85-Nusantara%20Beat%20by%20Pasqual%20Amade%20mei%202023%20horizontal%202_site.jpg"
  },
  {
    "id": 48,
    "description": "House, al dan niet doorspekt met breakbeats, is de specialiteit van Octo Octa, de New Yorkse dj/producer die doorgaans b2b draait met haar partner Eris Drew. De kans dat je die op de achtergrond mee ziet dansen is dan ook levensgroot. Octa maakte een muzikale stijlverandering door, van IDM via drum & bass naar de four-to-the-floorbeats waarmee ze furore maakt. Gebleven is haar ambachtelijke draaistijl – niet van usb-stick maar van vinyl – waarbij ze ook voor een potje oldskool scratchen haar hand niet omdraait.Foto door Andra Chitimus",
    "startTime": "2024-07-06T23:45:00.000Z",
    "endTime": "2024-07-07T01:15:00.000Z",
    "name": "Octo Octa",
    "socials": {
      "instagram": "https://www.instagram.com/octoocta/",
      "facebook": "https://www.facebook.com/octooctaofficial/?locale=nl_NL",
      "twitter": "https://twitter.com/octo_octa",
      "spotify": "https://open.spotify.com/artist/2GH8Mzo3Ur1AdOnGUUpt17?si=rR_po3SgQV2S6iFvxEWe3g",
      "soundcloud": "https://soundcloud.com/octoocta"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2413_crop_1600-696-202-78_fit_1400-540-center_85-Octo%20Octa%20cred.%20Andra%20Chitimus_site.jpg"
  },
  {
    "id": 49,
    "description": "Jay Donaldsons eerste liefde was, zoals dat gaat, de vroege house uit Detroit en Chicago. Dat hoor je nog steeds terug, maar zijn sets kenmerken zich vooral door de huiveringwekkende hoeveelheid melodieën. Alleen experts zullen de platen herkennen: Palmsy draait het liefst obscure tunes waarvan je je direct afvraagt waarom het geen wereldhits zijn geweest. Hij rijgt ze in hoog tempo aan elkaar, zodat er een lange, verslavende track ontstaat waarvan je met heel je hart hoopt dat hij nooit eindigt.",
    "startTime": "2024-07-07T23:00:00.000Z",
    "endTime": "2024-07-08T02:00:00.000Z",
    "name": "Palms Trax",
    "socials": {
      "instagram": "https://www.instagram.com/palmstrax/",
      "facebook": "https://www.facebook.com/palmstrax/?locale=nl_NL",
      "spotify": "https://open.spotify.com/artist/52XSRQqTAfZ8ZrIqkQvQyA?si=iS_d9nCLSPuhgO53IqpM_w",
      "soundcloud": "https://soundcloud.com/palmstrax"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2411_crop_1600-695-142-391_fit_1400-540-center_85-Palms-Trax-Pressphoto-1_site.jpg"
  },
  {
    "id": 50,
    "description": "Dat Sammy Rae & The Friends roots heeft in de Cotton Club in Harlem, New York is je eerste hint dat hun show op Down The Rabbit Hole een must is voor liefhebbers van poppy jazz, soul en funk. Deze onvermoeibare livebeesten nemen sowieso de zon mee, zo uit de hemel geplukt door goudkeeltje Sammy Rae en hun zes virtuoze vrienden – waaronder twee blazers en een ritmesectie die de zomerhitte in je heupen vanzelf doen ontvlammen. Speelplezier pur sang, soms is dat alles wat het moet zijn.",
    "startTime": "2024-07-07T11:00:00.000Z",
    "endTime": "2024-07-07T12:00:00.000Z",
    "name": "Sammy Rae & The Friends",
    "socials": {
      "instagram": "https://www.instagram.com/sammyraemusic",
      "facebook": "https://www.facebook.com/SammyRaeMusic/",
      "twitter": "https://twitter.com/SammyRaeMusic",
      "website": "https://www.sammyrae.com/",
      "spotify": "https://open.spotify.com/artist/3lFDsTyYNPQc8WzJExnQWn?si=iNA1cPWAQ-SjvQ95wD7rmw",
      "soundcloud": "https://soundcloud.com/sammyraemusic"
    },
    "stage": "hotot",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2811_fit_1400-540-center_85-Sammy_Rae_full_band_preferred%282%29.jpeg"
  },
  {
    "id": 51,
    "description": "'Psychic Dance Routine' is het vervaarlijke debuut van Scowl, een vijftal dat in 2019 in Santa Cruz, Californië bij elkaar kwam. Ongeleid projectiel annex frontvrouw Kat Moss en haar band slepen je mee naar punkrockkelders waar het zweet van Blondie, Sonic Youth en The Breeders nog in de plinten zit. Check de knappe tempowisselingen op 'Shot Down' en geniet van de heerlijke hard-zachtdynamiek op de titeltrack: een fijne workout voor je nekspieren. De moshpit, straks in de Groene Heuvels, is natuurlijk helemaal bij de konijnen af.",
    "startTime": "2024-07-06T22:15:00.000Z",
    "endTime": "2024-07-06T23:00:00.000Z",
    "name": "Scowl",
    "socials": {
      "instagram": "https://www.instagram.com/scowl40831/?hl=en",
      "facebook": "https://www.facebook.com/Scowl40831/",
      "twitter": "https://twitter.com/Scowl40831",
      "spotify": "https://open.spotify.com/artist/6hW33nVHPElaXuG2hQ0QOp?si=C23g97dnRvaCiNKhEw1HNA",
      "website": "https://www.scowl40831.com/"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2409_crop_1852-805-91-208_fit_1400-540-center_85-SCOWL_PRESS_SITE.jpg"
  },
  {
    "id": 52,
    "description": "Waren we net gewend aan de heerlijk lome groove van amapiano, de hippe Zuid-Afrikaanse dansvloerstroming, komt landgenote Sho Madjozi de boel eens lekker opschudden. Mét amapiano, dat wel, als basis onder veel nummers, samen met hitsiger lokale stijlen als gqom en mbaqanga. Goed voor een uitbundig feestje, waarop zangeres, rapper en fashionista Madjozi en haar mededansers je alle kleuren van de regenboog laten zien, uiteraard featuring haar zomerse doorbraakhit ‘Chale’. En let op: stoppen komt in haar woordenboek niet voor. De Sho must go on!",
    "startTime": "2024-07-05T14:00:00.000Z",
    "endTime": "2024-07-05T15:00:00.000Z",
    "name": "Sho Madjozi",
    "socials": {
      "instagram": "https://www.instagram.com/shomadjozi/",
      "twitter": "https://twitter.com/ShoMadjozi",
      "website": "https://www.youtube.com/channel/UCkOVK-tMYtEITJkzsnm-uUg",
      "spotify": "https://open.spotify.com/artist/08V2vgJBY6VLoUPWlznRKo?si=pkaYyBZ9TFCbiHmewSz-tA"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2407_crop_1600-696-212-818_fit_1400-540-center_85-Sho%20Madjozi_SHOT02_0380_site.jpg"
  },
  {
    "id": 53,
    "description": "Deelname aan dirigeerprogramma ‘Maestro’ kuste bij deze producer, rapper en componist een diepe liefde voor klassieke muziek wakker en zo staat hij ineens met een orkest op de Beuningse planken. Samen blijven ze dicht bij zichzelf: sor brengt zangerige raps met smaakvolle autotune, terwijl de musici zich uitleven op Mozart en Satie. Dat die combinatie uitstekend werkt hoorden we al op de EP-reeks ‘Bae Doven’ – een verwijzing naar een zekere Ludwig Van én naar de gehoorproblemen waardoor sor noch Ludwig zich uit het veld liet slaan.",
    "startTime": "2024-07-07T10:00:00.000Z",
    "endTime": "2024-07-07T11:00:00.000Z",
    "name": "sor + sorkest",
    "socials": {
      "instagram": "https://www.instagram.com/sor/",
      "twitter": "https://twitter.com/rostrubation",
      "spotify": "https://open.spotify.com/artist/267wBt3XfmW3kdOC0JCtcO?si=GUK7iF5LRDuVTo3S-r-OBQ"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2496_crop_1600-696-203-826_fit_1400-540-center_85-sor1_site.jpg"
  },
  {
    "id": 54,
    "description": "Deze Australische powerriffrockers staan thuis bekend als een van de allerbeste livebands van het land. Dat leidde onder meer tot supportslots voor gelijkgestemde versterkerslopers als The Darkness en Wolfmother, die dankbaar meeliftten op de dieseldrive van tracks als ‘Stan Qualen’. The Southern River Band heeft echter een streepje voor op hun vakbroeders: met hardrock, hairmetal, rock ‘n’ roll, powerballads, country én de meest zompige bluesrock uit het Amerikaanse zuiden in hun arsenaal zijn ze momenteel hét geheime wapen van onze lieve heer Rrrawk.Foto door Greg Lewis",
    "startTime": "2024-07-07T12:00:00.000Z",
    "endTime": "2024-07-07T13:00:00.000Z",
    "name": "The Southern River Band",
    "socials": {
      "instagram": "https://www.instagram.com/thesouthernriverband/",
      "facebook": "https://www.facebook.com/thesouthernriverband/",
      "website": "https://www.youtube.com/c/TheSouthernRiverBand"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_3011_fit_1400-540-center_85-SRB_credit_Greg_Lewis%284%29.jpeg"
  },
  {
    "id": 55,
    "description": "In de dj-wereld kom je samenwerkingen tegen waarvan je je afvraagt wie in hemelsnaam dacht dat het een goed idee was, maar Tjade, Lucky Done Gone en Moody Mehran zijn voor elkaar geschapen. Natuurlijk, een beetje elektronicanerd kan je de verschillen aanwijzen. De een legt de nadruk op (neo)italo, een ander focust op nineties techno en de derde zoekt zijn heil in de uithoeken van het spectrum, maar wat telt is de gedeelde liefde voor retrosynths, 3D-grooves en ultraverslavende melodieën. De turbulentie krijg je er bij deze superzeldzame festivalshow gratis bij.Foto door Tom van Huisstede",
    "startTime": "2024-07-05T22:30:00.000Z",
    "endTime": "2024-07-06T00:30:00.000Z",
    "name": "TLM Airlines",
    "socials": {
      "instagram": "https://www.instagram.com/tlm.airlines/",
      "website": "https://www.tlm-airlines.com/"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2495_crop_1601-696-197-122_fit_1400-540-center_85-TLM-Analoog-TomvanHuisstede-01-liggend-web_site.jpg"
  },
  {
    "id": 56,
    "description": "'That Golden Time', de titeltrack van Villagers' nieuwe langspeler, sleurt je direct mee de diepte in. Door de diverse lagen van frontman Conor O'Briens gedachtewereld én langs urgente muziekgeschiedenis. Ennio Morricone-achtige orkestratie, mysterieuze David Bowie-sferen en Fleet Foxes-folkiness: melancholie en authenticiteit staan centraal. De multi-instrumentalist en metaforentovenaar wil niet zozeer een statement maken, maar vooral – en gelukkig voor ons – zijn in betoverende liedjes gevatte zielenroerselen met de luisteraar delen. Ongetemde kunstenaarsdrang in optima forma dus, en live op volle podiumsterkte is Villagers helemaal een aanrader.Foto door Andrew Whitton",
    "startTime": "2024-07-07T14:00:00.000Z",
    "endTime": "2024-07-07T15:00:00.000Z",
    "name": "Villagers",
    "socials": {
      "instagram": "https://www.instagram.com/wearevillagers",
      "facebook": "https://www.facebook.com/Villagers/",
      "twitter": "https://twitter.com/wearevillagers",
      "website": "https://wearevillagers.com/",
      "spotify": "https://open.spotify.com/artist/2m1l9MLSslzup4vvokKgvQ?si=Fnis8PA2StydXZEbhbcyYA"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2491_fit_1400-540-center_85-d9d911cd_VILLAGERS_231113_ANDREW_WHITTON_00174_site.jpg"
  },
  {
    "id": 57,
    "description": "Op zoek naar strafbesnaarde rock met jakkerende drums en bouncy baslijnen? Wodan Boys hookt je op: na hun allesverzengende show op de Platte Kar op DTRH22 keren deze vier Hagenezen triomfantelijk terug naar de Groene Heuvels. Met drie EP’s op de teller heeft de overstuurde postpunky hookrock van Tommy, Mikkie B, Olly en Timo alleen maar aan power gewonnen, mede dankzij de vele extra livekilometers in hun andere bands Soul Sister Dance Revolution, Taymir en Son Mieux. Inpluggen en gaan, Wodan Boys komt je goden verzoeken.",
    "startTime": "2024-07-06T10:30:00.000Z",
    "endTime": "2024-07-06T11:30:00.000Z",
    "name": "Wodan Boys",
    "socials": {
      "instagram": "https://www.instagram.com/wodanboys/"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2979_fit_1400-540-center_85-Wodan%20Boys%20-%20Press%20pick%202024-02%20-%20Tim%20van%20der%20Zalm_site.jpg"
  },
  {
    "id": 58,
    "description": "De laatste Rabbit Radio van dit weekend vind je niet in Fuzzy Lop, maar in dancenight-tempel Teddy Widder. Geen wonder, want vandaag heerst niemand minder dan DJ Arnold Scheepmaker over de decks, de maestro achter de legendarische all-nighters Kiss All Hipsters, Noodlanding en 110% Indie. Een klapper van een finale, waarbij je tot in de vroege uurtjes doordanst op zowel classic als kersverse tunes in de smaken indie, hiphop, pop, electronic en alle crossovers daartussen. Eind goed? All good!",
    "startTime": "2024-07-07T21:00:00.000Z",
    "endTime": "2024-07-08T01:30:00.000Z",
    "name": "Rabbit Radio: Arnold Scheepmaker",
    "socials": {
      "instagram": "https://www.instagram.com/arnoldscheepmaker/",
      "facebook": "https://www.facebook.com/ArnoldScheepmakerKillAllHipsters/?hc_ref=ARQSuXO-xEPF3yHUu5qZwpprt-ADn0JngejSyw2lXUYvzNmy0kmr9dHMUreJx-FxIY8&fref=nf&locale=id_ID&paipv=0&eav=AfaiLlt84tdnfwh4bun8qLRZPR5mBRqcXiOwElOcXOrM9BLtKFqaoOZQC2l1J0YsxGs&_rdr"
    },
    "stage": "teddy widder",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2814_crop_512-223-97_fit_1400-540-center_85-1477741_10151869320591656_2037385878_n%282%29.jpg"
  },
  {
    "id": 59,
    "description": "Hop! De doordansextravaganza Rabbit Radio pakt vannacht in Fuzzy Lop magistraal uit met twee selectors van de Utrechtse fijnproeversclub EKKO: Menno de Meester & DJ Prima distilleren alleen de allerbeste hits, bangers, anthems en deepcuts uit hun shithete huisplaylist. En die zitten precies op jouw golflengte, dus duik diep in de groef tot het bitterzoete ochtendgloren je komt halen.",
    "startTime": "2024-07-06T23:30:00.000Z",
    "endTime": "2024-07-07T02:00:00.000Z",
    "name": "Rabbit Radio: Menno de Meester & DJ Prima",
    "socials": {
      "instagram": "https://www.instagram.com/mennodemeester/",
      "facebook": "https://www.facebook.com/mennodemeesterofficial/?locale=nl_NL",
      "website": "https://www.youtube.com/@mennodemeester/featured",
      "soundcloud": "https://soundcloud.com/mennodemeester"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2808_crop_2000-870-23_fit_1400-540-center_85-DJ%20Prima%20%26%20Menno%20de%20Meester%20main%20press%20pic_site%282%29.jpg"
  },
  {
    "id": 60,
    "description": "Zet 3FM-dj Vera Siemons en TiVre-nachtprogrammeur Liza van den Brink samen in een booth en je hebt geheid vuurwerk. Hun succesrecept voor volle tenten en kletsnatte dansvloeren komt uit hetzelfde vaatje als hun eigen clubnacht ICONIC, waar queer pop de boventoon voert. Deze twee energieke koppen delen een zwak voor queer representatie op muziekgebied, maar laten je straks tijdens Rabbit Radio in Fuzzy Lop ook graag kennismaken met hun favoriete pop, indie, house en hiphop van dit moment, naadloos in de mix met evergreens die op geen festival mogen ontbreken. Foto door Tom van Huisstede",
    "startTime": "2024-07-05T23:15:00.000Z",
    "endTime": "2024-07-06T01:30:00.000Z",
    "name": "Rabbit Radio: Vera Siemons & Liza van den Brink",
    "socials": {
      "instagram": "https://www.instagram.com/lizavdbrink/?hl=en",
      "twitter": "https://twitter.com/verasiemons?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
    },
    "stage": "fuzzy lop",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2796_crop_1600-696-234-77_fit_1400-540-center_85-Vera%20%26%20Liza%20%E2%80%93%20Tom%20van%20Huisstede%20%E2%80%93%2005_site%283%29.jpg"
  },
  {
    "id": 61,
    "description": "Berghain, Herrensauna en Spielraum, waar de Rotterdamse een residentplek afdwong, zijn allemaal plekken die undergroundpionier Afra met haar platenkoffer vol Detroit-invloeden in hogere sferen bracht. De dj werd al snel gegrepen door de wondere wereld des vinyls. Haar andere passie – sieraden ontwerpen – verraadt een smaakvol oog voor detail bij het zoeken naar de juiste materialen. Achter de draaitafels komt die gave uitermate van pas. Enige verschil: Afra's woeste acidparels en technodiamanten zijn doorgaans ongepolijst, imperfectie wordt omarmd in plaats van weggeslepen. En daar gaat elke dansvloer keihard op.Foto door Sven Bijma",
    "startTime": "2024-07-07T00:30:00.000Z",
    "endTime": "2024-07-07T02:30:00.000Z",
    "name": "Afra",
    "socials": {
      "instagram": "https://www.instagram.com/_______afra_______/",
      "facebook": "https://www.facebook.com/afra.amsterdam",
      "soundcloud": "https://soundcloud.com/aframusic",
      "website": "https://linktr.ee/_______afra_______"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2555_crop_1600-696-325-72_fit_1400-540-center_85-afra-by-sven-bijma3_site%283%29.jpg"
  },
  {
    "id": 62,
    "description": "De lieflijke naam Angel D'lite is een beetje misleidend, want dit opstomende talent uit Zuid-Londen geselt haar dansvloerdiscipelen met een meedogenloos zweepslagensalvo van trance, garage en eurodance. Luister haar dit jaar uitgekomen bloemlezing 'Enchanted': je begint met fijne drum-'n-bass en belandt al snel op het eindeloos groovende 'Cloud 69'. Live kan daar zomaar een raveklassieker doorheen schieten. En dat alles in rap tempo, want je staat er als het aan de draaitafeldame ligt niet om vliegen te vangen. Feest, verdwaling en herkenning alom dus, met Angel D'lite.",
    "startTime": "2024-07-08T00:30:00.000Z",
    "endTime": "2024-07-08T02:30:00.000Z",
    "name": "Angel D'lite",
    "socials": {
      "instagram": "https://www.instagram.com/angel_d_lite",
      "facebook": "https://www.facebook.com/djangeldlite",
      "spotify": "https://open.spotify.com/artist/3hTukG4F04etkdZSQBsPOf?si=QUDTvtqmQ2eZyeeKAk09bA",
      "soundcloud": "https://soundcloud.com/angeldlite",
      "website": "https://linktr.ee/AngelDlite"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2654_fit_1400-540-center_85-9q9a6616_site%282%29.jpg"
  },
  {
    "id": 63,
    "description": "Cinnaman – Yuri Boselie in zijn paspoort – is al twee decennia een spin in het Amsterdamse undergroundweb. Als resident liet hij De School, Trouw en Club 11 schudden, anno nu navigeert hij onder meer Dekmantel richting euforische sferen. De routinier, erkend vakman in electro- en hiphopbeats, speurt altijd naar meer invalshoeken om zichzelf te vernieuwen. De improvisatieskills druipen ervan af in zijn radioshows, live gaat daar nog een tandje of wat bij en legt Cinnaman alleen maar meer risico in zijn spel – en dat maakt zijn optredens even spannend als energiek.",
    "startTime": "2024-07-06T13:30:00.000Z",
    "endTime": "2024-07-06T15:30:00.000Z",
    "name": "Cinnaman",
    "socials": {
      "instagram": "https://www.instagram.com/cinnaman/",
      "facebook": "https://www.facebook.com/cinnamanofficial/",
      "spotify": "https://open.spotify.com/artist/2UHOxilPkRsyFp4EQu8Sq4?si=1N5G49XXRiKjR1Lcz-vVJA",
      "soundcloud": "https://soundcloud.com/cinnaman"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2675_crop_871-378-144_fit_1400-540-center_85-Web_foto_Cinnaman%282%29.png"
  },
  {
    "id": 64,
    "description": "Darwin is het brein achter REEF, de 24-uursraves in Berghain. De Canadese heeft gemakshalve haar intrek in Berlijn genomen, dicht op het technovuur. Haar droom: de vonken van de Britse clubscene laten overspringen op de dansmetropool en alle andere hotspots ter wereld. Dat doet Darwin als labeloprichter van SPE:C én door het voorbeeld te geven vanuit de booth. Daar koken de bassen heerlijk over en gaan de breakbeats tekeer als een kudde bronstige olifanten, terwijl de menigte handen tekortkomt om in de lucht te steken.",
    "startTime": "2024-07-06T20:30:00.000Z",
    "endTime": "2024-07-06T22:30:00.000Z",
    "name": "Darwin",
    "socials": {
      "instagram": "https://www.instagram.com/darwin_reef",
      "facebook": "https://www.facebook.com//profile.php?id=100009578411542",
      "twitter": "https://twitter.com/Darwin_REEF",
      "soundcloud": "https://soundcloud.com/darwinspec"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2940_fit_1400-540-center_85-Darwin%208%20credit%20ALXPStudio_site.jpg"
  },
  {
    "id": 65,
    "description": "Oeh, die 90s-vibes in 'Feel My Love' van DASCO: dat wordt smullen straks, in de Groene Heuvels. Deze track representeert alles waar de Berlijnse voor staat. Niet alleen de liefde voor klassieke dance en ongekende levenslust, ook haar regenboogkleurige droom. Met haar label Bisexuality Exists bindt ze een vrolijke, open strijd aan met genderdiscriminatie. Hoe? Door op dansvloeren wereldwijd de diversiteit te omarmen, met een set vol superherkenbare tracks die je bij elkaar gerust als powerhouse mag omschrijven. Het leven moet gevierd worden – en wel samen.",
    "startTime": "2024-07-05T20:30:00.000Z",
    "endTime": "2024-07-05T22:30:00.000Z",
    "name": "DASCO",
    "socials": {
      "instagram": "https://www.instagram.com/dj.dasco",
      "facebook": "https://www.facebook.com/dj.dasco",
      "spotify": "https://open.spotify.com/artist/1uWXQVDLruQwFEaGtQeWuc?si=bx_r1f15RxWn1i2xx3fSBg",
      "soundcloud": "https://soundcloud.com/dj-dasco",
      "website": "https://linktr.ee/dj.dasco"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2664_crop_1200-522-155-192_fit_1400-540-center_85-DASCO_site%286%29.jpg"
  },
  {
    "id": 66,
    "description": "Craig De Shan Adams kennen we beter als DJ Assault: rapper, producer, draaitafelkanon en medegrondlegger van het subgenre ghettotech, ook wel Detroit Club. Adams' formule van hiphopbeats, electro-invloeden en repetitieve chants in gangstastyle – bij voorkeur over 's werelds populairste vorm van beweging – is in deze eeuw nog steeds goed voor oldschool feestjes. Gingen clubs eind jaren 90 los op 'Ass n' Titties', nu is het twerken geblazen op 'Full Frontal Nudity'. Deze vos is zijn streken nog lang niet verloren. Als dat maar goed gaat, in konijnenland.",
    "startTime": "2024-07-06T15:30:00.000Z",
    "endTime": "2024-07-06T17:30:00.000Z",
    "name": "DJ Assault",
    "socials": {
      "instagram": "https://www.instagram.com/djassault1/",
      "twitter": "https://twitter.com/DJAssault",
      "website": "https://linktr.ee/DJAssault",
      "spotify": "https://open.spotify.com/artist/3yoyUFgb11DMp1KrsJoQZU?si=YEVTF8uwQ_2qsQVl6sOZtA",
      "soundcloud": "https://soundcloud.com/djassault"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2571_crop_2048-890-275-40_fit_1400-540-center_85-DJAssault%282%29.jpg"
  },
  {
    "id": 67,
    "description": "Met een naam die verwijst naar een koninklijk slangenmens uit de Koerdische mythologie heb je sowieso een streepje voor in club REX. Helemaal als je dan ook nog eens compromisloos de bass op de hielen zit, of je die nu vindt in rave, breakbeats, house of andere clubtempo bangers die DJ Shahmaran met liefdevolle precisie fileert tot de essentie: beentjes zweten op de dansvloer. De sets van deze barnstormer in de queerscene zijn dan ook even intens als gelukkigmakend – en jouw glimlach straks maximaal breed.",
    "startTime": "2024-07-06T17:30:00.000Z",
    "endTime": "2024-07-06T19:30:00.000Z",
    "name": "DJ Shahmaran",
    "socials": {
      "instagram": "https://www.instagram.com/dj_shahmaran/",
      "facebook": "https://www.facebook.com/DJShahmaran",
      "soundcloud": "https://soundcloud.com/djshahmaran"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2626_crop_1600-695-200-472_fit_1400-540-center_85-DSCF0125_site%282%29.jpg"
  },
  {
    "id": 68,
    "description": "Al ruim een decennium stuurt Eversines vanuit Amsterdam de smakelijkste tunes de wereld in. Hij plaatst zichzelf desgevraagd aan de energieke en dromerige kant van het elektronische spectrum. Dat klopt zeker, al betekent energiek niet per se dat het tempo hoog ligt – al is er een immer dwingende onderstroom die van stilstaan geen optie maakt. En die dromerige kant? Er gebeuren de gekste dingen, maar wie zo lekker droomt zou de hele dag alleen maar willen slapen.",
    "startTime": "2024-07-07T12:15:00.000Z",
    "endTime": "2024-07-07T15:00:00.000Z",
    "name": "Eversines",
    "socials": {
      "instagram": "https://www.instagram.com/eversines",
      "facebook": "https://www.facebook.com/Eversines/",
      "spotify": "https://open.spotify.com/artist/0hq7wHZ0z3nuoFSyfUXDHu?si=xQVgyu5WTr-VApqgtVykRA",
      "soundcloud": "https://soundcloud.com/eversines",
      "website": "https://linktr.ee/eversines"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2575_crop_1833-797-233-219_fit_1400-540-center_85-000009%282%29.jpg"
  },
  {
    "id": 69,
    "description": "Fafi Abdel Nour werd geboren in zijn favoriete muziekjaar, 1996. Veel nieuwe platen hoef je bij hem dan ook niet te verwachten. Dat wil allerminst zeggen dat zijn sets roestig klinken. Integendeel: de Groninger weet als geen ander de energie te vangen die zo kenmerkend is voor de housescene van de jaren 90. De onbevangenheid, de experimenteerdrift, het onversneden optimisme. Nu en dan komt er een (semi)klassieker voorbij, maar Nour mikt vooral op onbekende tracks waarvan je je afvraagt waarom ze niet tot de housecanon behoren.",
    "startTime": "2024-07-06T00:30:00.000Z",
    "endTime": "2024-07-06T02:30:00.000Z",
    "name": "Fafi Abdel Nour",
    "socials": {
      "instagram": "https://www.instagram.com/fafi_abdel_nour",
      "facebook": "https://www.facebook.com/FafiAbdelNourDJ",
      "soundcloud": "https://soundcloud.com/fafi_abdel_nour",
      "website": "https://linktr.ee/fafiabdelnour"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2579_crop_1600-696-215-77_fit_1400-540-center_85-Fafi_Abdel_Nour_site%282%29.jpg"
  },
  {
    "id": 70,
    "description": "Een beetje wiegen, een beetje dansen, een beetje dromen, een beetje sjansen: het effect van deze dj/producer uit Amsterdam is velerlei, maar resulteert altijd in een kleurrijk en euforisch palet. Hoe hard de leftfieldbeats ook beuken, hoe stevig de breakbeats ook struikelen en hoe uitgelaten de jonge dj/producer uit Amsterdam ook achter zijn apparatuur staat te bouncen. Zijn geheim? Wellicht de droompopmelodieën van zijn eigen materiaal, of de feelgoodmixen van andermans tracks. En anders wel de vrolijke onvoorspelbaarheid van zijn veelkleurige set. Hoe dan ook, Ineffekt doet wonderen voor je humeur.Foto door  Angelina Nikolayeva",
    "startTime": "2024-07-07T15:00:00.000Z",
    "endTime": "2024-07-07T17:30:00.000Z",
    "name": "Ineffekt",
    "socials": {
      "instagram": "https://www.instagram.com/ineffekt",
      "facebook": "https://www.facebook.com/ineffekt",
      "twitter": "https://twitter.com/ineffekt",
      "spotify": "https://open.spotify.com/artist/4gjrK1MHgJnPCESuzMtWXg?si=qYKic-W9R2aKmvMT8Gv-yA",
      "soundcloud": "https://soundcloud.com/ineffekt",
      "website": "https://linktr.ee/ineffekt"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2630_crop_1598-695-199-292_fit_1400-540-center_85-Ineffekt%20by%20Angelina_Nikolayeva-09_site%282%29.jpg"
  },
  {
    "id": 71,
    "description": "Zo onverstoorbaar en gefocust als ze achter de decks staat, zo wild en opwindend is haar set: welkom in de wondere wereld van JASSS. De Berlijnse dj/producer van Spaanse origine (Silvia Jiménez Alvarez, volgens haar paspoort) jaagt haar avontuurlijke, kiezelharde en industriële techno met nietsontziende donderdrift door de speakers, dansvloeren verpulverend als waren het lucifershoutjes. Een slimme JASSS-fan zorgt dus voor stevige stappers, en een setje oordoppen kan sowieso geen kwaad. Alle stampertjes verzamelen!Foto door Kasia Zacharko",
    "startTime": "2024-07-06T22:30:00.000Z",
    "endTime": "2024-07-07T00:30:00.000Z",
    "name": "JASSS",
    "socials": {
      "instagram": "https://www.instagram.com/jasss_inc/",
      "facebook": "https://www.facebook.com/jasssisjasss",
      "spotify": "https://open.spotify.com/artist/4reMrexillTAvq1AQr6MzV?si=aUXzg2wqSuiTFjCowbrJWA",
      "soundcloud": "https://soundcloud.com/jass-29"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2634_crop_653-285-83-96_fit_1400-540-center_85-JASSS-KasiaZacharko_site%282%29.jpg"
  },
  {
    "id": 72,
    "description": "Bekende jongensdroom: ‘Attentie, onze drummer/topscorer/coureur is geblesseerd, kan iemand uit het publiek invallen?’ Zo ongeveer moet Eddy Kennedy alias Kessler zijn debuut op Dekmantel gevoeld hebben, omdat hij er al jarenlang vaste bezoeker was. Hij verhuisde van Belfast naar Nederland, werkte in een platenzaak en zwoegde intussen hard op zijn eclectische stijlenpalet van jungle, drum-'n-bass en ambient (voorpretluistertip: ep 'Lunar'). Dat hij nu hoog op de posters van clubs en festivals staat is een kroon op zijn doorzettingsvermogen – net als zijn welverdiende spot op DTRH24.",
    "startTime": "2024-07-06T19:30:00.000Z",
    "endTime": "2024-07-06T20:30:00.000Z",
    "name": "Kessler (live)",
    "socials": {
      "instagram": "https://www.instagram.com/____kessler",
      "facebook": "https://www.facebook.com/kesslertrax",
      "twitter": "https://twitter.com/kesslerism",
      "spotify": "https://open.spotify.com/artist/3p0aq3vKGFP6N7rDc0UhJC?si=GejynxzeTDix8zPzx9lRQw",
      "soundcloud": "https://soundcloud.com/kess1er",
      "website": "https://linktr.ee/____kessler"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2583_crop_1023-445-127-577_fit_1400-540-center_85-f688aa1761ebc1d81a8f9b023e515bd5d6b7a601.jpg"
  },
  {
    "id": 73,
    "description": "Tijdens haar Toy Tonics-set op ADE vorig jaar liet Laura Meester er geen toeval over bestaan: de dj-booth is haar favoriete stek en de dansvloer het decor dat steevast afgebroken wordt. Ze leerde als kind piano en bas spelen, maar sneakte al snel richting de verboden snoepwinkel, waar beukende clubbeats en vooral (African) disco verleidelijk lagen uitgestald. Ze propte haar zakken vol met de meest funky lekkernijen en vulde ze aan met percussiebreaks en vocal samples die oudeschool-eurohouse doen herleven. En die formule werkt geheid, dus geknipt voor club REX.",
    "startTime": "2024-07-05T10:00:00.000Z",
    "endTime": "2024-07-05T12:30:00.000Z",
    "name": "Laura Meester",
    "socials": {
      "instagram": "https://www.instagram.com/laura_meester/",
      "soundcloud": "https://soundcloud.com/laura-meester-850776437",
      "website": "https://linktr.ee/laurameester"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2589_crop_1200-522-126-671_fit_1400-540-center_85-Laura_Meester_site%282%29.jpg"
  },
  {
    "id": 74,
    "description": "Small Steps, zo heet de SoundCloud-hub van het Antwerpse dancefenomeen Lola Haro, dat de logische verhuizing naar Berlijn inmiddels heeft voltooid. Ze maakt dus niet alleen maar kleine stapjes. Sterker nog, een Boiler Room-gig, spots op Klub Dramatik en Paradise City Festival in haar geboorteland, een talent-awardnominatie namens Red Bull Elektropedia en een volgeramde Europese zomerfestivalkalender voor 2024? Dat mogen reuzenstappen heten. Voel de diepe uptempo-electrovibe en ga compleet uit je kratje op de laag voor laag opgebouwde feelgoodtracks van mixmaster Lola.",
    "startTime": "2024-07-07T20:30:00.000Z",
    "endTime": "2024-07-07T22:30:00.000Z",
    "name": "Lola Haro",
    "socials": {
      "instagram": "https://www.instagram.com/lolitaharo/",
      "facebook": "https://www.facebook.com/lolaharomusic",
      "soundcloud": "https://soundcloud.com/lola-haro",
      "website": "https://linktr.ee/lolitaharo"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2597_crop_1500-652-701_fit_1400-540-center_85-Lola_Haro_Presspic_06_site%286%29.jpg"
  },
  {
    "id": 75,
    "description": "Een optreden van Orpheu The Wizard is de milieuvriendelijkste manier om iets van de wereld te zien. De medeoprichter van Red Light Radio duikelt zijn platen op in de verste uithoeken, recht door genres, continenten en tijdvakken heen. Van sommige tracks zou je niet geloven dat ze bestaan als je ze niet met eigen oren zou horen. De tovenaar van Amsterdam weet al die pareltjes ook nog eens op een logische manier aan elkaar te smeden. Een set om over na te praten als over je mooiste vakanties.",
    "startTime": "2024-07-07T22:30:00.000Z",
    "endTime": "2024-07-08T00:30:00.000Z",
    "name": "Orpheu The Wizard",
    "socials": {
      "soundcloud": "https://soundcloud.com/orpheuthewizard"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2667_crop_2000-870-352_fit_1400-540-center_85-230508_Orpheu_The_Wizard_1888_site%285%29.jpg"
  },
  {
    "id": 76,
    "description": "De van origine Zimbabwaanse gangmaker Passion DEEZ kun je intussen kennen van Radio Radio FM en Team Patta. Van zijn draaistijl spat het enthousiasme voor UK bass, grime en jungle af, net als van die zorgvuldig uitgezochte 'oh ja!'-samples. Ginuwines 'Pony', of een synthesizerhit uit 1985? No-go's bestaan niet! Zijn telefoon ontplofte dan ook, na zijn bejubelde Boiler Room-debuut in thuisstad Amsterdam: een onnavolgbare, swingende en liefdevolle ode aan al het moois dat muziek heet. Kijk, dat soort gasten wil je er graag bij hebben.",
    "startTime": "2024-07-06T10:00:00.000Z",
    "endTime": "2024-07-06T12:30:00.000Z",
    "name": "Passion DEEZ",
    "socials": {
      "instagram": "https://www.instagram.com/PASSIONDEEZ",
      "facebook": "https://www.facebook.com/PASSIONDEEZ/",
      "twitter": "https://twitter.com/passiondeez",
      "soundcloud": "https://soundcloud.com/PASSIONDEEZBEATS",
      "website": "https://linktr.ee/passiondeez"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2670_crop_1141-496-21_fit_1400-540-center_85-Press%20Pic%20Passion%20DEEZ%204_site_2%282%29.png"
  },
  {
    "id": 77,
    "description": "Aparte vogel of paradijsvogel? Elias Mazian fladdert feelgoodliedjes fluitend tussen allerlei muziekgenres door, en verbindt ze op speelse wijze met elkaar. Een nacht vol techno in Trouw of De School kon zomaar onderbroken worden door een gevoelig nederpopnummer, zijn Private Hearts-sets zijn evenmin verstoken van experimenteerdrift. Die veelzijdigheid maakt hem een graag geziene gast op de grote festivals. Straks bij Nijmegen rechtsaf, oftewel rondom de konijnenholen, kiest hij voor een liveversie van die populaire radioshow. Mét special guest, want Elias Mazian trakteert en verrast altijd.Foto door Marieke de Graaff",
    "startTime": "2024-07-07T10:15:00.000Z",
    "endTime": "2024-07-07T12:15:00.000Z",
    "name": "Private Hearts Radio (with Elias Mazian & Luc Mast)",
    "socials": {
      "instagram": "https://www.instagram.com/privateheartsworld/"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2678_fit_1400-540-center_85-Logo_PHR_site%282%29.jpeg"
  },
  {
    "id": 78,
    "description": "Al even niets nieuws gehoord van Jameszoo? Dat kan kloppen. De experimentele Bossche producer is momenteel aan de rol met toetsenman en zielsverwant Niels Broos. Als ROLROLROL waagt het illustere duo zich aan een schijnbaar chaotische wirwar van pop, moderne jazz en elektronica, de ene keer uitmondend in een met acidblieps volgestouwde kruisbestuiving van musica exotica en citypop, de andere in pure avant-garde. Op plaat tenminste; tijdens hun sets houden de mannen gelukkig ook de beats in de gaten, die de spanningsboog nog strakker aantrekken.",
    "startTime": "2024-07-06T12:30:00.000Z",
    "endTime": "2024-07-06T13:30:00.000Z",
    "name": "ROLROLROL",
    "socials": {
      "instagram": "https://www.instagram.com/rolrolrolrolrolrol/",
      "spotify": "https://open.spotify.com/artist/6iMGNZPRWTevB4NfJnC5iU?si=Fp-Ai-B9TSa26WZ4vaYaZw",
      "soundcloud": "https://soundcloud.com/rolrolrol"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2642_crop_1600-696-184-254_fit_1400-540-center_85-ROLROLROL%202023%20%281%29_site%282%29.jpg"
  },
  {
    "id": 79,
    "description": "Sally C, dat klinkt als de naam van een folkzangeres, maar nee: het is house dat geen geheimen heeft voor deze gestaalde Berlijnse dj/producer/labelbaas van Noord-Ierse komaf. Vintage house met name, van acid tot hiphouse en alles ertussenin. En dat dan volgens het adagium ‘minder is meer’, want aan nodeloze tierelantijntjes heeft Sally een broertje dood. Bij deze topselector zijn tijdloze maar vergeten klassiekers uit het gouden housetijdperk 1988-1998 dan ook in goede handen. Banger na banger na banger. Een dame om op te bouwen.",
    "startTime": "2024-07-05T22:30:00.000Z",
    "endTime": "2024-07-06T00:30:00.000Z",
    "name": "Sally C",
    "socials": {
      "instagram": "https://www.instagram.com/sallycberlin/",
      "facebook": "https://www.facebook.com/sallycberlin/",
      "spotify": "https://open.spotify.com/artist/3AkVHCDEo2WuaVtMglFfN8?si=SaenLAyTRueIwEzo5W1TPg",
      "soundcloud": "https://soundcloud.com/sallycberlin"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2646_crop_1000-435-482_fit_1400-540-center_85-Sally%20C%282%29.jpg"
  },
  {
    "id": 80,
    "description": "Als de uitdrukking ‘genre-fluid’ niet bestond, zou het uitgevonden moeten worden voor Sansibar. Bij de Finse producer uit Helsinki is alles vloeibaar: house, techno, electro, new beat, jungle, downtempo, breakbeats, trance… je kunt het zo gek niet verzinnen of hij vlecht het aan en door elkaar, verscholen in een futuristische spacewolk die je verblijf op de dansvloer transformeert in een trip door tijd en ruimte, terwijl je heupen overuren draaien. Zo is Sansibar verleden en toekomst tegelijk en zijn liveset goed voor het ultieme geluksgevoel.",
    "startTime": "2024-07-07T17:30:00.000Z",
    "endTime": "2024-07-07T18:30:00.000Z",
    "name": "Sansibar (live)",
    "socials": {
      "instagram": "https://www.instagram.com/sans1bar",
      "facebook": "https://www.facebook.com/SSansibar",
      "spotify": "https://open.spotify.com/artist/2iW1wJAN3tnT3fXoRPh6Kc?si=PVmBGmn4TGiuBhUS3Nugyg",
      "soundcloud": "https://soundcloud.com/sunnysibar",
      "website": "https://sinsistema.biz/"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2658_crop_1508-653--8-839_fit_1400-540-center_85-2021%20sansibar%20photos%20by%20adele%20hyry-2000px_site2%283%29.jpg"
  },
  {
    "id": 81,
    "description": "Aan avontuurlijke types dit weekend geen gebrek in Beuningen. Dat is maar goed ook, want rond de draaitafels van Sarkawt Hamad valt veel bloedspannends te beleven. De dj uit Amsterdam is niet bang voor afwijkende ritmes en blijft nooit langer bij een genre hangen dan strikt noodzakelijk. Kernwoorden: dubby, psychedelisch, melodieus, mysterieus, verleidelijk, verslavend. Hamad is een relatieve nieuwkomer, maar van de Moldau tot de Tigris wordt er naar haar gehengeld. Beter zie je haar hier nu het nog kan.Foto door Carmen Vollebregt",
    "startTime": "2024-07-07T18:30:00.000Z",
    "endTime": "2024-07-07T20:30:00.000Z",
    "name": "Sarkawt Hamad",
    "socials": {
      "instagram": "https://www.instagram.com/sarkawthamad/?hl=nl",
      "soundcloud": "https://soundcloud.com/sarkawthamad",
      "website": "https://linktr.ee/SarkawtHamad"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2609_crop_1600-696-227-187_fit_1400-540-center_85-2CARMENVOLLEBREGT-000068390012_site%282%29.jpg"
  },
  {
    "id": 82,
    "description": "Over ongeveer vijf miljard jaar is de zon opgebrand, beweert de wetenschap. Daar konden we op aarde weleens last van gaan krijgen. Gelukkig is er Session Victim. De livesets van dit Duitse duo hebben hetzelfde effect op de mens als die heliumbol die ons in Beuningen al zoveel moois heeft gebracht. Hauke Freer en Matthias Reiling geven ons warmte, licht en levensvreugde vol zomerse trompetjes, zwoele samples en broeierige beats. Zie ze stralen, daar achter de draaitafels. De zon kan met een gerust hart van haar pensioen genieten.",
    "startTime": "2024-07-05T17:30:00.000Z",
    "endTime": "2024-07-05T18:30:00.000Z",
    "name": "Session Victim (live)",
    "socials": {
      "instagram": "https://www.instagram.com/session_victim/",
      "facebook": "https://www.facebook.com/sessionvictim/",
      "twitter": "https://twitter.com/sessionvictim",
      "spotify": "https://open.spotify.com/artist/4Hl6TEQAFgH0XrZq4f8okX?si=DShlskCmSvK_NcsD5DXlAA",
      "soundcloud": "https://soundcloud.com/sessionvictim",
      "website": "https://sessionvictim.com/#Music"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2613_crop_1024-446-63_fit_1400-540-center_85-Session-Victim-Press-photo-by-Sc8-1-1024x683_site%282%29.jpg"
  },
  {
    "id": 83,
    "description": "Gedeelde vreugd is dubbele vreugd, zeggen ze. Maar als Guus Christian en Sander Emil de handen ineenslaan gaat het wel even een stapje verder dan de gebruikelijke een-plus-een-is-drie. Ze delen een diepe liefde voor zwoele house vol soul en jazz. Vermenigvuldig deze vrienden met elkaar en je krijgt een zonovergoten set vol hartstochtelijke vocalen en smakelijke licks. Zoek in je weekendtas naar die festivaloutfit vol pasteltinten, bestel je favoriete cocktail en leg de ijsblokjes in je nek. Het leven is goed, toch?",
    "startTime": "2024-07-05T12:30:00.000Z",
    "endTime": "2024-07-05T15:30:00.000Z",
    "name": "Shared Joy (Guus Christian & Sander Emil)",
    "socials": {},
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2651_crop_1820-791-44-62_fit_1400-540-center_85-Shared%20Joy_site%282%29.jpg"
  },
  {
    "id": 84,
    "description": "Aan de top komen is één, er blijven is iets anders. Dat Tom Trago al zijn halve leven tot de household names van de Nederlandse dancescene behoort, heeft niet alleen met hard werken te maken. Zijn nooit aflatende nieuwsgierigheid is misschien nog wel veel belangrijker. Vanuit de experimentele hiphop waarmee het allemaal begon verkende hij zo’n beetje elk genre; van uitbundige disco tot onderkoelde psychedelica en alles wat ertussen ligt. De enige constante in zijn synth-heavy sets is dat ze voortdurend van kleur verschieten en never nooit niet vervelen.",
    "startTime": "2024-07-05T15:30:00.000Z",
    "endTime": "2024-07-05T17:30:00.000Z",
    "name": "Tom Trago",
    "socials": {
      "instagram": "https://www.instagram.com/tomtrago/",
      "facebook": "https://www.facebook.com/tom.trago/",
      "twitter": "https://twitter.com/tomtrago",
      "spotify": "https://open.spotify.com/artist/2vUpX2Zq1DBdCHuoEnmzkK?si=JNyuFp5pSVGvfgXyWK5rFA",
      "soundcloud": "https://soundcloud.com/tomtrago",
      "website": "https://www.tomtrago.com/"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2617_crop_1440-626-48_fit_1400-540-center_85-Press%20Photo%20Tom%20Trago%2004_site%282%29.jpg"
  },
  {
    "id": 85,
    "description": "Elke vezel in je lichaam ontspant met 'Spaces of Silence', de meest recente langspeler van Pierre d’Estienne d’Orves alias Tour-Maubourg. Een pláátje van een plaat, volgekleurd met soulvocalen, originele baslijnen en laidback beats die met St Germain flirten. In 'Why (Life)' klinken echo's van UNKLE, op 'I Never Will' wipt Air gezellig aan. Weinig muziek klonk ooit zo loungy en relaxed, kortom zomers. Dat wordt zweven dus, waarbij je pas weer terug op aarde landt zodra de Fransman op weg is naar zijn volgende gig.Foto door Robin Delanoy",
    "startTime": "2024-07-05T18:30:00.000Z",
    "endTime": "2024-07-05T20:30:00.000Z",
    "name": "Tour-Maubourg",
    "socials": {
      "instagram": "https://www.instagram.com/tourmaubourg/",
      "facebook": "https://www.facebook.com/tourmaubourgofficial/",
      "spotify": "https://open.spotify.com/artist/7sbDfGq4RVRz6cEt5PH4Su?si=GzakYW-rT-Ow2n9G9YglZg",
      "soundcloud": "https://soundcloud.com/tour-maubourg",
      "website": "https://linktr.ee/Tourmaubourg?fbclid=PAAaasATNXePfOy1IsO1IKSEpzarJ3wiJjTYQQ6Imr85vA-qpdTm-cgTUsirU_aem_AfXF2PD21tFYNfhDAH3p3SkhFEg0n0-R-_6_NoipB_awSCYuYP-YjEXTINZVdNrSXYo"
    },
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2621_crop_1080-470-153_fit_1400-540-center_85-Tour-Maubourg%20%C2%A9%20Robin%20Delanoy%20%282%29_site%282%29.jpg"
  },
  {
    "id": 86,
    "description": "Yoga, klassieke muziek, een fenomenaal bakkie koffie binnen handbereik; in de Groene Heuvels is het altijd heerlijk ontwaken. Ook in de dag- en nachtclub REX begin je elke ochtend relaxed met de Morning Rave door dj Katjang Pedis, een mellow en mindful kickstart van je festivaldag voor onuitputtelijke nieuwe energie en verbinding met jezelf en elkaar. Eerst brengt een geleide meditatie met stretch- en ademhalingsoefeningen je bij je positieven, waarna je tijdens een bewegingsimprovisatie steeds intenser gaat dansen. Wakker en warm? Dan barst er een euforische rave los, zoals je van Down The Rabbit Hole gewend bent geheel in stijl met alle glitter & glow die daarbij hoort. En je weet: raven is leven.",
    "startTime": "2024-07-05T09:00:00.000Z",
    "endTime": "2024-07-05T10:00:00.000Z",
    "name": "Morning Rave with Katjang Pedis",
    "socials": {},
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2659_crop_2048-890-444_fit_1400-540-center_85-20230630_1117_Ochtendactiviteiten_BartHeemskerk-_LH_5798-CR2_-.jpg"
  },
  {
    "id": 87,
    "description": "Yoga, klassieke muziek, een fenomenaal bakkie koffie binnen handbereik; in de Groene Heuvels is het altijd heerlijk ontwaken. Ook in de dag- en nachtclub REX begin je elke ochtend relaxed met de Morning Rave door dj Katjang Pedis, een mellow en mindful kickstart van je festivaldag voor onuitputtelijke nieuwe energie en verbinding met jezelf en elkaar. Eerst brengt een geleide meditatie met stretch- en ademhalingsoefeningen je bij je positieven, waarna je tijdens een bewegingsimprovisatie steeds intenser gaat dansen. Wakker en warm? Dan barst er een euforische rave los, zoals je van Down The Rabbit Hole gewend bent geheel in stijl met alle glitter & glow die daarbij hoort. En je weet: raven is leven.",
    "startTime": "2024-07-06T09:00:00.000Z",
    "endTime": "2024-07-06T10:00:00.000Z",
    "name": "Morning Rave with Katjang Pedis",
    "socials": {},
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2659_crop_2048-890-444_fit_1400-540-center_85-20230630_1117_Ochtendactiviteiten_BartHeemskerk-_LH_5798-CR2_-.jpg"
  },
  {
    "id": 88,
    "description": "Yoga, klassieke muziek, een fenomenaal bakkie koffie binnen handbereik; in de Groene Heuvels is het altijd heerlijk ontwaken. Ook in de dag- en nachtclub REX begin je elke ochtend relaxed met de Morning Rave door dj Katjang Pedis, een mellow en mindful kickstart van je festivaldag voor onuitputtelijke nieuwe energie en verbinding met jezelf en elkaar. Eerst brengt een geleide meditatie met stretch- en ademhalingsoefeningen je bij je positieven, waarna je tijdens een bewegingsimprovisatie steeds intenser gaat dansen. Wakker en warm? Dan barst er een euforische rave los, zoals je van Down The Rabbit Hole gewend bent geheel in stijl met alle glitter & glow die daarbij hoort. En je weet: raven is leven.",
    "startTime": "2024-07-07T09:00:00.000Z",
    "endTime": "2024-07-07T10:00:00.000Z",
    "name": "Morning Rave with Katjang Pedis",
    "socials": {},
    "stage": "rex",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2659_crop_2048-890-444_fit_1400-540-center_85-20230630_1117_Ochtendactiviteiten_BartHeemskerk-_LH_5798-CR2_-.jpg"
  },
  {
    "id": 89,
    "description": "Een workshop Set Opbouwen & Dak Eraf Draaien, zo kun je een optreden van Alyah Rivière alias AliA omschrijven. Slinger op YouTube haar Boiler Room-show op Listen! Festival maar aan, een rijke bloemlezing van wat je hier straks kunt verwachten: alles wat warmbloedig en soulvol is belandt op de draaitafel van de Brusselse. Al op 17-jarige leeftijd kon haar verbluffende mixtechniek op onverdeeld enthousiasme van onder meer Red Bull Elektropedia rekenen, met als logisch gevolg dat de naam AliA in steeds grotere letters op de Europese festivalposters staat.",
    "startTime": "2024-07-07T00:30:00.000Z",
    "endTime": "2024-07-07T02:00:00.000Z",
    "name": "AliA",
    "socials": {
      "instagram": "https://www.instagram.com/alyahriv/",
      "facebook": "https://www.facebook.com/aliamusicc/",
      "soundcloud": "https://soundcloud.com/aliamusicc",
      "website": "https://linktr.ee/aliamusic"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2681_crop_2000-869-365_fit_1400-540-center_85-AliA_2020-PRESS%20%281%29_site%282%29.jpg"
  },
  {
    "id": 90,
    "description": "Gefeliciteerd, je staat op de gastenlijst van het pompende hiphop-, house - en dancehallfeestje van CHAMOS. De drie vrienden rijgen hun successen aaneen in een tempo waar Sifan Hassan of Max Verstappen zich niet voor zou schamen. Van R&B-kneiter ‘Dousman’ en de een-tweetjes met Dave Nunes tot de meest recente hit ‘Masoko’; de dancewereld blijft maar op lekkers getrakteerd worden. Via Barcelona naar Kuala Lumpur en weer terug naar The Bizarre verandert het duo clubs wereldwijd in krioelende taferelen, vol stralende gezichten, zwaaiende armen en – uiteraard – schuddende heupen.",
    "startTime": "2024-07-05T23:00:00.000Z",
    "endTime": "2024-07-06T00:30:00.000Z",
    "name": "CHAMOS",
    "socials": {
      "instagram": "https://www.instagram.com/wearechamos/",
      "facebook": "https://www.facebook.com/wearechamos/",
      "spotify": "https://open.spotify.com/artist/2HEwUTzrG0SLQLLSQQGBWi?si=xWE3Pu5uQ6mMuvnqOU65Pw",
      "soundcloud": "https://soundcloud.com/wearechamos",
      "website": "https://linktr.ee/wearechamos"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2685_crop_2000-869-305_fit_1400-540-center_85-CHAMOS_site%282%29.jpg"
  },
  {
    "id": 91,
    "description": "Chinnamasta is een dj met een missie. Als zonnetje in huis wil ze niets liever dan ook de dansvloer laten stralen. Maar haar zendingsdrift gaat dieper: als cultuuronderzoeker is zomaar een feestje bouwen voor haar niet genoeg: haar set moet de impact en wereldwijde invloed van de Caribische muziek onderstrepen, klanken die ze het liefst rauw en ongefilterd uit de speakers laat swingen. Daarvoor haalt de Amsterdamse haar bewijs zelfs uit de hele Afrikaanse diaspora. Maar het nettoresultaat blijft intussen duidelijk: get low!",
    "startTime": "2024-07-06T20:00:00.000Z",
    "endTime": "2024-07-06T21:30:00.000Z",
    "name": "Chinnamasta",
    "socials": {
      "instagram": "https://www.instagram.com/squishybvb/",
      "soundcloud": "https://soundcloud.com/raziyah-heath-471543061?ref=clipboard&p=i&c=0&si=C17899AB4FD4485CB56E324624E5008A&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
      "website": "https://linktr.ee/squishybvb"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2689_crop_2000-869-489_fit_1400-540-center_85-Chinnamasta_site%282%29.jpg"
  },
  {
    "id": 92,
    "description": "Je hebt housemuziek en housemuziek. Wat heet, house is er in alle kleuren van de regenboog. In de handen van Cincity is het een dromerige, eclectische mashup van gedempte four-to-the-floorbeats en de Afrikaanse roots van deze Rotterdamse dj. Voor wie het duidelijker hebben wil: deephouse meets afrohouse, gefilterd door een hippiebril. Ook de culturele smeltkroes die haar woonplaats is spreekt natuurlijk een muziekje mee en zo maak je aan de hand van Cindy’s grenzeloze grooves een heuse wereldtrip.",
    "startTime": "2024-07-06T00:30:00.000Z",
    "endTime": "2024-07-06T02:00:00.000Z",
    "name": "Cincity",
    "socials": {
      "instagram": "https://www.instagram.com/cincityofficial",
      "facebook": "https://www.facebook.com/djcincity/?locale=nl_NL",
      "spotify": "https://open.spotify.com/artist/2JTUg3LgzJsqxzNnPB9hen?si=pMw9m0lAR8iVurkqFtzejg",
      "soundcloud": "https://soundcloud.com/cincitymusic",
      "website": "https://www.burst-artists.com/cincity"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2693_crop_1806-785-3-302_fit_1400-540-center_85-Cincity_site%282%29.jpg"
  },
  {
    "id": 93,
    "description": "De Spaanse dj Clara! is je favoriete reisgids in het zinderende zweetland dat reggaeton heet. De trip begint waar de bekende hits ophouden. In haar sets neemt de onverwoestbare boem-takke-tak-takbeat de meest uiteenlopende gedaanten aan. Sinds het genre vanaf de jaren 90 vanuit Puerto Rico als een wervelwind rond de aarde trok, zijn er tig subgenres ontsproten. Het gaat van dembow en neoperreo tot cross-overs als trapaton en acidton. Clara! voert je buiten de gebaande paden langs onontdekte oases en prachtige vergezichten.",
    "startTime": "2024-07-07T17:00:00.000Z",
    "endTime": "2024-07-07T18:10:00.000Z",
    "name": "Clara!",
    "socials": {
      "instagram": "https://www.instagram.com/claraaaaaaaaaaaaaaaaaaaa_/",
      "spotify": "https://open.spotify.com/artist/5Iel4Yqnn76GGKvs2ZRNsz?si=ry9tXN6yRAK4rDLDPUXz8A",
      "soundcloud": "https://soundcloud.com/clara-uno",
      "website": "https://linktr.ee/Claraaaaaaaaaa"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2697_crop_2000-870-176_fit_1400-540-center_85-Clara%21%20photographies%20Coquelin_site%282%29.jpg"
  },
  {
    "id": 94,
    "description": "Zoek op ‘opbeurende house’ en je krijgt een afbeelding van Jelle Schoneveld alias Cleanfield te zien. Deze dj/producer uit Arnhem met een voorkeur voor 90s acid, progressive, house en breaks heeft het talent om precies de juiste balans tussen oud en nieuw te vinden. Disclosure gunde hem een plek op hun ‘DJ-Kicks’ en festivals als Lowlands, Wildeburg en Subcultuur raakten al in vervoering door zijn hypnotiserende sets. Nu is het de beurt aan de Groene Heuvels om te genieten van zijn elektronische zonnestralen.",
    "startTime": "2024-07-05T18:30:00.000Z",
    "endTime": "2024-07-05T20:00:00.000Z",
    "name": "Cleanfield",
    "socials": {
      "instagram": "https://www.instagram.com/cleanfieldmusic",
      "facebook": "https://www.facebook.com/Cleanfieldmusic/",
      "spotify": "https://open.spotify.com/artist/77Rl2fZAHgCfAjqB5X7dfH?si=Q6WsZ7VqRK-jp8YmTBUL0g",
      "soundcloud": "https://soundcloud.com/cleanfieldmusic",
      "website": "https://linktr.ee/cleanfield"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2701_crop_2000-869-250_fit_1400-540-center_85-Jelle%202022-10%282%29.jpg"
  },
  {
    "id": 95,
    "description": "Hij mag dan gewoon uit Nijmegen komen, als dj en producer kijkt twintiger Dave Nunes graag verder dan de Hollandse klei. Afrohouse is voor hem de basis, de laatste jaren voor een flink deel toegespitst op amapiano, met zijn onweerstaanbare percussie en logdrums. Daarbovenop stapelt hij een jengabouwsel van blokken elektronica met wereldse invloeden, edits, afro, house en baile funk. Waarbij hij evenveel blokjes wegtrekt als (weer) toevoegt, onnavolgbaar goochelend met de schuiven en knoppen van zijn cdj-deck. En dan maar dansen, mensen.",
    "startTime": "2024-07-06T17:00:00.000Z",
    "endTime": "2024-07-06T18:30:00.000Z",
    "name": "Dave Nunes",
    "socials": {
      "instagram": "https://www.instagram.com/davenunes_",
      "facebook": "https://www.facebook.com/davenunesdj",
      "spotify": "https://open.spotify.com/artist/1Jo0kVGvfYzeXGjMUvfmLR?si=cgI_hiMjQTi1s-7Tlf4VnQ",
      "soundcloud": "https://soundcloud.com/davenunes"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2705_crop_1080-470-21_fit_1400-540-center_85-Dave_Nunes_site%282%29.png"
  },
  {
    "id": 96,
    "description": "DEBORAH X is van de Generatie Y, maar ze draait vooral voor Generatie Z. Ze trakteert je op een stevige maar soulvolle blend van klassieke house en techno, aaneengelast met UK garage en afrobeats en de industriële vibe van bass, percussie en extra hard ingemixte kickdrums. Als lid van het Rotterdamse dj-collectief AMPFEMININE staat Deborah bovendien voor het brengen van kleur en diversiteit achter de dj-decks. Foto door Maria Grundlehner",
    "startTime": "2024-07-07T20:00:00.000Z",
    "endTime": "2024-07-07T21:30:00.000Z",
    "name": "DEBORAH X",
    "socials": {
      "instagram": "https://www.instagram.com/deborah__x__",
      "soundcloud": "https://soundcloud.com/deborah_x",
      "website": "https://linktr.ee/deborahsumter"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2709_crop_668-291-118_fit_1400-540-center_85-deborah%20x%20foto%20maria%20grundlehner%206_site%282%29.jpg"
  },
  {
    "id": 97,
    "description": "Wie inspireren een dansvloer beter dan twee gasten die de hiphopmoves zelf als geen ander beheersen? Chris Breinburg en SHINSHAN Salazar bleken tijdens hun dansopleiding met elkaar te kunnen lezen, schrijven én draaien: DIFFSUMM is dus dansend geboren. Met hiphop, maar ook afrobeat, jazz en UK garage jagen ze menigtes in clubs en festivaltenten al snel de stuipen op de heupen. Salazar is de half-Cubaanse percussiespecialist, Breinburg de R&B- en soulman. Kom kijken en genieten, want hun yin-en-yang-vriendschap en speelplezier zijn hyperaanstekelijk.",
    "startTime": "2024-07-05T14:00:00.000Z",
    "endTime": "2024-07-05T15:30:00.000Z",
    "name": "DIFFSUMM",
    "socials": {
      "instagram": "https://www.instagram.com/diffsumm/",
      "website": "https://www.youtube.com/@diffsumm",
      "soundcloud": "https://soundcloud.com/diff-summ"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2713_crop_1170-509-484_fit_1400-540-center_85-unnamed%282%29.jpg"
  },
  {
    "id": 98,
    "description": "Diehard ravers en vroege paradijsvogels komen elke dag samen tijdens Echoes, een catch-all kickstart van je flow in The Bizarre. Net als de getijden zoeken de beste feestjes immers een harmonieuze balans tussen eb en vloed, komen en gaan, en rijzen en indalen. Met dj Wes Lee aan de decks volg je de energie van helende ambient naar uptempo sounds met diepe wortels in de diaspora – denk amapiano, (afro)house/tech, gqom en alles daartussenin, in combinatie met spirituele soundhealing en liveperformances van o.a. Phantom Wizard, mc MODE en Sydney Lowell.Foto: Voorstelling door Bruno Sitton bij Potato Head Bali",
    "startTime": "2024-07-05T11:00:00.000Z",
    "endTime": "2024-07-05T12:30:00.000Z",
    "name": "Echoes: Wes Lee, MODE, Phantom Wizard & Sydney Lowell",
    "socials": {
      "instagram": "https://www.instagram.com/dj.weslee/",
      "facebook": "https://www.facebook.com/djwaxfiend",
      "twitter": "https://twitter.com/djwaxfiend",
      "spotify": "https://open.spotify.com/artist/5jX2NDv1nyXv0FCEP3gqwR?si=y0csCyugT1KyrLw3nqPbUA",
      "soundcloud": "https://soundcloud.com/listentowax"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2784_crop_2000-870-240_fit_1400-540-center_85-DAY%203-09038_credit_%27Performance%20by%20Bruno%20Sitton%20at%20Potato%20Head%20Bali%27_site%282%29.jpg"
  },
  {
    "id": 99,
    "description": "Diehard ravers en vroege paradijsvogels komen elke dag samen tijdens Echoes, een catch-all kickstart van je flow in The Bizarre. Net als de getijden zoeken de beste feestjes immers een harmonieuze balans tussen eb en vloed, komen en gaan, en rijzen en indalen. Met dj Wes Lee aan de decks volg je de energie van helende ambient naar uptempo sounds met diepe wortels in de diaspora – denk amapiano, (afro)house/tech, gqom en alles daartussenin, in combinatie met spirituele soundhealing en liveperformances van o.a. Phantom Wizard, mc MODE en Sydney Lowell.Foto: Voorstelling door Bruno Sitton bij Potato Head Bali",
    "startTime": "2024-07-06T11:00:00.000Z",
    "endTime": "2024-07-06T12:30:00.000Z",
    "name": "Echoes: Wes Lee, MODE, Phantom Wizard & Sydney Lowell",
    "socials": {
      "instagram": "https://www.instagram.com/dj.weslee/",
      "facebook": "https://www.facebook.com/djwaxfiend",
      "twitter": "https://twitter.com/djwaxfiend",
      "spotify": "https://open.spotify.com/artist/5jX2NDv1nyXv0FCEP3gqwR?si=y0csCyugT1KyrLw3nqPbUA",
      "soundcloud": "https://soundcloud.com/listentowax"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2784_crop_2000-870-240_fit_1400-540-center_85-DAY%203-09038_credit_%27Performance%20by%20Bruno%20Sitton%20at%20Potato%20Head%20Bali%27_site%282%29.jpg"
  },
  {
    "id": 100,
    "description": "Diehard ravers en vroege paradijsvogels komen elke dag samen tijdens Echoes, een catch-all kickstart van je flow in The Bizarre. Net als de getijden zoeken de beste feestjes immers een harmonieuze balans tussen eb en vloed, komen en gaan, en rijzen en indalen. Met dj Wes Lee aan de decks volg je de energie van helende ambient naar uptempo sounds met diepe wortels in de diaspora – denk amapiano, (afro)house/tech, gqom en alles daartussenin, in combinatie met spirituele soundhealing en liveperformances van o.a. Phantom Wizard, mc MODE en Sydney Lowell.Foto: Voorstelling door Bruno Sitton bij Potato Head Bali",
    "startTime": "2024-07-07T11:00:00.000Z",
    "endTime": "2024-07-07T12:30:00.000Z",
    "name": "Echoes: Wes Lee, MODE, Phantom Wizard & Sydney Lowell",
    "socials": {
      "instagram": "https://www.instagram.com/dj.weslee/",
      "facebook": "https://www.facebook.com/djwaxfiend",
      "twitter": "https://twitter.com/djwaxfiend",
      "spotify": "https://open.spotify.com/artist/5jX2NDv1nyXv0FCEP3gqwR?si=y0csCyugT1KyrLw3nqPbUA",
      "soundcloud": "https://soundcloud.com/listentowax"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2784_crop_2000-870-240_fit_1400-540-center_85-DAY%203-09038_credit_%27Performance%20by%20Bruno%20Sitton%20at%20Potato%20Head%20Bali%27_site%282%29.jpg"
  },
  {
    "id": 101,
    "description": "Trek in onvervalste, pure reggae? Als je geluk hebt, draait Ezri Jade het, zoals eerder dit jaar een hele set voor het Rotterdamse Operator Radio. Zo niet, dan is het in elk geval goed om te weten dat de Guyaans-Nederlandse afro-dj daar en bij andere Caribische riddims wel ooit de muzikale mosterd heeft gehaald. Van daaruit én vanuit haar geloof in de pan-Afrikaanse gedachte was de oversteek naar afrohouse en afrobeats een logische, want is Afrika voor rasta’s niet de moederschoot?",
    "startTime": "2024-07-07T15:30:00.000Z",
    "endTime": "2024-07-07T17:00:00.000Z",
    "name": "Ezri Jade",
    "socials": {
      "instagram": "https://www.instagram.com/ezrijade/",
      "soundcloud": "https://soundcloud.com/ezrijade",
      "website": "https://linktr.ee/ezrijade"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2718_crop_2000-869-671_fit_1400-540-center_85-ezrijade-4_site%282%29.jpg"
  },
  {
    "id": 102,
    "description": "Rol je lichtgevende dansvloer uit en hang je spiegelbol aan het plafond: Fleur du Bonheur brengt disco, funk en boogie naar The Bizarre. Haar platen haalt ze met haar tijdmachine uit de hoogtijdagen van de discohype, met af en toe een stiekem uitstapje naar vroege house. Je weet wel: de tijd van wijde pijpen, glitterjurkjes en extravagante zonnebrillen. Maar hoewel Fleur met twee benen in het verleden staat, voelen haar sets geen moment gedateerd. Ze weet de evergreens blind van de niemendalletjes te scheiden.",
    "startTime": "2024-07-06T14:00:00.000Z",
    "endTime": "2024-07-06T15:30:00.000Z",
    "name": "Fleur du Bonheur",
    "socials": {
      "instagram": "https://www.instagram.com/fleur.du.bonheur",
      "facebook": "https://www.facebook.com/deejayfleurdubonheur",
      "soundcloud": "https://soundcloud.com/fleurdubonheur",
      "website": "https://www.fleurdubonheur.com/"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2722_crop_2000-869-260_fit_1400-540-center_85-Fleur_du_Bonheur_site%282%29.jpg"
  },
  {
    "id": 103,
    "description": "Als producer staat FS Green op speeddial bij bazen uit binnen- en buitenland; van Major Lazer tot Sevdaliza. Als dj is deze Amsterdamse groefgraver ook al decennialang een hoeksteen in festival- en clubland, waar zijn snikhete blend van elektronisch lekkers met afrobeat, dancehall en house de stoom van de dansvloer doet slaan. Van bezielde remixes tot zijn retecatchy eigen tracks, bij FS Green zit je nooit verkeerd – al was het maar omdat zitten tijdens zijn sets simpelweg onmogelijk is.",
    "startTime": "2024-07-07T23:00:00.000Z",
    "endTime": "2024-07-08T00:30:00.000Z",
    "name": "FS Green",
    "socials": {
      "instagram": "https://www.instagram.com/fsgreen/?hl=en",
      "facebook": "https://www.facebook.com/iamfsgreen/",
      "website": "https://lnk.bio/fsgreen",
      "spotify": "https://open.spotify.com/artist/2xjVYIMLS3nR5Y1UOr02Vb?si=XztH1JnoSrG_CSK1ayhPQw",
      "soundcloud": "https://soundcloud.com/fsgreen"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2726_crop_2000-869-44_fit_1400-540-center_85-FS%20Green%20007_site%282%29.jpg"
  },
  {
    "id": 104,
    "description": "Net als andere wereldjes toonde de dj-scene zich een nogal masculiene aangelegenheid, merkte Joya Blaauw als beatdropper van The Eclectic Eleven. Dus droeg ze met haar collectief Voortouw bij aan wat normaal zou moeten zijn: meer diversiteit in de booth. Die daadkracht leidt langs Amsterdam Open Air en Milkshake richting downtown Rabbit Hole, waar ze haar mixsurprises door The Bizarre laat pompen. De maffe baslijn is vaste prik in een set vol house, rave, drum-’n-bass, hiphop – wat niet? Ook hier doet Joya Blaauw niet aan uitsluiting.",
    "startTime": "2024-07-06T12:30:00.000Z",
    "endTime": "2024-07-06T14:00:00.000Z",
    "name": "Joya Blaauw",
    "socials": {
      "instagram": "https://www.instagram.com/joyablaauw/",
      "facebook": "https://www.facebook.com/joya.blaauw/",
      "soundcloud": "https://soundcloud.com/joya-felicia-blaauw"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2730_crop_828-360-27_fit_1400-540-center_85-Joya_Blaauw_site%282%29.jpg"
  },
  {
    "id": 105,
    "description": "Voor Akjitra Phuangkularb, het Amsterdamse nachtleven afschuimend onder de naam Kinnari, zijn de decks een verlengstuk van haar emoties, dierbare herinneringen en vooral grenzeloze muzikale passie. Ze kan je op druilerige ochtenden wekken met geruststellende sets, maar op avonden dan wel nachten zoals die in The Bizarre haalt ze flink de boel overhoop. En speelt de beat in uitbundige garage, afro, uptempo R&B en hiphop – puur op gevoel geïmproviseerd – eenzelfde glansrol als vocalen, melodie en sfeer. Warmbloedige taferelen creëren, laat dat maar aan Kinnari over.",
    "startTime": "2024-07-07T12:30:00.000Z",
    "endTime": "2024-07-07T14:00:00.000Z",
    "name": "Kinnari",
    "socials": {
      "instagram": "https://www.instagram.com/akjitra",
      "soundcloud": "https://soundcloud.com/kinnaridj",
      "website": "https://www.kinnaridj.com/"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2734_crop_2000-870-71_fit_1400-540-center_85-Kinnari_site%282%29.jpg"
  },
  {
    "id": 106,
    "description": "Lefto’s platenkoffer biedt meer variatie dan je favoriete streamingdienst. Onze zuiderbuur koppelt Pakistaanse filmwalsjes moeiteloos aan underground-rap, om via Britse bigbandsoul bij Johann Sebastian Bach uit te komen. Voor hem zijn zulke routes net zo vanzelfsprekend als voor jou de weg van je huis naar de dichtstbijzijnde supermarkt. Boven alles is Lefto Early Bird een sucker voor jazz en abstracte ritmes. Dat hij verkozen werd tot beste dj van België heeft niet alleen met zijn muziekkeuzes te maken. Ook technisch behoort deze man tot de absolute wereldtop.",
    "startTime": "2024-07-06T21:30:00.000Z",
    "endTime": "2024-07-06T23:00:00.000Z",
    "name": "Lefto Early Bird",
    "socials": {
      "instagram": "https://www.instagram.com/lefto_early_bird/",
      "facebook": "https://www.facebook.com/leftooo/?locale=nl_NL",
      "website": "https://linktr.ee/lefto",
      "spotify": "https://open.spotify.com/artist/38qHUVZbVZfKq6NJxTU9oj?si=ulbRMDvXRSOumUfWwSCMsA",
      "soundcloud": "https://soundcloud.com/lefto"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2738_crop_2000-869-671_fit_1400-540-center_85-Lefto_Early_Bird_site%282%29.jpg"
  },
  {
    "id": 107,
    "description": "Het überdansbare verrassingselement is daar als Lizzy Green je meesleurt in haar vintage danswereld. Een vinyljuweel uit pakweg 1983 is niet obscuur genoeg of verdwijnt in haar platentas, om op Open Source Radio, Wildeburg of in je favoriete konijnenresort de toon te zetten. Haak aan in een set vol acid, house, new beat, synthpop en (italo-)disco, allemaal afkomstig uit, dan wel schatplichtig aan het decennium dat meer dan ooit scoort op menig dansvloer. Oldschool extase in een nieuw oversized colbert.",
    "startTime": "2024-07-07T14:00:00.000Z",
    "endTime": "2024-07-07T15:30:00.000Z",
    "name": "Lizzy Green",
    "socials": {
      "instagram": "https://www.instagram.com/lizzygreennn/",
      "soundcloud": "https://soundcloud.com/lizzygreenn",
      "website": "https://linktr.ee/lizzygreenn"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2742_crop_2281-992-1007_fit_1400-540-center_85-B78ACD67-FB80-4D33-941B-6D00862F3112%282%29.jpeg"
  },
  {
    "id": 108,
    "description": "De stijl van Mo Jakob lijkt op papier nogal wijduitlopend. Hij spurt van hiphop via techno naar de rauwere kant van footwork. Ondanks die verscheidenheid klinken zijn sets buitengewoon coherent. Zijn geheim? Hij draait alleen platen die hem herinneren aan Rotterdam. En zo voelt het ook. Mo Jakobs sets zijn hard, kil en recht voor zijn raap, maar tegelijkertijd goudeerlijk en gastvrij. Four-to-the-floortypes moeten wel een beetje hun best doen. Deze hindernisbaan vol abstracte stuiterbeats volbreng je niet op de automatische piloot.",
    "startTime": "2024-07-08T00:30:00.000Z",
    "endTime": "2024-07-08T02:00:00.000Z",
    "name": "Mo Jakob",
    "socials": {
      "instagram": "https://www.instagram.com/championlovaaa/",
      "facebook": "https://www.facebook.com/mojakob/?locale=nl_NL",
      "soundcloud": "https://soundcloud.com/mojakob",
      "website": "https://linktr.ee/mojakob"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2745_crop_2000-869-322_fit_1400-540-center_85-Mo%20Jakob%20press%203_site%282%29.jpg"
  },
  {
    "id": 109,
    "description": "Kind aan huis in het clubleven van Utrecht is NALA, die je eraan herinnert welk genre de basis is van uiteenlopende dancestijlen. Funk kreeg in de muziekgeschiedenis veel kinderen en de als Gillian Bohan geboren dj laat ze harmonieus samenspelen, of deze nu R&B, hiphop of futurebeat heten. Jarenlang luisteren, matchen, mixen en mashen baart draaikunst. In homebase EKKO bijvoorbeeld, of op ADE. Én straks in The Bizarre, als NALA andermaal een stampende set opbouwt vanuit haar heilige dancedrie-eenheid, bestaande uit energie, genres en bpm's.",
    "startTime": "2024-07-07T18:10:00.000Z",
    "endTime": "2024-07-07T20:00:00.000Z",
    "name": "NALA",
    "socials": {
      "instagram": "https://www.instagram.com/nalaontheweb/",
      "facebook": "https://www.facebook.com/nalaontheweb",
      "soundcloud": "https://soundcloud.com/nalaontheweb",
      "website": "https://linkr.bio/k3n8r"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2749_crop_2000-870-293_fit_1400-540-center_85-TARONA_GILLIAN_NALA_08_WEB_site%282%29.jpg"
  },
  {
    "id": 110,
    "description": "Op Radio Radio FM zijn maandelijks nieuwe favoriete tracks te horen van de Mokumse Nèna, die opvalt door haar gloeiend hete stijlenmix. Dancehall, reggaeton en techno spelen hierin de hoofdrollen, terwijl deze dj genoeg ruimte overlaat voor samples, percussie en zwierige melodieën. Laag voor laag ontstaat er een heerlijk loom en vervreemdend panorama, maar pas op: badend in deze kabbelende beek kun je zomaar in een verraderlijke, maar o zo opwindende stroomversnelling belanden.",
    "startTime": "2024-07-05T15:30:00.000Z",
    "endTime": "2024-07-05T17:00:00.000Z",
    "name": "Nèna",
    "socials": {
      "instagram": "https://www.instagram.com/nena.info",
      "soundcloud": "https://soundcloud.com/castro-nena"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2753_crop_1638-712-325_fit_1400-540-center_85-Helena-MdeGraaff-007%282%29.jpg"
  },
  {
    "id": 111,
    "description": "Patrick Mordi werd geboren in de Verenigde Staten, groeide op in Lagos en streek uiteindelijk neer in onze hoofdstad. Ondertussen ontwikkelde hij een enorme passie voor Japanse (muziek)cultuur – een passie die hij deelt op zijn videokanaal, dat vooral in Japan mateloos populair is. De vier werelddelen die hem vormden weet hij op wonderbaarlijke wijze te vatten in zijn dj-sets, waarbij de nadruk ligt op hiphop, R&B en afrobeats. Op momenten ritst hij zijn loom-zwoele platentas open, maar doorgaans is energiek het sleutelwoord bij mister Mordi.",
    "startTime": "2024-07-06T18:30:00.000Z",
    "endTime": "2024-07-06T20:00:00.000Z",
    "name": "Patrick Mordi",
    "socials": {
      "instagram": "https://www.instagram.com/patrickmordi/",
      "twitter": "https://twitter.com/patrickmordi",
      "website": "https://www.youtube.com/@Patrickmordi",
      "soundcloud": "https://soundcloud.com/patrickmordi"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2757_crop_980-426-350_fit_1400-540-center_85-PATRICKMORDIxBRANKO4%282%29.jpg"
  },
  {
    "id": 112,
    "description": "Caraïben represent! De van Curaçao afkomstige Rozaly heeft een missie: het vocabulaire van de overzeese sound uitbreiden met verloren erfgoed en een dosis absurdisme. Denk aan een cocktail met een lekker smaakje dat je niet helemaal thuis kunt brengen. Deze pionier verspreidt zijn boodschap ook als curator en sounddesigner voor merken als Marshall, Warp en Patta. De resident-dj van Garage Noord bracht eerder de boel in beweging op Dekmantel en Nyege Nyege en is klaar om zijn zomerse vibes op The Bizarre los te laten.Foto door Selwyn de Wind",
    "startTime": "2024-07-06T23:00:00.000Z",
    "endTime": "2024-07-07T00:30:00.000Z",
    "name": "Rozaly",
    "socials": {
      "instagram": "https://www.instagram.com/rozalyyyyy",
      "soundcloud": "https://soundcloud.com/rozalymusic",
      "website": "https://linktr.ee/rozaly"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2761_crop_1500-652-1062_fit_1400-540-center_85-Rozaly%20by%20Selwyn%20de%20Wind%20front_site%282%29.jpg"
  },
  {
    "id": 113,
    "description": "Kenners en liefhebbers horen deze NL-Alert al een tijdje in de wandelgangen van de scene loeien. S!RENE hoeft niet te pochen dat hij kwaliteitsinstituten als Soulection en Boiler Room in zijn hoek heeft, want zijn meesterlijke sets en discografie spreken boekdelen. Met soevereine grandeur mixt de Amsterdams-Nigeriaanse dj/producer moddervette club-edits met eigen werk, genre- en stijlhoppend als een bpm-happy gazelle door de hele platenzaak. Afrobeats, amapiano, jazz, Afrikaanse funk, house en hiphop komen allemaal voorbij, doorspekt met kaakdroppende samples die je doen gaan als – jawel – de brandweer.",
    "startTime": "2024-07-07T21:30:00.000Z",
    "endTime": "2024-07-07T23:00:00.000Z",
    "name": "S!RENE",
    "socials": {
      "instagram": "https://www.instagram.com/manlikesirene/?hl=en",
      "spotify": "https://open.spotify.com/artist/5cZy9VxDFRxCj7tRu5sPpl?si=e8tlsdmKQ-yFk4q36NaLXA",
      "soundcloud": "https://soundcloud.com/manlikesirene"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2765_crop_2000-869-646_fit_1400-540-center_85-S%21RENE%20-%203_site%282%29.jpg"
  },
  {
    "id": 114,
    "description": "Afzonderlijk zijn deze twee beatdroppers al dansvloermagneten, maar samen? Fire in the Rabbit Hole! Tijdens een magische set jutten Sef en FAISAL elkaar op met het heetste uit elkaars platenkoffers die, deze groove-aholics kennende, overlopen van de gepeperde classics en deepcuts. Special guest Sef en de Belgische dj/producer FAISAL kennen elkaar uit de studio: FAISAL coproduceerde namelijk Sefs laatste album ‘Ik Zou Voor Veel Kunnen Sterven Maar Niet Voor Een Vlag’. Een bonafide b2b doen ze echter maar heel zelden, dus dit buitenkansje in The Bizarre kun je niet laten lopen.Foto door Eva Roefs",
    "startTime": "2024-07-05T17:00:00.000Z",
    "endTime": "2024-07-05T18:30:00.000Z",
    "name": "Sef b2b FAISAL",
    "socials": {
      "instagram": "https://www.instagram.com/faisxl/?hl=en",
      "facebook": "https://www.facebook.com/LikeSef/?locale=nl_NL",
      "twitter": "https://twitter.com/yousefgnaoui?lang=en",
      "website": "https://linktr.ee/yousefgnaoui",
      "spotify": "https://open.spotify.com/artist/5VGhS6nHpXPbXGhku9RRB0?si=t-k8rhFSR1-je1TskDdjmA",
      "soundcloud": "https://soundcloud.com/FAISAL"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2769_fit_1400-540-center_85-Sef_b2b_Faisal_site%282%29.png"
  },
  {
    "id": 115,
    "description": "Solange leerde dj’en door heel goed te kijken naar good old Grandmaster Flash. Wat uit haar beginjaren is overgebleven zijn haar bizarre skills en een niet te doven liefde voor hiphop. Tegenwoordig draait ze ook graag dancehall, jungle, techno en verse afrotunes. Aan dertien-in-een-dozijnmuziek heeft ze een bloedhekel: je hoort alleen platen met een duidelijke eigen signatuur. Gemene deler is de rauwe energie die je misschien kent van illegale tunnelraves. Solange AB gunt de meute nauwelijks rust. Gas erop! Chillen doe je thuis maar.",
    "startTime": "2024-07-05T21:30:00.000Z",
    "endTime": "2024-07-05T23:00:00.000Z",
    "name": "Solange AB",
    "socials": {
      "instagram": "https://www.instagram.com/solangeabdj/",
      "soundcloud": "https://soundcloud.com/solangeab",
      "website": "https://linktr.ee/Solangeab"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2773_crop_2000-869-175_fit_1400-540-center_85-Solange_AB_site%282%29.jpg"
  },
  {
    "id": 116,
    "description": "Zin om te boogiën op warmbloedige tunes met spierballen van staal? Trippy Tins kennen doorgewinterde Amsterdamse clubbers van Lofi, De Schietclub, Shelter, Paradiso en het kleurrijke clubevent Palet. Met bass, percussie en soul mikt deze mixmeester en aanstormende producer rechtstreeks op de vlinders in je onderbuik, wat hem bij uitstek geknipt maakt voor de dampende dansarena The Bizarre – net als zijn onberispelijke smaak in house, UK funky, afrohouse en andere footloose vibes.",
    "startTime": "2024-07-05T20:00:00.000Z",
    "endTime": "2024-07-05T21:30:00.000Z",
    "name": "Trippy Tins",
    "socials": {
      "instagram": "https://www.instagram.com/trippy.tins/",
      "soundcloud": "https://soundcloud.com/trippytins"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2777_crop_1312-570-601_fit_1400-540-center_85-4d72c639-faf9-440c-8ebd-af27a1e8dc87%20%281%29%282%29.JPG"
  },
  {
    "id": 117,
    "description": "De fietstocht van Arnhem naar Beuningen duurt ongeveer een uurtje en is werkelijk prachtig, maar hij valt in het niet bij de reis die Urkel Shounen je gaat voorschotelen. Zijn stijl loopt uiteen van afrobeats en amapiano tot future, garage en jungle. Hij heeft een grote voorkeur voor platen die nog bijna niemand gehoord heeft en kiest vaak voor gewaagde cross-overs en rake remixen. In de Arnhemse clubscene geldt hij als grote meneer. Na deze festivalzomer staat hij ongetwijfeld ook in de rest van het land op de kaart.",
    "startTime": "2024-07-05T12:30:00.000Z",
    "endTime": "2024-07-05T14:00:00.000Z",
    "name": "Urkel Shounen",
    "socials": {
      "instagram": "https://www.instagram.com/urkelshounen/",
      "twitter": "https://twitter.com/urkelshounen",
      "soundcloud": "https://soundcloud.com/urkelshounen",
      "website": "https://linktr.ee/urkelshounenlinkss"
    },
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2781_crop_2000-869-168_fit_1400-540-center_85-Urkel_Shounen_site%282%29.jpg"
  },
  {
    "id": 118,
    "description": "Start je dag in The Bizarre met een dansworkshop die de mist in je hoofd definitief verdrijft. Tijdens de AfroMix Workshop leer je grootse, aardse moves te maken, aangevuurd door opzwepende livepercussie en een power-packed performance van de dansgroep AfroMixChicks onder leiding van Avalon Brown. Laat alle spanning los, voel die nieuwe energie stromen en dans op volle kracht vooruit naar de tjokvolle dag die komen gaat. AfroMix it up! Ns de performance ga je zelf aan de slag met de workshop.",
    "startTime": "2024-07-05T09:30:00.000Z",
    "endTime": "2024-07-05T10:30:00.000Z",
    "name": "Afromix Workshop",
    "socials": {},
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2837_fit_1400-540-center_85-8f485506-a350-4766-b3ed-171997f19d32%281%29.jpeg"
  },
  {
    "id": 119,
    "description": "Start je dag in The Bizarre met een dansworkshop die de mist in je hoofd definitief verdrijft. Tijdens de AfroMix Workshop leer je grootse, aardse moves te maken, aangevuurd door opzwepende livepercussie en een power-packed performance van de dansgroep AfroMixChicks onder leiding van Avalon Brown. Laat alle spanning los, voel die nieuwe energie stromen en dans op volle kracht vooruit naar de tjokvolle dag die komen gaat. AfroMix it up! Ns de performance ga je zelf aan de slag met de workshop.",
    "startTime": "2024-07-06T09:30:00.000Z",
    "endTime": "2024-07-06T10:30:00.000Z",
    "name": "Afromix Workshop",
    "socials": {},
    "stage": "the bizarre",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2837_fit_1400-540-center_85-8f485506-a350-4766-b3ed-171997f19d32%281%29.jpeg"
  },
  {
    "id": 120,
    "description": "Aili Maruyama en Orson Wouters brengen ons retro synthesizers, verslavende basloopjes en smaakvolle in-your-face-beats zoals ze die sinds het begin van de houserevolutie alleen in België kunnen maken. Haar teksten schrijft Maruyama in het Japans. Ze verliet dat land op haar zevende en spreekt een geheel eigen versie van de taal, doorspekt met letterlijk vertaalde Vlaamse gezegdes. Japanse fans smullen ervan: debuutplaat ‘Nandakke?’ werd een regelrechte hit in het Verre Oosten. Ook als je de taal niet machtig bent ervaar je geheid de aanstekelijke speelsheid van Aili. Foto door Tina Lewis Herbots",
    "startTime": "2024-07-05T13:00:00.000Z",
    "endTime": "2024-07-05T13:45:00.000Z",
    "name": "Aili",
    "socials": {
      "instagram": "https://www.instagram.com/aili_inc/?hl=en",
      "website": "https://linktr.ee/aili.computer",
      "spotify": "https://open.spotify.com/artist/4hKrRKWY1mF3LTwGIMj99R?si=DgZdxORgQ0im-eAhyFcXvw"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2845_crop_2000-870-144_fit_1400-540-center_85-HQ-AILI230808%C2%A9TinaLewisHerbots_00929%2016%209_site%282%29.jpg"
  },
  {
    "id": 121,
    "description": "Haar stem is helder als water, maar haar breekbare, emotionele liedjes vol tumult en trauma weerspiegelen de eenzaamheid van Schotse hooglanden en eindeloze Scandinavische wouden. Toch is Bess Atwell zo Engels als Engels maar zijn kan en nam ze haar recente single ‘Sylvester’ heel aards en gezellig op in de Amerikaanse studio van producer en The National-gitarist Aaron Dessner. En met een derde album in de pijplijn en nu al miljoenen Spotify-streams ziet de toekomst er meer dan zonnig uit voor de singer/songwriter uit Brighton.Foto door Davey Pentecos",
    "startTime": "2024-07-07T13:00:00.000Z",
    "endTime": "2024-07-07T13:45:00.000Z",
    "name": "Bess Atwell",
    "socials": {
      "instagram": "https://www.instagram.com/bessatwell",
      "facebook": "https://www.facebook.com/bessatwellmusic",
      "twitter": "https://twitter.com/bessatwell",
      "website": "https://www.bessatwell.com/",
      "spotify": "https://open.spotify.com/artist/6Pim5E5Lm03COsjHj2aEiK?si=tp5l6QPDTjGsacOg5Iq1Gw"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2849_crop_2000-870-258_fit_1400-540-center_85-Bess_credit_Davey_Pentecos_site%282%29.jpg"
  },
  {
    "id": 122,
    "description": "Buitenlandse hiphop die het bij ons goed doet komt vooral uit de Angelsaksische wereld. Rappers uit Latijns-Amerika, België en Frankrijk weten de weg ook wel te vinden, maar van onze oosterburen horen we zelden. Dat ligt zeker niet aan hen: de Duitse hiphopwereld floreert minstens zo uitbundig als de onze. De interessantste nieuwe naam is frytz, een opgewekte twintiger die het midden houdt tussen De Jeugd van Tegenwoordig en Gotu Jim. Elektronische beats, pakkende refreinen en smakelijke teksten vol zelfspot. Das gefällt uns sehr!",
    "startTime": "2024-07-06T15:30:00.000Z",
    "endTime": "2024-07-06T16:15:00.000Z",
    "name": "frytz",
    "socials": {
      "instagram": "https://www.instagram.com/frytzfrytzfrytz/?hl=en",
      "website": "https://www.youtube.com/@frytz_official",
      "spotify": "https://open.spotify.com/artist/4hX2AmJ9iZiXYA2Zx1vtBO?si=4comFpPuRQSM-vJLdDReNQ"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2857_crop_2000-870-170_fit_1400-540-center_85-Presse_frytz_Fred_site%285%29.jpg"
  },
  {
    "id": 123,
    "description": "Hezekiah Procter. Dat lijkt op de naam van een stokoude Amerikaanse cowboy. Met de nadruk op lijkt en oud, want achter deze fictieve figuur uit de jaren 20 van de vorige eeuw huist de al vier jaar op rij tot Beste Country/Folk Act uitgeroepen Montrealse zanger/gitarist Li’l Andy. Laat het dus maar aan deze Canadees over om naadloos in de huid van Hezekiah Procter te kruipen en niet van echt te onderscheiden old-time music over het voetlicht te brengen, compleet met banjo, fiddle en soesafoon.Foto door Francois Ollivier",
    "startTime": "2024-07-07T11:15:00.000Z",
    "endTime": "2024-07-07T12:00:00.000Z",
    "name": "Hezekiah Procter",
    "socials": {
      "instagram": "https://www.instagram.com/thisislilandy/?hl=en",
      "facebook": "https://www.facebook.com/profile.php?id=100063647163454",
      "website": "https://www.lilandy.net/about-hezekiah-procter",
      "spotify": "https://open.spotify.com/artist/4KWCcDYRRuG5S55bzulm1r?si=FQQ2cZbcQYm6Vz7alddMJQ"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2861_crop_620-270-32_fit_1400-540-center_85-Hezekiah_Procter_credit_Francois%20Ollivier%282%29.png"
  },
  {
    "id": 124,
    "description": "Hij heeft niet alleen de dreads van pa, maar erfde ook Glen Faria’s zanggenen. Rappen zoals paps doet Jaïr Faria dan weer niet, maar waarom zou je, met zo’n godenstem? Hoor de prille twintiger toch eens kwinkeleren op de eerder dit jaar uitgebrachte single ‘Why Me’, een hartverscheurende ballad van Tamino-proporties. Of luister naar het rugrillend mooie duet tussen vader en zoon in ‘Terug Naar Nu’, toen junior pas vijftien was. Geen passender podium dus voor hem dan de idyllische Bossa Nova-stage, mét denkbeeldig kampvuurtje.",
    "startTime": "2024-07-06T11:45:00.000Z",
    "endTime": "2024-07-06T12:30:00.000Z",
    "name": "Jaïr Faria",
    "socials": {
      "instagram": "https://www.instagram.com/jairfariamusic/",
      "website": "https://www.youtube.com/channel/UCyQvYnvGTX6xxVFx4F50Yuw",
      "spotify": "https://open.spotify.com/artist/0nzaebKwaLRFXNwKLrXBRe?si=VJhrjhHUT9SJzMDGcp_7Pg"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2865_crop_1600-696-127_fit_1400-540-center_85-Jair-Faria%282%29.JPG"
  },
  {
    "id": 125,
    "description": "La Sonora Mazurén verplaatst het allerleukste feestje van de Colombiaanse bergen naar de heuvels van Beuningen. Dit vrolijke gezelschap vond inspiratie in buurland Brazilië, waar eind jaren 60 onder de naam tropicália een ware muzikale revolutie plaatsvond. Lokale genres werden gekoppeld aan Afrikaanse ritmes en de psychedelische muziek die in het westen de dienst uitmaakte. La Sonora Mazurén voegt daar moderne en traditioneel Colombiaanse stijlen aan toe. De bandleden zijn niet alleen virtuoos in hun spel, maar ook stuk voor stuk cum laude afgestudeerd als levensgenieter.",
    "startTime": "2024-07-07T15:00:00.000Z",
    "endTime": "2024-07-07T15:45:00.000Z",
    "name": "La Sonora Mazurén",
    "socials": {
      "instagram": "https://www.instagram.com/lasonoramazuren/",
      "facebook": "https://www.facebook.com/lasonoramazuren/",
      "twitter": "https://twitter.com/lasonoramazuren?lang=en",
      "website": "https://www.youtube.com/@LaSonoraMazuren",
      "spotify": "https://open.spotify.com/artist/2IsqVzltUoufEu3q1yUmTX?si=UdyMDSsYQkecaK91NOrctQ"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2869_crop_912-396-145_fit_1400-540-center_85-SONORA%20CUADRADA%203_site%282%29.jpeg"
  },
  {
    "id": 126,
    "description": "We see you, POM. Met een track als ‘Down The Rabbit Hole’ speel je je in de Groene Heuvels sowieso in de kijker, helemaal als je ook live hoge ogen gooit. Met hun aanstekelijke fuzzpop sloeg dit vijftal uit Amsterdam een kans in de Popronde 2021 als pro’s uit het park, een homerun die de diep persoonlijke debuutplaat ‘We Were Girls Together’ (2023) nog meer glans gaf. Gouden bergen liggen in het verschiet, want de net verschenen single ‘a great escape’ is een verslavende voorbode van wat POM allemaal nog meer in huis heeft.",
    "startTime": "2024-07-06T13:30:00.000Z",
    "endTime": "2024-07-06T14:15:00.000Z",
    "name": "POM",
    "socials": {
      "instagram": "https://www.instagram.com/pomopinternet/"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_3013_fit_1400-540-center_85-POM_Persfoto_4_MOJO_NL_site.jpg"
  },
  {
    "id": 127,
    "description": "De in Oeganda geboren Victor Ray maakte meters als straatmuzikant in Londen. Met de penny’s en ponden die verblufte voorbijgangers in zijn hoed wierpen, betaalde hij zijn opleiding aan het conservatorium. Ondertussen werd hij een beroemdheid op de socials: de filmpjes die hij van zijn stoepconcerten maakte gingen om de haverklap viraal. Inmiddels combineert hij de noeste arbeid van de straat met de academische kennis van zijn opleiding. Dat resulteert in goudeerlijke, loepzuivere soulliedjes vol pakkende ritmes en heerlijke refreinen.Foto door Harvey Pearson",
    "startTime": "2024-07-05T11:15:00.000Z",
    "endTime": "2024-07-05T12:00:00.000Z",
    "name": "Victor Ray",
    "socials": {
      "instagram": "https://www.instagram.com/victorray",
      "facebook": "https://www.facebook.com/victorray09/",
      "website": "https://victorrayofficial.com/",
      "spotify": "https://open.spotify.com/artist/3vABvmPywI2fV90bnpkpsJ?si=nFhcwaQNT6WNj6TQIFaXSA"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2877_crop_2000-870-275_fit_1400-540-center_85-VICTOR_RAY_HARVEY_PEARSON-11_site%282%29.jpg"
  },
  {
    "id": 128,
    "description": "De naam verraadt het al een beetje: Cello Octet Amsterdam is een groep van acht cellisten uit onze hoofdstad. Maar daarmee weet je nog niet de helft. Deze topmusici zoeken de grenzen van hun instrument op en walsen daar vervolgens keihard eroverheen. Al 35 jaar brengen ze alles wat jij van een cello dacht te weten aan het wankelen. Arvo Pärt en Yo-Yo Ma zijn fan. Dan hebben we het over de grootste componist en cellist van onze tijd. Ze hebben uiteraard gelijk.Foto door Anisa Xomaqi",
    "startTime": "2024-07-07T08:30:00.000Z",
    "endTime": "2024-07-07T09:30:00.000Z",
    "name": "Cello Octet Amsterdam",
    "socials": {
      "instagram": "https://www.instagram.com/cellooctetamsterdam/",
      "facebook": "https://www.facebook.com/cellooctet/",
      "website": "https://cellooctet.com/",
      "spotify": "https://open.spotify.com/artist/114TP69VjPUQpzdkNXEMrS?si=_o-hksszRaeab909Gi-drQ"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2881_crop_2000-870-314_fit_1400-540-center_85-Cello%20Octet%20Amsterdam%20Anisa%20Xomaqi%20fotograaf%20I_site%282%29.jpg"
  },
  {
    "id": 129,
    "description": "Kamermuziek stijfjes en formeel? Niet bij het Dianto Reed Quintet, vijf jonge Spaanse rietblazers (onder wie liefst drie María’s) die het misschien wat stoffige imago van het genre frivool en vakkundig beentje lichten. Gewapend met sax, fagot, hobo en twee klarinetten, vrijelijk stukken dooreenhusselend en opgeleukt met een speelse choreografie, pompt het prijswinnende ensemble power en dynamiek in zijn repertoire, geplukt uit alle perken van de Spaanse (klassieke) muziekhistorie. En niet van blad, maar uit het blote hoofd. Ook dat is weer ’s wat anders.Foto door Jessie Kamp",
    "startTime": "2024-07-06T09:45:00.000Z",
    "endTime": "2024-07-06T10:30:00.000Z",
    "name": "Dianto Reed Quintet",
    "socials": {
      "instagram": "https://www.instagram.com/diantoreedquintet/?hl=es",
      "facebook": "https://www.facebook.com/diantoreedquintet",
      "website": "https://diantoreedquintet.com/"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2885_crop_2000-869-132_fit_1400-540-center_85-Dianto%20Reed%20Quintet_fotograaf%20JessieKamp%20I_site%282%29.jpg"
  },
  {
    "id": 130,
    "description": "Oude muziek in een nieuw jasje. En nieuwe muziek in een fantasieoutfit. Dat is wat het vaderlandse Ensemble Black Pencil uit de hoge hoed tovert. En met oude muziek bedoelen we dan héél oude muziek, zoals die eeuwen geleden gecomponeerd werd. Gegarandeerd ouder dan je ouders. En die jasjes wisselen van pastoraal tot avant-gardistisch. En dat met een instrumentatie die je zelden bij elkaar ziet; van blok- en panfluit tot altviool, accordeon en percussie. Kortom, een veelkleurige ervaring die met geen potlood te beschrijven is.Foto door Joe Finch",
    "startTime": "2024-07-05T09:45:00.000Z",
    "endTime": "2024-07-05T10:30:00.000Z",
    "name": "Ensemble Black Pencil",
    "socials": {
      "instagram": "https://www.instagram.com/ensembleblackpencil/",
      "facebook": "https://www.facebook.com/ensembleblackpencil/",
      "twitter": "https://twitter.com/EnsembleBP",
      "website": "https://www.blackpencil.org/",
      "spotify": "https://open.spotify.com/artist/5gQjTM7hKus0UUAKEQSXIk?si=8BCHS9giTs28sxA1nF6FJw"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2889_crop_1452-631-322_fit_1400-540-center_85-Ensemble%20Black%20Pencil%20-%20foto%20Joe%20Finch%20Photography%282%29.jpg"
  },
  {
    "id": 131,
    "description": "Opwinding in de klassieke wereld: twee van onze grootste talenten slaan de handen ineen. Voor de pop-minded people: dit is alsof Froukje en Joost Klein hun krachten bundelen. Harpist Michela Amici is vingervlugger dan Hans Kazàn. Haar oeuvre beslaat zo ongeveer alles: van de middeleeuwen en de barok tot moderne composities die speciaal voor haar geschreven zijn – ja, die status heeft ze. Sopraan Isabel Pronk heeft een van de meest intense stemmen die je ooit gehoord hebt. Vlecht die twee door elkaar en je krijgt muziek van een tijdloze pracht.",
    "startTime": "2024-07-05T08:30:00.000Z",
    "endTime": "2024-07-05T09:00:00.000Z",
    "name": "Michela Amici + Isabel Pronk",
    "socials": {
      "instagram": "https://www.instagram.com/pronkisabel/",
      "facebook": "https://www.facebook.com/MichelaAmiciHarpist/",
      "spotify": "https://open.spotify.com/artist/7xujZNHV2bjG1FG111wdJH?si=65C4GUVVTR2k8LNAMlh1PQ",
      "website": "https://www.michela-amici.com/"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2893_crop_1479-643_fit_1400-540-center_85-Michela%20Amici%20%26%20Isabel%20Pronk_site%282%29.jpg"
  },
  {
    "id": 132,
    "description": "De wieg van het Nymphéas Trombone Quartet staat in Amsterdam, waar de vier jeugdige conservatorium-alumni elkaar ontmoetten, maar hun ensemble vernoemden ze naar de Nymphéas-schilderijenreeks van Claude Monet. Niet toevallig dus dat hun muzikale focus ook op het Franse impressionistische repertoire ligt, naast hedendaags Nederlands werk. Momenteel toert het viertal met ‘Matricale’, een ode aan de madrigaal, een meerstemmig zangstuk uit de renaissance dat daarmee een trombone-makeover krijgt. Word-painting, noemen de mannen het zelf, extra verbeeld door een vj. Snoep voor oren en ogen dus.Foto door Foppe Schut",
    "startTime": "2024-07-06T08:30:00.000Z",
    "endTime": "2024-07-06T09:00:00.000Z",
    "name": "Nymphéas Trombone Quartet",
    "socials": {
      "instagram": "https://www.instagram.com/nympheastrombonequartet",
      "facebook": "https://www.facebook.com/nympheastrombonequartet",
      "website": "https://nympheastrombonequartet.nl/",
      "spotify": "https://open.spotify.com/artist/3Tb8FWtuzqnJgkbWqrITqB?si=QV4JtGPlQ_y19aInYFB6vQ"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2897_crop_1724-749_fit_1400-540-center_85-Nymphe%CC%81as%20Trombone%20Quartet%20%20fotograaf%20Foppe%20Schut_site%282%29.jpg"
  },
  {
    "id": 133,
    "description": "Voor het derde jaar op rij trakteren Orkater en geestverwanten jullie elke nacht op een marathonvoorstelling waar dans, performance, goochelkunst, menselijke piramides en sing-alongs onnavolgbaar, maar naadloos op elkaar aansluiten. In een lofzang op kunst en nachtcultuur presenteren ze ‘A Divine Comedy’, een programma waar de crème de la crème van het Nederlandse podiumtalent de krachten bundelt. Van tevoren weet je niet wat je geserveerd krijgt, maar reken maar dat het raakt, vervoert en verwart – en dat je er als herboren uitkomt. Van en met: 155 (Eenvijfvijf), DOX, De Dansers, Jelle en Jessica, KONVOOI, Maask, Magic Tom & Yuri, Post-theatre Posse Grande Loge, Simme Wouters & Jasper Stoop en Stephanie Louwrier.En Orkater, al vijftig jaar hét muziektheatergezelschap van Nederland, waar verhalen worden verteld die nu belangrijk zijn en waar muziek evenveel waarde heeft als taal. Orkaterproducties zijn direct, gevoelig, gewaagd en gul.Foto door Anne Claire de Breij",
    "startTime": "2024-07-05T17:30:00.000Z",
    "endTime": "2024-07-06T00:30:00.000Z",
    "name": "Orkater & Invites: A Divine Comedy",
    "socials": {
      "instagram": "https://www.instagram.com/orkater/",
      "facebook": "https://www.facebook.com/Orkater",
      "website": "https://orkater.nl/",
      "spotify": "https://open.spotify.com/show/24EmEguFZLW6zIJiOdsL9c?si=34eb7aba7c764a6f"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2901_crop_2000-870-417_fit_1400-540-center_85-2024%20DTRH%20-%20liggend%20-%20Anne%20Claire%20de%20Breij_site%282%29.jpg"
  },
  {
    "id": 134,
    "description": "Voor het derde jaar op rij trakteren Orkater en geestverwanten jullie elke nacht op een marathonvoorstelling waar dans, performance, goochelkunst, menselijke piramides en sing-alongs onnavolgbaar, maar naadloos op elkaar aansluiten. In een lofzang op kunst en nachtcultuur presenteren ze ‘A Divine Comedy’, een programma waar de crème de la crème van het Nederlandse podiumtalent de krachten bundelt. Van tevoren weet je niet wat je geserveerd krijgt, maar reken maar dat het raakt, vervoert en verwart – en dat je er als herboren uitkomt. Van en met: 155 (Eenvijfvijf), DOX, De Dansers, Jelle en Jessica, KONVOOI, Maask, Magic Tom & Yuri, Post-theatre Posse Grande Loge, Simme Wouters & Jasper Stoop en Stephanie Louwrier.En Orkater, al vijftig jaar hét muziektheatergezelschap van Nederland, waar verhalen worden verteld die nu belangrijk zijn en waar muziek evenveel waarde heeft als taal. Orkaterproducties zijn direct, gevoelig, gewaagd en gul.Foto door Anne Claire de Breij",
    "startTime": "2024-07-06T17:30:00.000Z",
    "endTime": "2024-07-07T00:30:00.000Z",
    "name": "Orkater & Invites: A Divine Comedy",
    "socials": {
      "instagram": "https://www.instagram.com/orkater/",
      "facebook": "https://www.facebook.com/Orkater",
      "website": "https://orkater.nl/",
      "spotify": "https://open.spotify.com/show/24EmEguFZLW6zIJiOdsL9c?si=34eb7aba7c764a6f"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2901_crop_2000-870-417_fit_1400-540-center_85-2024%20DTRH%20-%20liggend%20-%20Anne%20Claire%20de%20Breij_site%282%29.jpg"
  },
  {
    "id": 135,
    "description": "Voor het derde jaar op rij trakteren Orkater en geestverwanten jullie elke nacht op een marathonvoorstelling waar dans, performance, goochelkunst, menselijke piramides en sing-alongs onnavolgbaar, maar naadloos op elkaar aansluiten. In een lofzang op kunst en nachtcultuur presenteren ze ‘A Divine Comedy’, een programma waar de crème de la crème van het Nederlandse podiumtalent de krachten bundelt. Van tevoren weet je niet wat je geserveerd krijgt, maar reken maar dat het raakt, vervoert en verwart – en dat je er als herboren uitkomt. Van en met: 155 (Eenvijfvijf), DOX, De Dansers, Jelle en Jessica, KONVOOI, Maask, Magic Tom & Yuri, Post-theatre Posse Grande Loge, Simme Wouters & Jasper Stoop en Stephanie Louwrier.En Orkater, al vijftig jaar hét muziektheatergezelschap van Nederland, waar verhalen worden verteld die nu belangrijk zijn en waar muziek evenveel waarde heeft als taal. Orkaterproducties zijn direct, gevoelig, gewaagd en gul.Foto door Anne Claire de Breij",
    "startTime": "2024-07-07T17:30:00.000Z",
    "endTime": "2024-07-08T00:30:00.000Z",
    "name": "Orkater & Invites: A Divine Comedy",
    "socials": {
      "instagram": "https://www.instagram.com/orkater/",
      "facebook": "https://www.facebook.com/Orkater",
      "website": "https://orkater.nl/",
      "spotify": "https://open.spotify.com/show/24EmEguFZLW6zIJiOdsL9c?si=34eb7aba7c764a6f"
    },
    "stage": "bossa nova",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2901_crop_2000-870-417_fit_1400-540-center_85-2024%20DTRH%20-%20liggend%20-%20Anne%20Claire%20de%20Breij_site%282%29.jpg"
  },
  {
    "id": 136,
    "description": "Het kloppende hart van the CROQUE Madame is de dagelijkse MAISON the FAUX the Croque Madame Revue. Vergeet wat je weet over de klassieke revue, want dit mindblowing showspektakel van drie uur zet alle traditionele genres en disciplines volledig op hun kop. Van opera en pole tot spoken word, drag, harde beats en de wereldberoemde cancan; alle vormen van podiumkunst spatten hier van de planken. Leve de cultuur, leve de schoonheid, leve de Madames!Met Arno Verbruggen, Dragcouenne, Anto López Espinosa, Michelle Tshibola, Sarahsson, J BOY & Oriana Mangala Ikomo en the CROQUE Madame Space Angels.Dagelijks te zien in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders?",
    "startTime": "2024-07-05T17:30:00.000Z",
    "endTime": "2024-07-05T20:30:00.000Z",
    "name": "the CROQUE Madame REVUE by MAISON the FAUX",
    "socials": {
      "instagram": "https://www.instagram.com/maisonthefaux/",
      "facebook": "https://www.facebook.com/maisonthefauxfashion",
      "website": "https://maisonthefaux.com/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2949_fit_1400-540-center_85-Still%201%20liggend%20%28voor%20tekst%29%281%29.jpg"
  },
  {
    "id": 137,
    "description": "Het kloppende hart van the CROQUE Madame is de dagelijkse MAISON the FAUX the Croque Madame Revue. Vergeet wat je weet over de klassieke revue, want dit mindblowing showspektakel van drie uur zet alle traditionele genres en disciplines volledig op hun kop. Van opera en pole tot spoken word, drag, harde beats en de wereldberoemde cancan; alle vormen van podiumkunst spatten hier van de planken. Leve de cultuur, leve de schoonheid, leve de Madames!Met Arno Verbruggen, Dragcouenne, Anto López Espinosa, Michelle Tshibola, Sarahsson, J BOY & Oriana Mangala Ikomo en the CROQUE Madame Space Angels.Dagelijks te zien in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders?",
    "startTime": "2024-07-06T17:30:00.000Z",
    "endTime": "2024-07-06T20:30:00.000Z",
    "name": "the CROQUE Madame REVUE by MAISON the FAUX",
    "socials": {
      "instagram": "https://www.instagram.com/maisonthefaux/",
      "facebook": "https://www.facebook.com/maisonthefauxfashion",
      "website": "https://maisonthefaux.com/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2949_fit_1400-540-center_85-Still%201%20liggend%20%28voor%20tekst%29%281%29.jpg"
  },
  {
    "id": 138,
    "description": "Het kloppende hart van the CROQUE Madame is de dagelijkse MAISON the FAUX the Croque Madame Revue. Vergeet wat je weet over de klassieke revue, want dit mindblowing showspektakel van drie uur zet alle traditionele genres en disciplines volledig op hun kop. Van opera en pole tot spoken word, drag, harde beats en de wereldberoemde cancan; alle vormen van podiumkunst spatten hier van de planken. Leve de cultuur, leve de schoonheid, leve de Madames!Met Arno Verbruggen, Dragcouenne, Anto López Espinosa, Michelle Tshibola, Sarahsson, J BOY & Oriana Mangala Ikomo en the CROQUE Madame Space Angels.Dagelijks te zien in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders?",
    "startTime": "2024-07-07T17:30:00.000Z",
    "endTime": "2024-07-07T20:30:00.000Z",
    "name": "the CROQUE Madame REVUE by MAISON the FAUX",
    "socials": {
      "instagram": "https://www.instagram.com/maisonthefaux/",
      "facebook": "https://www.facebook.com/maisonthefauxfashion",
      "website": "https://maisonthefaux.com/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2949_fit_1400-540-center_85-Still%201%20liggend%20%28voor%20tekst%29%281%29.jpg"
  },
  {
    "id": 139,
    "description": "Wordt het optreden van COBRAH in the CROQUE Madame de show van het weekend? Smaken verschillen, maar dikke kans dat deze spectaculaire hoogmis voor open seksualiteit en kink precies je lekkerste plekjes raakt. Nadat de Zweedse singer/producer een paar jaar geleden haar eerste latex catsuit aantrok en op fetishparty’s begon op te treden, is het hard gegaan met COBRAH. Op de vleugels van geruchtschoppende video’s en clubbangers als ‘GOOD PUSS’ vinden haar engelenstem en experimentele beats inmiddels gretig aftrek bij grote namen als Thierry Mugler, Ariana Grande en Charli XCX. Foto door Ninja Hanna",
    "startTime": "2024-07-06T21:15:00.000Z",
    "endTime": "2024-07-06T22:00:00.000Z",
    "name": "COBRAH",
    "socials": {
      "instagram": "https://www.instagram.com/iamcobrah/?hl=en",
      "facebook": "https://www.facebook.com/IAMCOBRAH/",
      "twitter": "https://twitter.com/_iamcobrah_",
      "website": "https://www.cobrahcore.com/",
      "spotify": "https://open.spotify.com/artist/1AHswQqsDNmu1xaE8KpBne?si=-H0PKvprRXCdWQh-E2o4qw"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2950_fit_1400-540-center_85-001_credit_NinjaHanna_COBRAH_0535C_site.jpg"
  },
  {
    "id": 140,
    "description": "Van warming-up tot het dak in lichterlaaie; deze dj, producer en co-founder van het transtalentplatform Los Angles Collective houdt de extravaganza in the CROQUE Madame het hele weekend gaande. YoungWoman volgt de mood van het moment op de voet en schakelt moeiteloos van spicy mellow naar explosieve ass-shaking beats. Drie dagen achter elkaar draaien is alleen voor de echte profs weggelegd, dus ben je bij deze in-house booth-madame in de beste handen. Wie pure klasse zaait, zal liefde oogsten.",
    "startTime": "2024-07-05T16:30:00.000Z",
    "endTime": "2024-07-05T17:30:00.000Z",
    "name": "YoungWoman",
    "socials": {
      "instagram": "https://www.instagram.com/youngw0man",
      "soundcloud": "https://soundcloud.com/youngwoman",
      "website": "https://youngwoman.xyz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2952_fit_1400-540-center_85-yw%201.jpg"
  },
  {
    "id": 141,
    "description": "Van warming-up tot het dak in lichterlaaie; deze dj, producer en co-founder van het transtalentplatform Los Angles Collective houdt de extravaganza in the CROQUE Madame het hele weekend gaande. YoungWoman volgt de mood van het moment op de voet en schakelt moeiteloos van spicy mellow naar explosieve ass-shaking beats. Drie dagen achter elkaar draaien is alleen voor de echte profs weggelegd, dus ben je bij deze in-house booth-madame in de beste handen. Wie pure klasse zaait, zal liefde oogsten.",
    "startTime": "2024-07-05T20:30:00.000Z",
    "endTime": "2024-07-05T21:30:00.000Z",
    "name": "YoungWoman",
    "socials": {
      "instagram": "https://www.instagram.com/youngw0man",
      "soundcloud": "https://soundcloud.com/youngwoman",
      "website": "https://youngwoman.xyz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2952_fit_1400-540-center_85-yw%201.jpg"
  },
  {
    "id": 142,
    "description": "Van warming-up tot het dak in lichterlaaie; deze dj, producer en co-founder van het transtalentplatform Los Angles Collective houdt de extravaganza in the CROQUE Madame het hele weekend gaande. YoungWoman volgt de mood van het moment op de voet en schakelt moeiteloos van spicy mellow naar explosieve ass-shaking beats. Drie dagen achter elkaar draaien is alleen voor de echte profs weggelegd, dus ben je bij deze in-house booth-madame in de beste handen. Wie pure klasse zaait, zal liefde oogsten.",
    "startTime": "2024-07-06T16:30:00.000Z",
    "endTime": "2024-07-06T17:30:00.000Z",
    "name": "YoungWoman",
    "socials": {
      "instagram": "https://www.instagram.com/youngw0man",
      "soundcloud": "https://soundcloud.com/youngwoman",
      "website": "https://youngwoman.xyz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2952_fit_1400-540-center_85-yw%201.jpg"
  },
  {
    "id": 143,
    "description": "Van warming-up tot het dak in lichterlaaie; deze dj, producer en co-founder van het transtalentplatform Los Angles Collective houdt de extravaganza in the CROQUE Madame het hele weekend gaande. YoungWoman volgt de mood van het moment op de voet en schakelt moeiteloos van spicy mellow naar explosieve ass-shaking beats. Drie dagen achter elkaar draaien is alleen voor de echte profs weggelegd, dus ben je bij deze in-house booth-madame in de beste handen. Wie pure klasse zaait, zal liefde oogsten.",
    "startTime": "2024-07-06T20:30:00.000Z",
    "endTime": "2024-07-06T21:15:00.000Z",
    "name": "YoungWoman",
    "socials": {
      "instagram": "https://www.instagram.com/youngw0man",
      "soundcloud": "https://soundcloud.com/youngwoman",
      "website": "https://youngwoman.xyz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2952_fit_1400-540-center_85-yw%201.jpg"
  },
  {
    "id": 144,
    "description": "Van warming-up tot het dak in lichterlaaie; deze dj, producer en co-founder van het transtalentplatform Los Angles Collective houdt de extravaganza in the CROQUE Madame het hele weekend gaande. YoungWoman volgt de mood van het moment op de voet en schakelt moeiteloos van spicy mellow naar explosieve ass-shaking beats. Drie dagen achter elkaar draaien is alleen voor de echte profs weggelegd, dus ben je bij deze in-house booth-madame in de beste handen. Wie pure klasse zaait, zal liefde oogsten.",
    "startTime": "2024-07-07T16:30:00.000Z",
    "endTime": "2024-07-07T17:30:00.000Z",
    "name": "YoungWoman",
    "socials": {
      "instagram": "https://www.instagram.com/youngw0man",
      "soundcloud": "https://soundcloud.com/youngwoman",
      "website": "https://youngwoman.xyz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2952_fit_1400-540-center_85-yw%201.jpg"
  },
  {
    "id": 145,
    "description": "Van warming-up tot het dak in lichterlaaie; deze dj, producer en co-founder van het transtalentplatform Los Angles Collective houdt de extravaganza in the CROQUE Madame het hele weekend gaande. YoungWoman volgt de mood van het moment op de voet en schakelt moeiteloos van spicy mellow naar explosieve ass-shaking beats. Drie dagen achter elkaar draaien is alleen voor de echte profs weggelegd, dus ben je bij deze in-house booth-madame in de beste handen. Wie pure klasse zaait, zal liefde oogsten.",
    "startTime": "2024-07-07T20:30:00.000Z",
    "endTime": "2024-07-07T21:00:00.000Z",
    "name": "YoungWoman",
    "socials": {
      "instagram": "https://www.instagram.com/youngw0man",
      "soundcloud": "https://soundcloud.com/youngwoman",
      "website": "https://youngwoman.xyz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2952_fit_1400-540-center_85-yw%201.jpg"
  },
  {
    "id": 146,
    "description": "Van warming-up tot het dak in lichterlaaie; deze dj, producer en co-founder van het transtalentplatform Los Angles Collective houdt de extravaganza in the CROQUE Madame het hele weekend gaande. YoungWoman volgt de mood van het moment op de voet en schakelt moeiteloos van spicy mellow naar explosieve ass-shaking beats. Drie dagen achter elkaar draaien is alleen voor de echte profs weggelegd, dus ben je bij deze in-house booth-madame in de beste handen. Wie pure klasse zaait, zal liefde oogsten.",
    "startTime": "2024-07-06T22:00:00.000Z",
    "endTime": "2024-07-06T23:00:00.000Z",
    "name": "YoungWoman",
    "socials": {
      "instagram": "https://www.instagram.com/youngw0man",
      "soundcloud": "https://soundcloud.com/youngwoman",
      "website": "https://youngwoman.xyz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2952_fit_1400-540-center_85-yw%201.jpg"
  },
  {
    "id": 147,
    "description": "Dit jaar kan niet stuk voor TWIENA. Ze scoorde een spot bij Boiler Room, gooit hoge ogen als Club Raum-resident en staat hoog op de bill van zowat elk festival in de Lage Landen. Ook op Down The Rabbit Hole, waar ze in het nieuwe revuetheater the CROQUE Madame een terechte ereplek krijgt. TWIENA’s trademark trancy rave- en technogrooves barsten van de energie, stijl en liefde, een instant-vibe die je in je hele lijf voelt. Geef je over en ontdek waarom elk hart sneller gaat kloppen van deze Amsterdamse clubvedette.",
    "startTime": "2024-07-05T21:30:00.000Z",
    "endTime": "2024-07-05T23:00:00.000Z",
    "name": "TWIENA",
    "socials": {
      "instagram": "https://www.instagram.com/twiena/",
      "soundcloud": "https://soundcloud.com/twiena"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2954_fit_1400-540-center_85-TWIENA_Presspic_2023_2_Site.jpg"
  },
  {
    "id": 148,
    "description": "Dj en schrijver Joost van Bellen presenteert in the CROQUE Madame zijn nieuwe boek ‘Fenix’, een semi-autobiografische roman over zijn tijd in en rond de legendarische Club RoXY. Verplichte leeskost, want de RoXY was tot 1999 niet alleen de bakermat van house, maar vooral een weergaloos gesamtkunstwerk vol muziek, performances, decors, mode en beeldende kunst. Fenix: Van het Vuur, het Vuur is een ongrijpbaar literair programma en nog veel meer dan dat: laat je meezuigen in een wervelende, magisch-realistische trip en tijdreis vol beeld en geluid, performances en spoken word. Met medewerking van o.a. VJ 00-Kaap, Andrea van Pol, Denzel Goudmijn, JPech, Lisa Chudalla, Maaike Strengholt, Stephanie Louwrier en input vanuit Cleo Camperts ‘Het RoXY Archief’, schilder Frans Franciscus, Leigh Bowery, MC Kinky’s E-Zee Posse en meer.Foto door Dennis Bouman",
    "startTime": "2024-07-06T09:30:00.000Z",
    "endTime": "2024-07-06T10:30:00.000Z",
    "name": "Fenix: Van het Vuur, het Vuur",
    "socials": {
      "instagram": "https://www.instagram.com/joostvanbellen/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2955_fit_1400-540-center_85-20240519%20Feniks%200151-NEF_%C2%A9DennisBouman%20kopie_site.jpg"
  },
  {
    "id": 149,
    "description": "Dj en schrijver Joost van Bellen presenteert in the CROQUE Madame zijn nieuwe boek ‘Fenix’, een semi-autobiografische roman over zijn tijd in en rond de legendarische Club RoXY. Verplichte leeskost, want de RoXY was tot 1999 niet alleen de bakermat van house, maar vooral een weergaloos gesamtkunstwerk vol muziek, performances, decors, mode en beeldende kunst. Fenix: Van het Vuur, het Vuur is een ongrijpbaar literair programma en nog veel meer dan dat: laat je meezuigen in een wervelende, magisch-realistische trip en tijdreis vol beeld en geluid, performances en spoken word. Met medewerking van o.a. VJ 00-Kaap, Andrea van Pol, Denzel Goudmijn, JPech, Lisa Chudalla, Maaike Strengholt, Stephanie Louwrier en input vanuit Cleo Camperts ‘Het RoXY Archief’, schilder Frans Franciscus, Leigh Bowery, MC Kinky’s E-Zee Posse en meer.Foto door Dennis Bouman",
    "startTime": "2024-07-07T09:30:00.000Z",
    "endTime": "2024-07-07T10:30:00.000Z",
    "name": "Fenix: Van het Vuur, het Vuur",
    "socials": {
      "instagram": "https://www.instagram.com/joostvanbellen/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2955_fit_1400-540-center_85-20240519%20Feniks%200151-NEF_%C2%A9DennisBouman%20kopie_site.jpg"
  },
  {
    "id": 150,
    "description": "Zin in een van de meest absurde, waanzinnige feestjes van het land? The House of Hopelezz is al meer dan twintig jaar een hoeksteen in de nationale dragscene, en dat zegt alles over het vuur dat dit collectief komt brengen. Glorieus aangevoerd door huismoeder Jennifer Hopelezz transporteert deze bonte familie dragqueens de heerlijke edgy ambiance van de Amsterdamse scene in workshops en lezingen onversneden naar Down The Rabbit Hole. Weird en wacky, maar ook open en liefdevol – The House of Hopelezz is het allemaal.Dagelijks te zien in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders? Foto door Stacey Yates",
    "startTime": "2024-07-05T10:30:00.000Z",
    "endTime": "2024-07-05T12:30:00.000Z",
    "name": "The House of Hopelezz",
    "socials": {
      "instagram": "https://www.instagram.com/thehouseofhopelezz/",
      "facebook": "https://www.facebook.com/TheHouseOfHopelezz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2956_fit_1400-540-center_85-2.Family%20portrait%20with%20members%20of%20the%20House%20of%20Hopelezz%20and%20the%20House%20of%20L%C3%B8stbois%2C%20photo%20by%20Stacey%20Yates%2C%20art%20direction%20Taka%20Taka%2C%202019_site.jpg"
  },
  {
    "id": 151,
    "description": "Zin in een van de meest absurde, waanzinnige feestjes van het land? The House of Hopelezz is al meer dan twintig jaar een hoeksteen in de nationale dragscene, en dat zegt alles over het vuur dat dit collectief komt brengen. Glorieus aangevoerd door huismoeder Jennifer Hopelezz transporteert deze bonte familie dragqueens de heerlijke edgy ambiance van de Amsterdamse scene in workshops en lezingen onversneden naar Down The Rabbit Hole. Weird en wacky, maar ook open en liefdevol – The House of Hopelezz is het allemaal.Dagelijks te zien in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders? Foto door Stacey Yates",
    "startTime": "2024-07-06T11:00:00.000Z",
    "endTime": "2024-07-06T13:00:00.000Z",
    "name": "The House of Hopelezz",
    "socials": {
      "instagram": "https://www.instagram.com/thehouseofhopelezz/",
      "facebook": "https://www.facebook.com/TheHouseOfHopelezz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2956_fit_1400-540-center_85-2.Family%20portrait%20with%20members%20of%20the%20House%20of%20Hopelezz%20and%20the%20House%20of%20L%C3%B8stbois%2C%20photo%20by%20Stacey%20Yates%2C%20art%20direction%20Taka%20Taka%2C%202019_site.jpg"
  },
  {
    "id": 152,
    "description": "Zin in een van de meest absurde, waanzinnige feestjes van het land? The House of Hopelezz is al meer dan twintig jaar een hoeksteen in de nationale dragscene, en dat zegt alles over het vuur dat dit collectief komt brengen. Glorieus aangevoerd door huismoeder Jennifer Hopelezz transporteert deze bonte familie dragqueens de heerlijke edgy ambiance van de Amsterdamse scene in workshops en lezingen onversneden naar Down The Rabbit Hole. Weird en wacky, maar ook open en liefdevol – The House of Hopelezz is het allemaal.Dagelijks te zien in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders? Foto door Stacey Yates",
    "startTime": "2024-07-07T11:00:00.000Z",
    "endTime": "2024-07-07T13:00:00.000Z",
    "name": "The House of Hopelezz",
    "socials": {
      "instagram": "https://www.instagram.com/thehouseofhopelezz/",
      "facebook": "https://www.facebook.com/TheHouseOfHopelezz/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2956_fit_1400-540-center_85-2.Family%20portrait%20with%20members%20of%20the%20House%20of%20Hopelezz%20and%20the%20House%20of%20L%C3%B8stbois%2C%20photo%20by%20Stacey%20Yates%2C%20art%20direction%20Taka%20Taka%2C%202019_site.jpg"
  },
  {
    "id": 153,
    "description": "Welkom in de stripclub van morgen: high-tech, interactief en volledig transparant in consent en lichamelijke autonomie. Sexy as hell ook, want Striptopia verleidt je met kippenvel in alle smaken. De meest sensuele dragqueens, strippers, voguedansers en paaldansartiesten wringen zich tijdens een retestrakke show van twee uur wulps en wild in alle denkbare bochten – en gegarandeerd een paar ondenkbare – om jou hoogtepunt na hoogtepunt te bezorgen. Dagelijks te zien in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders? Foto door Koen van der Kam",
    "startTime": "2024-07-05T13:00:00.000Z",
    "endTime": "2024-07-05T15:00:00.000Z",
    "name": "Striptopia",
    "socials": {
      "instagram": "https://www.instagram.com/striptopia/",
      "website": "https://striptopia.com/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2957_fit_1400-540-center_85-Photographer%20Koen%20van%20der%20Kam.jpg"
  },
  {
    "id": 154,
    "description": "Welkom in de stripclub van morgen: high-tech, interactief en volledig transparant in consent en lichamelijke autonomie. Sexy as hell ook, want Striptopia verleidt je met kippenvel in alle smaken. De meest sensuele dragqueens, strippers, voguedansers en paaldansartiesten wringen zich tijdens een retestrakke show van twee uur wulps en wild in alle denkbare bochten – en gegarandeerd een paar ondenkbare – om jou hoogtepunt na hoogtepunt te bezorgen. Dagelijks te zien in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders? Foto door Koen van der Kam",
    "startTime": "2024-07-06T13:30:00.000Z",
    "endTime": "2024-07-06T15:30:00.000Z",
    "name": "Striptopia",
    "socials": {
      "instagram": "https://www.instagram.com/striptopia/",
      "website": "https://striptopia.com/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2957_fit_1400-540-center_85-Photographer%20Koen%20van%20der%20Kam.jpg"
  },
  {
    "id": 155,
    "description": "Welkom in de stripclub van morgen: high-tech, interactief en volledig transparant in consent en lichamelijke autonomie. Sexy as hell ook, want Striptopia verleidt je met kippenvel in alle smaken. De meest sensuele dragqueens, strippers, voguedansers en paaldansartiesten wringen zich tijdens een retestrakke show van twee uur wulps en wild in alle denkbare bochten – en gegarandeerd een paar ondenkbare – om jou hoogtepunt na hoogtepunt te bezorgen. Dagelijks te zien in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders? Foto door Koen van der Kam",
    "startTime": "2024-07-07T13:30:00.000Z",
    "endTime": "2024-07-07T15:30:00.000Z",
    "name": "Striptopia",
    "socials": {
      "instagram": "https://www.instagram.com/striptopia/",
      "website": "https://striptopia.com/"
    },
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2957_fit_1400-540-center_85-Photographer%20Koen%20van%20der%20Kam.jpg"
  },
  {
    "id": 156,
    "description": "Gatorland is back! En hoe: deze superselectors serveren je in the CROQUE Madame weer een dansnacht die je zelden in deze vorm tegenkomt. Zet je mobiele hitverklikker op scherp, want DJ Trish Trash, DJ Marc Grauzone en DJ Clara Croft bezorgen je playlists voor dagen. Ontdek garage, punk en rock-‘n-roll van de bovenste undergroundplank, ver weg van de mainstream drijvend op een diepe groove van lasergestuurde synths, wave, eighties, electro en weirde jazz en tropicalia. De camping kan wachten.Met performances van Chelseaboy, Nova Star, Angel Spiders, Siren, Aeesha, VARLOC en Denis Bruno, kunst van Daphne Karstens Wearable Sculptures en met VJ Mysterious Creator.Elke nacht in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders?",
    "startTime": "2024-07-05T23:00:00.000Z",
    "endTime": "2024-07-06T02:00:00.000Z",
    "name": "Gatorland",
    "socials": {},
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2958_fit_1400-540-center_85-gatorland_ori1.jpg"
  },
  {
    "id": 157,
    "description": "Gatorland is back! En hoe: deze superselectors serveren je in the CROQUE Madame weer een dansnacht die je zelden in deze vorm tegenkomt. Zet je mobiele hitverklikker op scherp, want DJ Trish Trash, DJ Marc Grauzone en DJ Clara Croft bezorgen je playlists voor dagen. Ontdek garage, punk en rock-‘n-roll van de bovenste undergroundplank, ver weg van de mainstream drijvend op een diepe groove van lasergestuurde synths, wave, eighties, electro en weirde jazz en tropicalia. De camping kan wachten.Met performances van Chelseaboy, Nova Star, Angel Spiders, Siren, Aeesha, VARLOC en Denis Bruno, kunst van Daphne Karstens Wearable Sculptures en met VJ Mysterious Creator.Elke nacht in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders?",
    "startTime": "2024-07-06T23:00:00.000Z",
    "endTime": "2024-07-07T02:00:00.000Z",
    "name": "Gatorland",
    "socials": {},
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2958_fit_1400-540-center_85-gatorland_ori1.jpg"
  },
  {
    "id": 158,
    "description": "Gatorland is back! En hoe: deze superselectors serveren je in the CROQUE Madame weer een dansnacht die je zelden in deze vorm tegenkomt. Zet je mobiele hitverklikker op scherp, want DJ Trish Trash, DJ Marc Grauzone en DJ Clara Croft bezorgen je playlists voor dagen. Ontdek garage, punk en rock-‘n-roll van de bovenste undergroundplank, ver weg van de mainstream drijvend op een diepe groove van lasergestuurde synths, wave, eighties, electro en weirde jazz en tropicalia. De camping kan wachten.Met performances van Chelseaboy, Nova Star, Angel Spiders, Siren, Aeesha, VARLOC en Denis Bruno, kunst van Daphne Karstens Wearable Sculptures en met VJ Mysterious Creator.Elke nacht in de gloednieuwe revueclub the CROQUE Madame tussen het Idyllische Veldje en The Bizarre – waar anders?",
    "startTime": "2024-07-07T21:00:00.000Z",
    "endTime": "2024-07-08T02:00:00.000Z",
    "name": "Gatorland",
    "socials": {},
    "stage": "the croque madame",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2958_fit_1400-540-center_85-gatorland_ori1.jpg"
  },
  {
    "id": 159,
    "description": "Met roots in Venezuela en Portugal heb je danskoorts in je bloed. Alex Figueira geeft zijn tropische Afrikaanse, caribische en latin grooves graag een psychedelisch randje, het liefst zo obscuur mogelijk. Dansbaar? You bet: de producer/percussionist maakte in de kennersbands Fumaça Preta en Conjunto Papa Upa respectievelijk tropical psychpunk en polyritmische surf en psychedelica. Geen wonder, want Figueira wordt gedreven door een aangeboren instinct voor de heetste ritmes. Vraag maar aan de klanten van zijn inmiddels ter ziele gegane eigen platenzaak, een onuitputtelijke bron van zijn astronomische vinylcollectie.",
    "startTime": "2024-07-05T23:30:00.000Z",
    "endTime": "2024-07-06T02:00:00.000Z",
    "name": "Alex Figueira",
    "socials": {
      "instagram": "https://www.instagram.com/alexvoudou/",
      "facebook": "https://www.facebook.com/alex.figueira",
      "spotify": "https://open.spotify.com/artist/1KKpoCjkEc0b0vAuiMe2uT?si=Rs3MZj-ITFykPH7fX-9P5g",
      "website": "https://www.alexfigueira.com/"
    },
    "stage": "idyllische veldje",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2918_crop_2000-869_fit_1400-540-center_85-Alex%20Figueira%20Promo%20Pic%201_site%282%29.jpg"
  },
  {
    "id": 160,
    "description": "De vaste huismixmeesters van het Idyllische Veldje verpletteren elk jaar weer alle verwachtingen. Vijf hoogbegaafde vinylkenners draaien samen drie dagen lang een schijnbaar oneindige voorraad vintage vibes. Back to back? Schouder aan schouder, zul je bedoelen. Wat het duo Discos Horizontes uit zijn collectie psychedelische en polyritmische tropicalia vist, wordt meesterlijk aangevuld door roots, rocksteady, mento, calypso, reggae en dancehall van het Eindhovense soundsystemcollectief Jamaican Jukebox. En andersom. Geen plaat hetzelfde, altijd raak, op precies het goeie moment.Foto door Dave van Hout",
    "startTime": "2024-07-05T08:00:00.000Z",
    "endTime": "2024-07-05T21:00:00.000Z",
    "name": "Discos Horizontes & Jamaican Jukebox",
    "socials": {
      "instagram": "https://www.instagram.com/discoshorizontes/?hl=en",
      "facebook": "https://www.facebook.com/people/Jamaican-Jukebox/100012477731767/?locale=ms_MY&paipv=0&eav=AfZFk-JjeUc6-QlRF0oo1UgPRFtjMZIBHAIRuv5ZWFGUj-IkX-t_fZ1MBdBSKHAt6BY&_rdr",
      "soundcloud": "https://soundcloud.com/discos-horizontes"
    },
    "stage": "idyllische veldje",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2923_fit_1400-540-center_85-Persfoto%20Discos%20Horizontes%20Jamaican%20Jukebox%20-%20Foto%20Dave%20van%20Hout%20Fotografie-3146%20Asteroid_site%20%283%29.jpg"
  },
  {
    "id": 161,
    "description": "De vaste huismixmeesters van het Idyllische Veldje verpletteren elk jaar weer alle verwachtingen. Vijf hoogbegaafde vinylkenners draaien samen drie dagen lang een schijnbaar oneindige voorraad vintage vibes. Back to back? Schouder aan schouder, zul je bedoelen. Wat het duo Discos Horizontes uit zijn collectie psychedelische en polyritmische tropicalia vist, wordt meesterlijk aangevuld door roots, rocksteady, mento, calypso, reggae en dancehall van het Eindhovense soundsystemcollectief Jamaican Jukebox. En andersom. Geen plaat hetzelfde, altijd raak, op precies het goeie moment.Foto door Dave van Hout",
    "startTime": "2024-07-06T08:00:00.000Z",
    "endTime": "2024-07-06T21:00:00.000Z",
    "name": "Discos Horizontes & Jamaican Jukebox",
    "socials": {
      "instagram": "https://www.instagram.com/discoshorizontes/?hl=en",
      "facebook": "https://www.facebook.com/people/Jamaican-Jukebox/100012477731767/?locale=ms_MY&paipv=0&eav=AfZFk-JjeUc6-QlRF0oo1UgPRFtjMZIBHAIRuv5ZWFGUj-IkX-t_fZ1MBdBSKHAt6BY&_rdr",
      "soundcloud": "https://soundcloud.com/discos-horizontes"
    },
    "stage": "idyllische veldje",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2923_fit_1400-540-center_85-Persfoto%20Discos%20Horizontes%20Jamaican%20Jukebox%20-%20Foto%20Dave%20van%20Hout%20Fotografie-3146%20Asteroid_site%20%283%29.jpg"
  },
  {
    "id": 162,
    "description": "De vaste huismixmeesters van het Idyllische Veldje verpletteren elk jaar weer alle verwachtingen. Vijf hoogbegaafde vinylkenners draaien samen drie dagen lang een schijnbaar oneindige voorraad vintage vibes. Back to back? Schouder aan schouder, zul je bedoelen. Wat het duo Discos Horizontes uit zijn collectie psychedelische en polyritmische tropicalia vist, wordt meesterlijk aangevuld door roots, rocksteady, mento, calypso, reggae en dancehall van het Eindhovense soundsystemcollectief Jamaican Jukebox. En andersom. Geen plaat hetzelfde, altijd raak, op precies het goeie moment.Foto door Dave van Hout",
    "startTime": "2024-07-07T08:00:00.000Z",
    "endTime": "2024-07-08T01:00:00.000Z",
    "name": "Discos Horizontes & Jamaican Jukebox",
    "socials": {
      "instagram": "https://www.instagram.com/discoshorizontes/?hl=en",
      "facebook": "https://www.facebook.com/people/Jamaican-Jukebox/100012477731767/?locale=ms_MY&paipv=0&eav=AfZFk-JjeUc6-QlRF0oo1UgPRFtjMZIBHAIRuv5ZWFGUj-IkX-t_fZ1MBdBSKHAt6BY&_rdr",
      "soundcloud": "https://soundcloud.com/discos-horizontes"
    },
    "stage": "idyllische veldje",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2923_fit_1400-540-center_85-Persfoto%20Discos%20Horizontes%20Jamaican%20Jukebox%20-%20Foto%20Dave%20van%20Hout%20Fotografie-3146%20Asteroid_site%20%283%29.jpg"
  },
  {
    "id": 163,
    "description": "Fladder-happy nachtvlinders in Utrecht kennen de vaste hand van DJ Nasty als geen ander. De Colombiaanse vult met haar verhitte latinbeats dansvloeren all over de Domstad, waaronder TivoliVredenburg. En verder, want ook op onlineradiostation Stranded FM zet deze groefrijdster regelmatig haar beste dansbeentje voor. Tijdens haar sets leiden haar bezielende salsa, merengue, cumbia, reggaeton en Afro-tropische ritmes je dan ook zwetend van kookpunt naar kookpunt – smekend om meer, meer, meer.",
    "startTime": "2024-07-05T21:00:00.000Z",
    "endTime": "2024-07-05T23:30:00.000Z",
    "name": "DJ Nasty",
    "socials": {
      "instagram": "https://www.instagram.com/djnastynl?igsh=enJzeGpobXMwcDBz&utm_source=qr"
    },
    "stage": "idyllische veldje",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2926_fit_1400-540-center_85-dj%20Nasty_site%282%29.jpg"
  },
  {
    "id": 164,
    "description": "Van Latijns-Amerika tot Afrika; in de vinyl-only collectie van Mango schijnt altijd de zon. Als oprichter van Baobab en startmotor van El Mágico Baobab Picó Soundsystem stroopt deze Nijmeegse dj alle tropische meridianen af voor de lekkerste obscure pareltjes en gruizige barrio-ritmes. Zijn wilde doordanssets zijn dus steevast tot de rand toe afgetankt met de hitte van de evenaar, die het toch al muy caliente Idyllische Veldje het absolute brandpunt van jouw nacht maken.",
    "startTime": "2024-07-06T23:30:00.000Z",
    "endTime": "2024-07-07T02:00:00.000Z",
    "name": "Mango",
    "socials": {
      "instagram": "https://www.instagram.com/baobab.music/"
    },
    "stage": "idyllische veldje",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2929_crop_1642-714-2-146_fit_1400-540-center_85-Foto%20Mango%282%29.jpg"
  },
  {
    "id": 165,
    "description": "Deze Bossche supernova brengt het heilige vuur naar de nachtelijke dansvloer op het Idyllische Veldje. Reken maar dat dat een zegen is, want Palo Santo Discos is een gepassioneerd liefhebber van global sounds die buiten de lijntjes kleuren, getuige ook zijn maandelijkse radioshow bij het gerenommeerde Britse onlineplatform NTS. Typische Palo Santo Discos’ sets lopen over van obscure funky tracks uit alle windstreken, met name Brazilië, Turkije en Afrika – een geheide match made in de hemelse Groene Heuvels.",
    "startTime": "2024-07-06T21:00:00.000Z",
    "endTime": "2024-07-06T23:30:00.000Z",
    "name": "Palo Santo Discos",
    "socials": {
      "instagram": "https://www.instagram.com/palosantodiscos/?hl=en",
      "soundcloud": "https://soundcloud.com/palosantodiscos"
    },
    "stage": "idyllische veldje",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2932_crop_1439-626-250_fit_1400-540-center_85-Palo_Santo_Discos_picture%282%29.jpg"
  },
  {
    "id": 166,
    "description": "De yogalessen in Tramontana aan het meer zijn een ideale manier om je dag te beginnen. Met behulp van reinigende drums en dynamic meditation breng je met de helende kracht van Vinyasa Flow-yoga je ademhaling en bewegingen in evenwicht, terwijl alle spanning in je vermoeide heupen en schouders wegsmelt als het ijs in al die cocktails van gisteravond. Diep in de Vinyasa Flow hervind je je fysieke, spirituele en mentale gezondheid, onder bezielende begeleiding van het Samana Yoga Center Amsterdam. Geschikt voor zowel gevorderden als absolute beginners!",
    "startTime": "2024-07-05T08:00:00.000Z",
    "endTime": "2024-07-05T11:00:00.000Z",
    "name": "Yoga",
    "socials": {},
    "stage": "tramonta",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2970_fit_1400-540-center_85-20230630_1033_DTRH_maxkneefel_97294_site.jpg"
  },
  {
    "id": 167,
    "description": "De yogalessen in Tramontana aan het meer zijn een ideale manier om je dag te beginnen. Met behulp van reinigende drums en dynamic meditation breng je met de helende kracht van Vinyasa Flow-yoga je ademhaling en bewegingen in evenwicht, terwijl alle spanning in je vermoeide heupen en schouders wegsmelt als het ijs in al die cocktails van gisteravond. Diep in de Vinyasa Flow hervind je je fysieke, spirituele en mentale gezondheid, onder bezielende begeleiding van het Samana Yoga Center Amsterdam. Geschikt voor zowel gevorderden als absolute beginners!",
    "startTime": "2024-07-06T08:00:00.000Z",
    "endTime": "2024-07-06T11:00:00.000Z",
    "name": "Yoga",
    "socials": {},
    "stage": "tramonta",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2970_fit_1400-540-center_85-20230630_1033_DTRH_maxkneefel_97294_site.jpg"
  },
  {
    "id": 168,
    "description": "De yogalessen in Tramontana aan het meer zijn een ideale manier om je dag te beginnen. Met behulp van reinigende drums en dynamic meditation breng je met de helende kracht van Vinyasa Flow-yoga je ademhaling en bewegingen in evenwicht, terwijl alle spanning in je vermoeide heupen en schouders wegsmelt als het ijs in al die cocktails van gisteravond. Diep in de Vinyasa Flow hervind je je fysieke, spirituele en mentale gezondheid, onder bezielende begeleiding van het Samana Yoga Center Amsterdam. Geschikt voor zowel gevorderden als absolute beginners!",
    "startTime": "2024-07-07T08:00:00.000Z",
    "endTime": "2024-07-07T11:00:00.000Z",
    "name": "Yoga",
    "socials": {},
    "stage": "tramonta",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2970_fit_1400-540-center_85-20230630_1033_DTRH_maxkneefel_97294_site.jpg"
  },
  {
    "id": 169,
    "description": "Tijdens Everybody Dance Now in Tramontana zet je met mensen met en zonder beperking samen schitterende dingen neer. Of je nu in een rolstoel zit, tijdelijk op krukken loopt, een Rabbit Hole-liefhebber met autisme of downsyndroom bent, of none of the above; deze dansworkshop onder professionele begeleiding is onbetaalbaar leuk voor iedereen. Want als dansers met verschillende capaciteiten hun persoonlijke kracht en mogelijkheden laten samensmelten op één podium ontstaat er pure magie – inclusief de grootste lol.",
    "startTime": "2024-07-05T13:00:00.000Z",
    "endTime": "2024-07-05T14:00:00.000Z",
    "name": "Everybody Dance Now",
    "socials": {},
    "stage": "tramonta",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2973_fit_1400-540-center_85-20230629_1940_Terrein-Camping_BartHeemskerk-_HL_3239-CR2_-.jpg"
  },
  {
    "id": 170,
    "description": "Calling all ballerina’s, ballerino’s en alles daartussen en -buiten! Tijdens deze wervelende dansworkshop zwier je ook met twee linkervoeten mee in Down The Rabbit Hole’s eigen Zwanenmeer. Na een lesje pas de deux met spitzenpro’s laat je je puikste pliés en pirouettes zien voor het hooggeëerde publiek bij de pleintent Tramontana aan het water. In vol ornaat natuurlijk, want een klassiek balletspektakel zonder tutu’s en tiara’s is bij de konijnen af.",
    "startTime": "2024-07-05T15:00:00.000Z",
    "endTime": "2024-07-05T16:00:00.000Z",
    "name": "Het Konijnenmeer",
    "socials": {},
    "stage": "tramonta",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2971_fit_1400-540-center_85-2e156dd4-01fc-4e2a-9d25-5d2981d50550.jpeg"
  },
  {
    "id": 171,
    "description": "Calling all ballerina’s, ballerino’s en alles daartussen en -buiten! Tijdens deze wervelende dansworkshop zwier je ook met twee linkervoeten mee in Down The Rabbit Hole’s eigen Zwanenmeer. Na een lesje pas de deux met spitzenpro’s laat je je puikste pliés en pirouettes zien voor het hooggeëerde publiek bij de pleintent Tramontana aan het water. In vol ornaat natuurlijk, want een klassiek balletspektakel zonder tutu’s en tiara’s is bij de konijnen af.",
    "startTime": "2024-07-06T15:00:00.000Z",
    "endTime": "2024-07-06T16:00:00.000Z",
    "name": "Het Konijnenmeer",
    "socials": {},
    "stage": "tramonta",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2971_fit_1400-540-center_85-2e156dd4-01fc-4e2a-9d25-5d2981d50550.jpeg"
  },
  {
    "id": 172,
    "description": "Calling all ballerina’s, ballerino’s en alles daartussen en -buiten! Tijdens deze wervelende dansworkshop zwier je ook met twee linkervoeten mee in Down The Rabbit Hole’s eigen Zwanenmeer. Na een lesje pas de deux met spitzenpro’s laat je je puikste pliés en pirouettes zien voor het hooggeëerde publiek bij de pleintent Tramontana aan het water. In vol ornaat natuurlijk, want een klassiek balletspektakel zonder tutu’s en tiara’s is bij de konijnen af.",
    "startTime": "2024-07-07T15:00:00.000Z",
    "endTime": "2024-07-07T16:00:00.000Z",
    "name": "Het Konijnenmeer",
    "socials": {},
    "stage": "tramonta",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2971_fit_1400-540-center_85-2e156dd4-01fc-4e2a-9d25-5d2981d50550.jpeg"
  },
  {
    "id": 173,
    "description": "Danskriebels? Dan moet je in Tramontana zijn, want aan het meer ontdek je het hele weekend nieuwe moves voor de dansvloer. Zoals Balkandans, een onweerstaanbare volksdansmix met grootse, sierlijke bewegingen met je heupen en armen, afgewisseld met ritmisch voetenwerk. Dansmeester van dienst is Daniel Sandu, een voormalige profdanser van het Internationale Danstheater, die je in een mum van tijd de basics én fijne kneepjes van het Balkandansen leert. En daarbij is alle ruimte voor eigen improvisatie, dus dans je samen altijd in de pas.",
    "startTime": "2024-07-06T13:00:00.000Z",
    "endTime": "2024-07-06T14:00:00.000Z",
    "name": "Balkandans",
    "socials": {},
    "stage": "tramonta",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2972_fit_1400-540-center_85-IMG_0356_site.jpg"
  },
  {
    "id": 174,
    "description": "Danskriebels? Dan moet je in Tramontana zijn, want aan het meer ontdek je het hele weekend nieuwe moves voor de dansvloer. Zoals Balkandans, een onweerstaanbare volksdansmix met grootse, sierlijke bewegingen met je heupen en armen, afgewisseld met ritmisch voetenwerk. Dansmeester van dienst is Daniel Sandu, een voormalige profdanser van het Internationale Danstheater, die je in een mum van tijd de basics én fijne kneepjes van het Balkandansen leert. En daarbij is alle ruimte voor eigen improvisatie, dus dans je samen altijd in de pas.",
    "startTime": "2024-07-07T13:00:00.000Z",
    "endTime": "2024-07-07T14:00:00.000Z",
    "name": "Balkandans",
    "socials": {},
    "stage": "tramonta",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2972_fit_1400-540-center_85-IMG_0356_site.jpg"
  },
  {
    "id": 175,
    "description": "De titel van deze podcast dekt de lading: hosts Arco Gnocchi, Nynke de Jong, Thom Aalmoes en Andrea Huntjens bespreken echt álle geschiedenis ooit. Van Napoleon tot de Spice Girls en van prins Bernhard tot ABBA: ze komen allemaal voorbij. Op Bermuda AIR duiken de podcasters tijdens twee liveopnames van Alle Geschiedenis Ooit diep in de muziekhistorie. Op zaterdag hoor je het naadje van de kous over boybands en op zondag kom je alles te weten over de opkomst van de Eurodance. Bomvol nostalgie, muziek en genoeg gekke feitjes en anekdotes die je de rest van het weekend aan je campingburen kunt vertellen!Hosts: Arco Gnocchi, Nynke de Jong, Thom Aalmoes & Andrea Huntjens",
    "startTime": "2024-07-06T15:30:00.000Z",
    "endTime": "2024-07-06T16:30:00.000Z",
    "name": "Alle Geschiedenis Ooit",
    "socials": {
      "instagram": "https://www.instagram.com/allegeschiedenisooit/"
    },
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2998_fit_1400-540-center_85-WhatsApp%20Image%202024-05-27%20at%2014.33.45.jpeg"
  },
  {
    "id": 176,
    "description": "De titel van deze podcast dekt de lading: hosts Arco Gnocchi, Nynke de Jong, Thom Aalmoes en Andrea Huntjens bespreken echt álle geschiedenis ooit. Van Napoleon tot de Spice Girls en van prins Bernhard tot ABBA: ze komen allemaal voorbij. Op Bermuda AIR duiken de podcasters tijdens twee liveopnames van Alle Geschiedenis Ooit diep in de muziekhistorie. Op zaterdag hoor je het naadje van de kous over boybands en op zondag kom je alles te weten over de opkomst van de Eurodance. Bomvol nostalgie, muziek en genoeg gekke feitjes en anekdotes die je de rest van het weekend aan je campingburen kunt vertellen!Hosts: Arco Gnocchi, Nynke de Jong, Thom Aalmoes & Andrea Huntjens",
    "startTime": "2024-07-07T15:00:00.000Z",
    "endTime": "2024-07-07T16:00:00.000Z",
    "name": "Alle Geschiedenis Ooit",
    "socials": {
      "instagram": "https://www.instagram.com/allegeschiedenisooit/"
    },
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2998_fit_1400-540-center_85-WhatsApp%20Image%202024-05-27%20at%2014.33.45.jpeg"
  },
  {
    "id": 177,
    "description": "Begin je festivaldag bij de Brainwash Breakfast Club op openluchtpodium Bermuda AIR! Het Brainwash Festival is het grootste denkfestival van Nederland en België. Op hun jaarlijkse festival in Amsterdam komen meer dan honderd baanbrekende filosofen, wetenschappers, theatermakers en schrijvers samen om antwoorden te vinden op de grootste vragen van vandaag.Terwijl jij op Down The Rabbit Hole de slaap uit je ogen veegt, bevraag je tijdens de Brainwash Breakfast Club ons menszijn. Want wat voor mens ben jij eigenlijk? Een spelend mens, een solidaire ziel, een liefhebbend wezen. Of allemaal tegelijk? Welke waarden, ideeën en mensen beïnvloeden jouw keuzes? En met welke dilemma's worstel je? In hun club ontvangt Brainwash Lale Gül, Simon van Teutem, Safae el Khannoussi, Lucky Fonz III, Sef en vele anderen. Tatjana Almuli gidst je door de show, Eva van Manen speelt muziek en Lev Avitan sluit elke dag met wijze woorden af.Vrijdag – Brainwash Breakfast Club: de spelende mensRust en regelmaat of vrijheid, blijheid? Uitgaan of thuisblijven? Verstand of intuïtie? Met Lale Gül, Floor Rusman en jullie bespreekt de Brainwash Breakfast Club onze hang naar vrijheid en collectief plezier. Wat betekent het om homo ludens te zijn? Hoe vrij zijn we als we spelen? En welke verantwoordelijkheden staan er op het spel? Alle dilemma’s zijn welkom!Presentatie door Tatjana Almuli, muziek van Eva van Manen en wijze woorden van Lev Avitan.",
    "startTime": "2024-07-05T09:30:00.000Z",
    "endTime": "2024-07-05T10:30:00.000Z",
    "name": "Brainwash Breakfast Club: De spelende mens",
    "socials": {
      "instagram": "https://www.instagram.com/brainwash_festival/",
      "website": "https://brainwashfestival.nl/"
    },
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2992_fit_1400-540-center_85-Dag%201%20%E2%80%93%20spelende%20mens%20DTRH.jpg"
  },
  {
    "id": 178,
    "description": "Begin je festivaldag bij de Brainwash Breakfast Club op openluchtpodium Bermuda AIR! Het Brainwash Festival is het grootste denkfestival van Nederland en België. Op hun jaarlijkse festival in Amsterdam komen meer dan honderd baanbrekende filosofen, wetenschappers, theatermakers en schrijvers samen om antwoorden te vinden op de grootste vragen van vandaag.Terwijl jij op Down The Rabbit Hole de slaap uit je ogen veegt, bevraag je tijdens de Brainwash Breakfast Club ons menszijn. Want wat voor mens ben jij eigenlijk? Een spelend mens, een solidaire ziel, een liefhebbend wezen. Of allemaal tegelijk? Welke waarden, ideeën en mensen beïnvloeden jouw keuzes? En met welke dilemma's worstel je? In hun club ontvangt Brainwash Lale Gül, Simon van Teutem, Safae el Khannoussi, Lucky Fonz III, Sef en vele anderen. Tatjana Almuli gidst je door de show, Eva van Manen speelt muziek en Lev Avitan sluit elke dag met wijze woorden af.Zaterdag – Brainwash Breakfast Club: de solidaire mensStrategisch of ideologisch stemmen? Demonstreren of doneren? Tweedehands of fast-fashion? Vooruitgang of alles hetzelfde? Hoe kunnen we het ooit goed doen als mens? En met wie zijn we solidair? Met Simon van Teutem, Safae el Khannoussi en jullie gaat de Brainwash Breakfast Club op zoek naar dat wat we belangrijk vinden. Salonsocialisme of de barricades op? Het recht van de sterkste of de zwakste? Alle dilemma’s zijn welkom!Presentatie door Tatjana Almuli, muziek van Eva van Manen en wijze woorden van Lev Avitan.",
    "startTime": "2024-07-06T09:30:00.000Z",
    "endTime": "2024-07-06T10:30:00.000Z",
    "name": "Brainwash Breakfast Club: De solidaire mens",
    "socials": {
      "instagram": "https://www.instagram.com/brainwash_festival/",
      "website": "https://brainwashfestival.nl/"
    },
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2993_fit_1400-540-center_85-Dag%202%20%E2%80%93%20solidaire%20mens%20DTRH.jpg"
  },
  {
    "id": 179,
    "description": "Begin je festivaldag bij de Brainwash Breakfast Club op openluchtpodium Bermuda AIR! Het Brainwash Festival is het grootste denkfestival van Nederland en België. Op hun jaarlijkse festival in Amsterdam komen meer dan honderd baanbrekende filosofen, wetenschappers, theatermakers en schrijvers samen om antwoorden te vinden op de grootste vragen van vandaag.Terwijl jij op Down The Rabbit Hole de slaap uit je ogen veegt, bevraag je tijdens de Brainwash Breakfast Club ons menszijn. Want wat voor mens ben jij eigenlijk? Een spelend mens, een solidaire ziel, een liefhebbend wezen. Of allemaal tegelijk? Welke waarden, ideeën en mensen beïnvloeden jouw keuzes? En met welke dilemma's worstel je? In hun club ontvangt Brainwash Lale Gül, Simon van Teutem, Safae el Khannoussi, Lucky Fonz III, Sef en vele anderen. Tatjana Almuli gidst je door de show, Eva van Manen speelt muziek en Lev Avitan sluit elke dag met wijze woorden af.Zondag – Brainwash Breakfast Club: de liefhebbende mens Hoe geven leven en dood, en liefde en lust vorm aan ons bestaan? Welke rol spelen seks en gender hierin? En hoe beïnvloeden moderne relaties ons menszijn? Met Lucky Fonz III, Alma Mathijsen en jullie verkent de Brainwash Breakfast Club het hele spectrum; van eenzaamheid tot heartbreak en van rouw tot ouderschap. Welke taboes moeten we doorbreken en welke vastgeroeste patronen laten we achter? Alle dilemma’s zijn welkom!Presentatie door Tatjana Almuli, muziek van Eva van Manen en wijze woorden van Lev Avitan.",
    "startTime": "2024-07-07T09:30:00.000Z",
    "endTime": "2024-07-07T10:30:00.000Z",
    "name": "Brainwash Breakfast Club: De liefhebbende mens",
    "socials": {
      "instagram": "https://www.instagram.com/brainwash_festival/",
      "website": "https://brainwashfestival.nl/"
    },
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2994_fit_1400-540-center_85-Dag%203%20%E2%80%93%C2%A0liefhebbende%20mens%20DTRH.jpg"
  },
  {
    "id": 180,
    "description": "De Speakers Corner op Bermuda AIR is een open podium voor verhalen van elk kaliber, op ongedwongen, licht chaotische en charmante wijze gepresenteerd door hosts Eva Crutzen en Yvonne van den Eerenbeemt. Kom hangen, kom luisteren of – nog beter – kom vertellen wat je bezighoudt. Of je nu iets wilt delen over je winderige chihuahua, je droom om bomen te planten in de Amazone of iets aardig wetenswaardigs daartussenin; elk verhaal krijgt hier alle ruimte. Want in de Speakers Corner geldt: luisteren is zilver, spreken is goud.",
    "startTime": "2024-07-05T12:00:00.000Z",
    "endTime": "2024-07-05T13:30:00.000Z",
    "name": "Speakers Corner",
    "socials": {},
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2995_fit_1400-540-center_85-20230701_1540_SfeerAlgemeen_BartHeemskerk-_HL_8355-CR2_-.jpg"
  },
  {
    "id": 181,
    "description": "De Speakers Corner op Bermuda AIR is een open podium voor verhalen van elk kaliber, op ongedwongen, licht chaotische en charmante wijze gepresenteerd door hosts Eva Crutzen en Yvonne van den Eerenbeemt. Kom hangen, kom luisteren of – nog beter – kom vertellen wat je bezighoudt. Of je nu iets wilt delen over je winderige chihuahua, je droom om bomen te planten in de Amazone of iets aardig wetenswaardigs daartussenin; elk verhaal krijgt hier alle ruimte. Want in de Speakers Corner geldt: luisteren is zilver, spreken is goud.",
    "startTime": "2024-07-06T12:00:00.000Z",
    "endTime": "2024-07-06T13:30:00.000Z",
    "name": "Speakers Corner",
    "socials": {},
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2995_fit_1400-540-center_85-20230701_1540_SfeerAlgemeen_BartHeemskerk-_HL_8355-CR2_-.jpg"
  },
  {
    "id": 182,
    "description": "De Speakers Corner op Bermuda AIR is een open podium voor verhalen van elk kaliber, op ongedwongen, licht chaotische en charmante wijze gepresenteerd door hosts Eva Crutzen en Yvonne van den Eerenbeemt. Kom hangen, kom luisteren of – nog beter – kom vertellen wat je bezighoudt. Of je nu iets wilt delen over je winderige chihuahua, je droom om bomen te planten in de Amazone of iets aardig wetenswaardigs daartussenin; elk verhaal krijgt hier alle ruimte. Want in de Speakers Corner geldt: luisteren is zilver, spreken is goud.",
    "startTime": "2024-07-07T12:00:00.000Z",
    "endTime": "2024-07-07T13:30:00.000Z",
    "name": "Speakers Corner",
    "socials": {},
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2995_fit_1400-540-center_85-20230701_1540_SfeerAlgemeen_BartHeemskerk-_HL_8355-CR2_-.jpg"
  },
  {
    "id": 183,
    "description": "🌈🌈🌈",
    "startTime": "2024-07-05T15:00:00.000Z",
    "endTime": "2024-07-05T16:00:00.000Z",
    "name": "Surprise",
    "socials": {},
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2996_fit_1400-540-center_85-De-Lesbische-Liga-credit-Carin-Verbruggen-Ferry-Drenthem-Soesman-voor-Winq_site.jpg"
  },
  {
    "id": 184,
    "description": "Het funky Britse souldiscoduo Jungle komt met een totaalpakket naar de Groene Heuvels: op zaterdag headlinen Josh Lloyd-Watson en Tom McFarland met hun veelkoppige liveband op hoofdpodium Hotot, de rest van het weekend zie je ze in ‘Volcano – A Motion Picture by Jungle’ op het silver screen van openluchtstage Bermuda AIR. Ook niet te missen, want deze wervelende, drie kwartier durende muziekvideo voor hun hele album ‘Volcano’ kun je doorgaans alleen via streaming checken. Dansen in de bios – alleen op Down The Rabbit Hole.",
    "startTime": "2024-07-05T20:30:00.000Z",
    "endTime": "2024-07-05T21:30:00.000Z",
    "name": "Volcano – A Motion Picture by Jungle",
    "socials": {},
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2997_fit_1400-540-center_85-volcano.jpg"
  },
  {
    "id": 185,
    "description": "Het funky Britse souldiscoduo Jungle komt met een totaalpakket naar de Groene Heuvels: op zaterdag headlinen Josh Lloyd-Watson en Tom McFarland met hun veelkoppige liveband op hoofdpodium Hotot, de rest van het weekend zie je ze in ‘Volcano – A Motion Picture by Jungle’ op het silver screen van openluchtstage Bermuda AIR. Ook niet te missen, want deze wervelende, drie kwartier durende muziekvideo voor hun hele album ‘Volcano’ kun je doorgaans alleen via streaming checken. Dansen in de bios – alleen op Down The Rabbit Hole.",
    "startTime": "2024-07-06T20:30:00.000Z",
    "endTime": "2024-07-06T21:30:00.000Z",
    "name": "Volcano – A Motion Picture by Jungle",
    "socials": {},
    "stage": "bermuda air",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2997_fit_1400-540-center_85-volcano.jpg"
  },
  {
    "id": 186,
    "description": "Met de dikke doordraaishow geven we je niet één feestje maar zes in één. Stap in de wereld van Desperados en omarm het onverwachte; duik je na 20 minuten een kussengevecht, ontdek je hoe flexibel je nog bent na je eerste nacht op je luchtbed of heb jij je eerste hoogtepunt straks al achter de rug met de waanzinnige guest appearance die we voor je in petto hebben.",
    "startTime": "2024-07-05T12:00:00.000Z",
    "endTime": "2024-07-05T14:00:00.000Z",
    "name": "Porto Party: De dikke doordraaishow",
    "socials": {},
    "stage": "desperados",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_3022_fit_1400-540-center_85-20230630_1448_DesperadosStage_BartHeemskerk-_LH_6527-CR2_-%281%29.jpg"
  },
  {
    "id": 187,
    "description": "Ben jij een trouwe labrador, een parmantige poedel, een hitsige jachthond of een loopse teef? Een Lady of een Vagebond? Een Pitbull of een Snoop Dogg? Mag jij lekker snuffelen of lig je aan de riem? Hoe dan ook: Laat jezelf helemaal uit op DesperaDOGS, een Porto Party met beestachtige muziek om je viervoeters van de vloer te krijgen! Samen worden we hondsdol op Down The Rabbit Hole. Paw-ty!",
    "startTime": "2024-07-05T16:30:00.000Z",
    "endTime": "2024-07-05T18:30:00.000Z",
    "name": "Porto Party: DesperaDOGS",
    "socials": {},
    "stage": "desperados",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2982_fit_1400-540-center_85-2.%20Desperadogs%20-%20Wil%20je%20op%20mijn%20feestje%20komen_site.png"
  },
  {
    "id": 188,
    "description": "Of je nou net je lekke luchtbed af komt rollen of al een yogaklasje achter de rug hebt: bij de DRAAIORGELDISCO word je wel wakker! Kom nostalgie proeven op deze Porto Party, waar wij welverdiende ere schenken aan oer-Hollandsche cultuur. Mis het niet, en draai lekker met ons door!",
    "startTime": "2024-07-06T12:00:00.000Z",
    "endTime": "2024-07-06T14:00:00.000Z",
    "name": "Porto Party: DRAAIORGELDISCO",
    "socials": {},
    "stage": "desperados",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2984_fit_1400-540-center_85-4%20Draaiorgel%20Disco%20poster_site.png"
  },
  {
    "id": 189,
    "description": "Good evening Beuningen! Vanavond is het songfestival weer van de fans. Twee uur lang glitters, hysterisch schreeuwen, een schitterend ABBA-kwartier, vlaggen zwaaien en alleen maar Eurovisie-hits. Wie gaat er na deze Porto Party naar huis met de grote Eurovisietrofee!? Douze points to everyone, but especially to the queers. LOVE LOVE PEACE PEACE!",
    "startTime": "2024-07-05T21:00:00.000Z",
    "endTime": "2024-07-05T23:00:00.000Z",
    "name": "Porto Party: Queerovision",
    "socials": {},
    "stage": "desperados",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2983_fit_1400-540-center_85-3%20Queerovision_site.png"
  },
  {
    "id": 190,
    "description": "Stap in je meest schandalige cowboylaarzen en zet je schrap voor Cunty Country – de Porto Party waar de wildwestwind over Down the Rabbit Hole waait! Dit is geen plekvoor brave cowboys en cowgirls, hier laat je je innerlijke outlaw los op de dansvloer. Met een mix van Shania Twains twang en de playlist van je dronken tante zorgen we ervoor dat je in no time staat te linedancen. Saddle up & get ready for the ride of your life!",
    "startTime": "2024-07-06T16:30:00.000Z",
    "endTime": "2024-07-06T18:30:00.000Z",
    "name": "Porto Party: Cunty Country",
    "socials": {},
    "stage": "desperados",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2985_fit_1400-540-center_85-Cunty_Country_site.jpg"
  },
  {
    "id": 191,
    "description": "De diagnose is binnen: een danstekort en een te laag hartritme. Dat is natuurlijk levensgevaarlijk en het enige medicijn is een goede dosis jungle en drum & bass. Gelukkig kun je bij Skankenhaus je hartritme met de gruwelijkste breaks weer oppompen tot een gezonde 160 bpm. Disclaimer: deze Porto Party gaat mogelijk gepaard met een flinke revalidatietijd!",
    "startTime": "2024-07-06T21:00:00.000Z",
    "endTime": "2024-07-06T23:00:00.000Z",
    "name": "Porto Party: Skankenhaus",
    "socials": {},
    "stage": "desperados",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2986_fit_1400-540-center_85-6%20skankenhaus_A2_CMYK_page-0001%2B_site.jpg"
  },
  {
    "id": 192,
    "description": "Ooit werd er bedacht om grappige leuzen af te spelen over een box met die ene vertaalsite met de monotone stem. En ideeën kunnen escaleren, helemaal in het nachtleven. Fast forward naar DTRH24, waar MC Taal Trance naar deze Porto Party afreist om al jullie leuzen als een mad mic-master door de muziek heen te mixen. Dus kom allemaal met je brakke kop op dit geweldige tijdstip op zondag genieten van een goed feestje met een wel heel bijzondere ceremoniemeester!",
    "startTime": "2024-07-07T10:00:00.000Z",
    "endTime": "2024-07-07T12:00:00.000Z",
    "name": "Porto Party: Taal Trance",
    "socials": {},
    "stage": "desperados",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2987_fit_1400-540-center_85-7.%20Taal%20trance%20ZONDAG%207%20JULI%202024%201200%20-%201400_site.png"
  },
  {
    "id": 193,
    "description": "Alle Lance aan dek! Zet je snelste planga op en sprint langs de contrôle antidopage naar het snelste feestje van het weekend: Tour de Dance! Aanschouw een zinderende Touretappe en ga op deze Porto Party twee uur lang los op de lekkerste nineties-danceknallers. Hijs jezelf in je strakste fietspak en daag elkaar uit in de bierfietscompetitie – maar voorkom dat je de Rode Lantaarn wordt! Parijs is nog ver, maar het feest begint hier!!",
    "startTime": "2024-07-07T13:30:00.000Z",
    "endTime": "2024-07-07T15:30:00.000Z",
    "name": "Porto Party: Tour de Dance",
    "socials": {},
    "stage": "desperados",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2988_fit_1400-540-center_85-8%20Tour%20de%20Dance_site.png"
  },
  {
    "id": 194,
    "description": "Hoe ziet jouw leven eruit na Down The Rabbit Hole? Je gaat het beleven bij Down The Holy Rabbit: op deze Porto Party waan je je in de hemel vol dansende konijnen, waar heilige hitjes de afsluiting zijn van jouw festivalweekend. We eren Holy Rabbit tot we zelf hemelen! Niet getreurd, we stomen je klaar om in het hiernamaals van 2025 weer te herrijzen.",
    "startTime": "2024-07-07T19:00:00.000Z",
    "endTime": "2024-07-07T21:00:00.000Z",
    "name": "Porto Party: Down The Holy Rabbit",
    "socials": {},
    "stage": "desperados",
    "imgSrc": "https://downtherabbithole.nl/img/cache/media_2989_fit_1400-540-center_85-9%20PosterDownTheHolyRabbit_site.jpg"
  }
]