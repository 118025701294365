import React from 'react'
import { twMerge } from 'tailwind-merge'

interface PageTitleProps {
  children: React.ReactNode
  className?: string
}

export default function PageTitle({ children, className }: PageTitleProps) {
  return (
    <h1
      className={twMerge(
        'text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight',
        className
      )}
    >
      {children}
    </h1>
  )
}
