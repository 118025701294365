import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function FriendCounterBadge({
  className,
  compactMode = true,
  count,
}: {
  className?: string
  compactMode?: boolean
  count: number
}) {
  const interestedFriendsString = count === 1 ? ' friend' : ' friends'
  return (
    <p
      className={twMerge(
        'flex items-center justify-center rounded-full bg-black px-2 font-mono text-sm font-semibold leading-6 text-white',
        className
      )}
    >
      {count}
      {!compactMode && interestedFriendsString}
    </p>
  )
}
