import React from 'react'

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

interface PopupProps {
  children: React.ReactNode
  closePopup: () => void
  isOpen: boolean
  panelClassName?: string
}

function Popup({ children, isOpen, closePopup, panelClassName }: PopupProps) {
  return (
    <Dialog className="fixed isolate z-50" open={isOpen} onClose={closePopup}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={twMerge(
              'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95',
              panelClassName
            )}
          >
            <button onClick={closePopup} className="mb-2 ml-auto mr-0 block py-2 pl-2">
              <XMarkIcon className="h-6 w-6 text-gray-500" />
            </button>

            {children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default Popup
