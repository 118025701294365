import { PlusCircleIcon, ShareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { clsx } from 'clsx'
import React, { useState } from 'react'
import CopyButton from '~/components/copy-button'
import { dtrhBitfieldSize } from '~/data/dtrh2024'
import { BitField } from '~/lib/bitfield'
import { useStore } from '~/store'
import Button from '../button'
import PageTitle from '../page-title'
import Popup from '../popup'
import FriendList from './friend-list'
import NoFriends from './no-friends'

const defaultFormValues = {
  name: '',
  config: '',
}

function ManualEntryPopup({ closePopup }: { closePopup: () => void }) {
  const personalConfigurationString = useStore((state) => state.personalConfigurationString)
  const setPersonalConfigurationString = useStore((state) => state.setPersonalConfigurationString)
  const [config, setConfig] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (error) {
      setError(null)
    }

    try {
      if (config.length === 0) {
        setError('Configuration string is required')
        return
      }
      if (config.length !== new BitField(dtrhBitfieldSize).asBase64Url().length) {
        setError('Invalid configuration string, looking for a string with length')
      }

      if (config.length !== new BitField(dtrhBitfieldSize).asBase64Url().length) {
        setError(
          `The config you specified is not the right length. It should be ${new BitField(dtrhBitfieldSize).asBase64Url().length} characters long and end with two equal signs.`
        )
        return
      }

      BitField.fromBase64Url(config, dtrhBitfieldSize)
    } catch (error) {
      console.error(error)
      setError('Invalid configuration string')
      return
    }

    if (config) {
      setPersonalConfigurationString(config)

      closePopup()
    }
  }

  return (
    <Popup closePopup={closePopup} isOpen={true} panelClassName="max-w-sm sm:max-w-sm md:max-w-sm">
      <div className="flex flex-col">
        <p className="mb-2 text-center text-lg font-bold tracking-tight sm:text-2xl">
          Manual entry of your configuration string
        </p>
        <p>
          If you made your selections on a different device, or deleted your browser cache, you can manually reset your
          configuration to that state.
        </p>
        <p className="mt-4 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
          Updating this string wipes the current configuration, so be careful!
        </p>
        <form onSubmit={(e) => handleSubmit(e)}>
          <label htmlFor="config" className="mt-4 block text-sm font-medium leading-6 text-gray-900">
            Configuration string
          </label>
          <p className="mb-2 text-xs text-gray-500">
            If you wish to abort, click cancel or close the popup.
            <br /> The currently saved configuration is:
            <br /> <code>{personalConfigurationString}</code>
          </p>
          <div className="mt-2">
            <input
              type="config"
              name="config"
              id="config"
              className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={personalConfigurationString}
              onChange={(event) => setConfig(event.target.value)}
              value={config}
            />
          </div>
          {error && <p className="mt-2 text-sm font-semibold text-red-700">{error}</p>}
          <div className="mt-8 flex items-center justify-end gap-4">
            <Button onClick={closePopup} className="rounded-md text-base text-gray-900">
              Cancel
            </Button>
            <Button className="rounded-md text-base text-green-600" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Popup>
  )
}

function AddAFriendPopup({ closePopup }: { closePopup: () => void }) {
  const personalConfigurationString = useStore((state) => state.personalConfigurationString)
  const addFriend = useStore((state) => state.addFriend)
  const friends = useStore((state) => state.getFriends())
  const friendIds = friends.map((friend) => friend.id)

  const [name, setName] = useState<string>(defaultFormValues.name)
  const [config, setConfig] = useState<string>(defaultFormValues.name)
  const [error, setError] = useState<string | null>(null)

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (error) {
      setError(null)
    }

    try {
      if (config.length === 0) {
        setError('Your configuration string is required')
        return
      }
      if (config.length !== new BitField(dtrhBitfieldSize).asBase64Url().length) {
        setError('Invalid configuration string, looking for a string with length')
      }

      if (config.length !== new BitField(dtrhBitfieldSize).asBase64Url().length) {
        setError(
          `The config you specified is not the right length. It should be characters ${new BitField(dtrhBitfieldSize).asBase64Url().length} long and end with two equal signs.`
        )
        return
      }

      BitField.fromBase64Url(config, dtrhBitfieldSize)
    } catch (error) {
      console.error(error)
      setError('Invalid configuration string')
      return
    }

    if (name && config) {
      addFriend({
        id: Math.max(...friendIds) + 1,
        name: name,
        bitField: BitField.fromBase64Url(config, dtrhBitfieldSize),
      })

      closePopup()
    }
  }

  return (
    <Popup closePopup={closePopup} isOpen={true} panelClassName="max-w-sm sm:max-w-sm md:max-w-sm">
      <div className="flex flex-col">
        <p className="text-center text-lg font-bold tracking-tight sm:text-2xl">Add a friend</p>
        <form onSubmit={(e) => handleSubmit(e)}>
          <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
            Name
          </label>
          <div className="mt-2">
            <input
              type="name"
              name="name"
              id="name"
              className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Pete"
              required
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <label htmlFor="config" className="mt-4 block text-sm font-medium leading-6 text-gray-900">
            Configuration string
          </label>
          <p className="mb-2 text-xs text-gray-500">
            This is the string that your friend has shared with you. It looks a bit scary. Make sure ends with two equal
            signs.
          </p>
          <div className="mt-2">
            <input
              type="config"
              name="config"
              id="config"
              className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={personalConfigurationString}
              onChange={(event) => setConfig(event.target.value)}
            />
          </div>
          {error && <p className="mt-2 text-sm font-semibold text-red-700">{error}</p>}
          <div className="mt-8 flex items-center justify-end gap-4">
            <Button onClick={closePopup} className="rounded-md text-base text-gray-900">
              Cancel
            </Button>
            <Button className="rounded-md text-base text-green-600" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Popup>
  )
}

function RemoveAllFriendsPopup({ closePopup }: { closePopup: () => void }) {
  const removeAllFriends = useStore((state) => state.removeAllFriends)
  return (
    <Popup closePopup={closePopup} isOpen={true} panelClassName="max-w-sm sm:max-w-sm md:max-w-sm">
      <div className="flex flex-col">
        <p className="text-center text-lg font-bold tracking-tight sm:text-2xl">Danger zone 🤘</p>
        <p>Are you sure you want to remove all your friends?</p>
        <p>This action cannot be undone.</p>

        <div className="mt-4 flex justify-center gap-4">
          <Button
            onClick={removeAllFriends}
            className="rounded-md border-transparent bg-red-600 text-base text-white hover:bg-red-700"
          >
            Yes, remove all friends
          </Button>
          <Button onClick={closePopup} className="rounded-md bg-gray-100 text-base text-gray-900">
            Cancel
          </Button>
        </div>
      </div>
    </Popup>
  )
}

function Explainer({ className = '' }: { className?: string }) {
  return (
    <div className={clsx('border-md rounded-md bg-gradient-to-r from-yellow-500 to-red-500 p-4 shadow-md', className)}>
      <div className="rounded-md bg-white p-4 tracking-tight">
        <span className="text-justify text-lg font-semibold">
          Easily add your friends' program selections for the festival here!
        </span>
        <br />
        <br />
        To get started, ask your friends for their configuration strings and add them below. You can also share your
        configuration string with friends, so they can add it to their app. <br />
        <br /> <b>Note</b>: If you or your friends update your program configurations, remember to update the
        configuration strings in your friends list to stay in sync.
      </div>
    </div>
  )
}

export default function Friends({}) {
  const personalConfigurationString = useStore((state) => state.personalConfigurationString)
  const friends = useStore((state) => state.getFriends())
  const shareData = `I'm using Better Timetable on DTRH2024! Add my configuration to your friends list: ${personalConfigurationString}`

  const [isAddAFriendVisible, setIsAddAFriendVisible] = useState<boolean>(false)
  const [isRemoveAllFriendsPopupVisible, setIsRemoveAllFriendsPopupVisible] = useState<boolean>(false)
  const [isManualEntryPopupOpen, setIsManualEntryPopupOpen] = useState<boolean>(false)
  return (
    <section className="w-full">
      <div className="mb-4 flex items-center justify-end sm:mb-0 sm:justify-between">
        <PageTitle className="hidden sm:block">Friends</PageTitle>
        <div className="flex items-center gap-2">
          <Button
            className="text-md flex items-center gap-2 p-2 font-semibold"
            onClick={() => setIsAddAFriendVisible(!isAddAFriendVisible)}
          >
            <PlusCircleIcon className="h-5 w-5 text-green-500" />
            <span className="hidden sm:block">Add friend</span>
          </Button>
          <Button
            className="text-md flex items-center gap-2 p-2 font-semibold"
            onClick={() => setIsRemoveAllFriendsPopupVisible(true)}
          >
            <TrashIcon className="h-5 w-5 text-red-500" />
            <span className="hidden sm:block">Remove all friends</span>
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-x-8 gap-y-2 sm:grid-cols-2">
        <div className="self-start sm:mt-8">
          <Explainer />
          <div className="mt-8 flex items-center justify-between gap-2">
            <div className="flex flex-col">
              <p className="text-xl font-semibold">Your configuration</p>
              <p className="text-xs text-gray-500">
                This changes when you like or unlike an act. If you only see a bunch of A's: get to liking some acts!
              </p>
            </div>
            <Button onClick={() => setIsManualEntryPopupOpen(true)}>
              <PencilSquareIcon className="h-6 w-6" />
            </Button>
            {navigator.share && navigator.canShare({ text: shareData }) && (
              <Button
                onClick={async () => {
                  await navigator.share({ text: shareData })
                }}
              >
                <ShareIcon className="h-6 w-6" />
              </Button>
            )}
          </div>
          <div className="mt-2 flex rounded-md shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <input
                type="text"
                className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={personalConfigurationString}
              />
            </div>
            <CopyButton className="rounded-l-none" value={personalConfigurationString} />
          </div>
        </div>
        {friends.length === 0 ? <NoFriends /> : <FriendList friends={friends} />}
      </div>
      {isRemoveAllFriendsPopupVisible && (
        <RemoveAllFriendsPopup closePopup={() => setIsRemoveAllFriendsPopupVisible(false)} />
      )}
      {isAddAFriendVisible && <AddAFriendPopup closePopup={() => setIsAddAFriendVisible(false)} />}
      {isManualEntryPopupOpen && <ManualEntryPopup closePopup={() => setIsManualEntryPopupOpen(false)} />}
    </section>
  )
}
