import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { differenceInMinutes, eachHourOfInterval, format, isBefore, roundToNearestHours, sub } from 'date-fns'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { dtrhStages, type Act } from '~/data/dtrh2024'
import { useStore } from '../store'
import ArtistDetailPopup from './artist-detail-pop'
import { ArtistStar } from './artist-star'
import Button from './button'
import FriendCounterBadge from './friend-counter-badge'

const STAGE_HEADER_HEIGHT = 32
const STAGE_ENTRY_HEIGHT = 96
const HEIGHT_IN_PX_PER_STAGE = STAGE_HEADER_HEIGHT + STAGE_ENTRY_HEIGHT
const TIME_HEADER_HEIGHT = 41
const TIME_FOOTER_HEIGHT = 41

const NAVBAR_HEIGHT = 64
const VERTICAL_GUTTER_TOP = 32
const HEADER_ELEMENT = 52
const GAP_BETWEEN_HEADER_AND_TIMETABLE = 16

const maxTimetableHeight = `calc(100vh - ${NAVBAR_HEIGHT}px - ${VERTICAL_GUTTER_TOP}px - ${HEADER_ELEMENT}px - ${GAP_BETWEEN_HEADER_AND_TIMETABLE}px)`

const patternClassNames = 'bg-gradient-to-r from-yellow-500 to-red-500'

function TimeHeader({ hours, validatedPixelsPerMinute }: { hours: Date[]; validatedPixelsPerMinute: number }) {
  return (
    <div
      className={clsx(
        `h-[${TIME_HEADER_HEIGHT}px] z-20`,
        'sticky left-0 top-0 border-b border-black bg-white',
        'shadow-lg',
        'select-none',
        'font-mono'
      )}
      style={{ height: TIME_HEADER_HEIGHT }}
    >
      {hours.map((hour, index) => {
        const isFirstHour = index === 0
        const isFirstHourOrNextDay = isFirstHour || hour.getHours() === 0
        const hourString = isFirstHourOrNextDay
          ? format(hour, 'dd LLL')
          : hour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

        let marginStyles = ''

        if (isFirstHour) {
          marginStyles = 'ml-0'
        } else if (hour.getHours() === 0) {
          marginStyles = 'ml-0'
        } else {
          marginStyles = '-ml-6'
        }

        return (
          <div
            className={clsx('px inline-block py-2', isFirstHour ? 'border-l-0' : 'border-l border-l-black')}
            key={hour.getTime()}
            style={{ width: validatedPixelsPerMinute * 60 }}
          >
            <span className={clsx(marginStyles, isFirstHourOrNextDay ? 'bg-black text-white' : 'bg-white text-black')}>
              {hourString}
            </span>
          </div>
        )
      })}
    </div>
  )
}

function TimetableItem({
  act,
  durationInMinutes,
  marginLeft,
  width,
}: {
  act: Act
  durationInMinutes: number
  marginLeft: number
  width: number
}) {
  const [isTimetableItemOpen, setIsTimetableItemOpen] = useState(false)
  const artistConfiguration = useStore((state) => state.getArtistConfiguration(act.id))
  const interestedFriendsCount = useStore((state) => state.getInterestedFriendsCount(act.id))
  const hidePhotos = useStore((state) => state.hidePhotos)

  const actBackgroundStyles = hidePhotos
    ? {
        clipPath: 'polygon(0 0, 0 100%, 80% 0)',
        // background: 'rgb(234,179,8)',
        background: 'linear-gradient(90deg, rgba(234,179,8,1) 0%, rgba(239,68,68,1) 100%)',
      }
    : {
        clipPath: 'polygon(0 0, 0 100%, 80% 0)',
        backgroundImage: `url(${act.id + '.webp'})`,
      }
  return (
    <>
      <div
        className={clsx(
          'relative isolate flex items-center justify-center rounded-sm border border-black bg-white text-center font-semibold shadow-lg',
          durationInMinutes < 60 ? 'break-all' : 'break-words'
        )}
        style={{
          marginLeft: marginLeft,
          width: width,
        }}
      >
        <button
          onClick={() => setIsTimetableItemOpen(!isTimetableItemOpen)}
          className="absolute inset-0 h-full w-full"
        />
        <div className="pointer-events-none absolute inset-0 bg-cover bg-right" style={actBackgroundStyles} />
        <span className={clsx('pointer-events-none z-10 bg-white', durationInMinutes < 60 && 'text-xs')}>
          {act.name}
        </span>
        <span className="absolute bottom-1 right-1 flex gap-1">
          {interestedFriendsCount > 0 && (
            <FriendCounterBadge
              className="pointer-events-none select-none self-center rounded-sm px-1 py-0 text-xs leading-4"
              count={interestedFriendsCount}
            />
          )}
          <button
            onClick={(e) => {
              e.stopPropagation()
              useStore.getState().toggleArtistConfiguration(act.id)
            }}
          >
            <ArtistStar className="h-4 w-4" isLiked={artistConfiguration} />
          </button>
        </span>
      </div>
      <ArtistDetailPopup artist={act} isOpen={isTimetableItemOpen} onClose={() => setIsTimetableItemOpen(false)} />
    </>
  )
}

function StageRow({
  acts,
  startingHour,
  stage,
  validatedPixelsPerMinute,
}: {
  acts: Act[]
  startingHour: Date
  stage: string
  validatedPixelsPerMinute: number
}) {
  const sortedActs = [...acts].sort((a, b) => a.startTime.getTime() - b.startTime.getTime())

  return (
    <div className={clsx(`h-[${HEIGHT_IN_PX_PER_STAGE}px]`)} style={{ height: HEIGHT_IN_PX_PER_STAGE }}>
      <p className="sticky left-2 inline-block h-8 font-mono text-2xl font-bold tracking-tight">{stage}</p>
      <div className="flex h-24 items-stretch">
        {sortedActs.map((act, index, array) => {
          const durationInMinutes = differenceInMinutes(act.endTime, act.startTime)
          const widthInPixels = durationInMinutes * validatedPixelsPerMinute

          let marginLeftStyles: number
          if (index === 0) {
            marginLeftStyles = differenceInMinutes(act.startTime, startingHour) * validatedPixelsPerMinute
          } else {
            marginLeftStyles = differenceInMinutes(act.startTime, array[index - 1]!.endTime) * validatedPixelsPerMinute
          }

          return (
            <TimetableItem
              key={act.id}
              act={act}
              durationInMinutes={durationInMinutes}
              marginLeft={marginLeftStyles}
              width={widthInPixels}
            />
          )
        })}
      </div>
    </div>
  )
}

function TimeFooter({ hours, validatedPixelsPerMinute }: { hours: Date[]; validatedPixelsPerMinute: number }) {
  return (
    <div
      className={clsx(
        `h-[${TIME_FOOTER_HEIGHT}px] z-20`,
        'sticky bottom-0 left-0 border-b border-black bg-white',
        'shadow-lg',
        'select-none',
        'font-mono'
      )}
      style={{ height: TIME_FOOTER_HEIGHT }}
    >
      {hours.map((hour, index) => {
        const isFirstHour = index === 0
        const isFirstHourOrNextDay = isFirstHour || hour.getHours() === 0
        const hourString = isFirstHourOrNextDay
          ? format(hour, 'dd LLL')
          : hour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

        let marginStyles = ''

        if (isFirstHour) {
          marginStyles = 'ml-0'
        } else if (hour.getHours() === 0) {
          marginStyles = 'ml-0'
        } else {
          marginStyles = '-ml-6'
        }

        return (
          <div
            className={clsx('px inline-block py-2', isFirstHour ? 'border-l-0' : 'border-l border-l-black')}
            key={hour.getTime()}
            style={{ width: validatedPixelsPerMinute * 60 }}
          >
            <span className={clsx(marginStyles, isFirstHourOrNextDay ? 'bg-black text-white' : 'bg-white text-black')}>
              {hourString}
            </span>
          </div>
        )
      })}
    </div>
  )
}

interface TimetableProps {
  acts: Act[]
  isFullScreen: boolean
  closeFullScreen: () => void
}

function Timetable({ acts, isFullScreen, closeFullScreen }: TimetableProps) {
  if (acts.length === 0) {
    return (
      <div className="m-8 flex flex-col items-center justify-center rounded-md bg-gradient-to-r from-yellow-500 to-red-500 p-8">
        <div className="w-full rounded-md bg-white p-8 text-center text-black">
          <h3 className="mb-4 text-xl font-semibold">No acts found with these current filters</h3>
          <p className="text-md font-medium">Please adjust your filters to build a timetable</p>
        </div>
      </div>
    )
  }

  const pixelsPerMinute = useStore((state) => state.pixelsPerMinute)
  let validatedPixelsPerMinute: number

  if (pixelsPerMinute > 1 && pixelsPerMinute < 7) {
    validatedPixelsPerMinute = pixelsPerMinute
  } else {
    validatedPixelsPerMinute = 2
  }

  const earliestStartTime = acts.reduce(
    (earliest: Date, act: Act) => (act.startTime < earliest ? act.startTime : earliest),
    acts[0]!.startTime
  )

  const latestEndTime = acts.reduce(
    (latest: Date, act: Act) => (act.endTime > latest ? act.endTime : latest),
    acts[0]!.endTime
  )

  const startingHour = sub(roundToNearestHours(earliestStartTime, { roundingMethod: 'floor' }), { hours: 1 })
  const endingHour = roundToNearestHours(latestEndTime, { roundingMethod: 'ceil' })

  if (isBefore(endingHour, startingHour)) {
    throw new Error('Ending hour is before starting hour')
  }

  const hoursBetween = eachHourOfInterval({ start: startingHour, end: endingHour })
  const totalDurationInMinutes = hoursBetween.length * 60

  const stages = Array.from(dtrhStages)
  const actsByStage = stages.map((stage) => acts.filter((act) => act.stage === stage)).filter((acts) => acts.length > 0)

  const timetableContainerWidth = totalDurationInMinutes * validatedPixelsPerMinute
  const timetableContainerHeight = actsByStage.length * HEIGHT_IN_PX_PER_STAGE + TIME_HEADER_HEIGHT + TIME_FOOTER_HEIGHT

  return (
    <>
      <div
        className={twMerge(
          'relative isolate max-h-screen w-full overflow-auto rounded-md border border-black',
          isFullScreen && 'fixed left-0 top-0 z-40 h-full w-full bg-white'
        )}
        style={{ maxHeight: isFullScreen ? '100dvh' : maxTimetableHeight }}
      >
        <div
          className={clsx(patternClassNames, 'min-w-full')}
          style={{ width: timetableContainerWidth, height: timetableContainerHeight }}
        >
          <TimeHeader hours={hoursBetween} validatedPixelsPerMinute={validatedPixelsPerMinute} />
          {actsByStage.map((acts) => (
            <StageRow
              key={acts[0]!.stage}
              acts={acts}
              stage={acts[0]!.stage}
              startingHour={startingHour}
              validatedPixelsPerMinute={validatedPixelsPerMinute}
            />
          ))}
          <TimeFooter hours={hoursBetween} validatedPixelsPerMinute={validatedPixelsPerMinute} />
        </div>
      </div>
      {isFullScreen && (
        <Button
          className="fixed right-4 top-8 z-50 border-black bg-black text-white shadow-lg hover:bg-yellow-500 sm:right-8"
          onClick={closeFullScreen}
        >
          <ArrowsPointingOutIcon className="h-10 w-10" />
        </Button>
      )}
    </>
  )
}

export default Timetable
