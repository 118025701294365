import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ArrowsUpDownIcon, CheckIcon } from '@heroicons/react/24/outline'
import React from 'react'

export const sortingOptions = ['Alphabetical', 'Stage', 'Time'] as const

export type SortingOption = 'Alphabetical' | 'Stage' | 'Time'

export default function SortingMenu({
  selectedSorting,
  setSelectedSorting,
}: {
  selectedSorting: SortingOption
  setSelectedSorting: (option: SortingOption) => void
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="mt-[2px] inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <ArrowsUpDownIcon className="h-4 w-4 sm:h-5 sm:w-5" />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="flex flex-col gap-1 py-1">
          {sortingOptions.map((option, index) => (
            <MenuItem
              as="button"
              className="flex items-center gap-2 rounded-md px-3 py-2 text-left text-sm text-gray-900 hover:bg-gray-100 hover:text-gray-900"
              key={index}
              onClick={() => setSelectedSorting(option as SortingOption)}
            >
              <div className="flex h-5 w-5 items-center justify-between">
                {selectedSorting === option && <CheckIcon className="h-5 w-5 text-yellow-600" />}
              </div>
              {option}
            </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  )
}
