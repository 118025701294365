import {
  ClockIcon,
  CloudIcon,
  GlobeAltIcon,
  LockClosedIcon,
  Squares2X2Icon,
  UserGroupIcon,
} from '@heroicons/react/24/solid'
import React from 'react'
import { NavLink } from 'react-router-dom'
import Header from '~/components/header'

function HeaderComp() {
  const links = [
    { name: 'Program', href: '/program' },
    { name: 'Timetable', href: '/timetable' },
    { name: 'Friends', href: '/friends' },
  ]
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <div className="absolute inset-0 -z-10 h-full w-full bg-gradient-to-r from-yellow-500 to-red-500 object-cover object-right md:object-center" />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Better Timetable</h1>
          <p className="mt-6 text-lg leading-8 text-white">
            Get a better and more social timetable experience for Down The Rabbit Hole 2024.
            <br />
            Works fully offline. Even at the Hotot.
            <br />
            <br />
            <b>No account, ads or tracking.</b>
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
            {links.map((link) => (
              <NavLink key={link.name} to={link.href}>
                {link.name} <span aria-hidden="true">&rarr;</span>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
function HowDoesThisWork() {
  const features = [
    {
      name: 'Program',
      description:
        "The program page lists all the acts in a grid, and allows you to filter by name, or stage. This is a great place to get started and find out what you'd like to see. Find something you want to see? Hit the yellow star to save it to your selection.",
      icon: Squares2X2Icon,
      href: '/program',
      linkText: 'Go to program',
    },
    {
      name: 'Timetable',
      description:
        'The timetable page should look familiar - time goes horizontal, stages go vertical. You can mangle the timetable to show you everything for a specific day, or just the things that you or your friends want to see.',
      icon: ClockIcon,
      href: '/timetable',
      linkText: 'Go to timetable',
    },
    {
      name: 'Friends',
      description:
        'On the friends page, you can share your own configuration with your friends, and import theirs (I hope they have good taste). If a user makes a change to their selections, you will have to reimport their selections.',
      icon: UserGroupIcon,
      href: '/friends',
      linkText: 'Go to friends',
    },
  ]
  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How does this work?</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Better Timetable allows you to peruse the Down The Rabbit Hole 2024 lineup in a more social and interactive
            way. Find the acts you want to see, like them, and share your timetable code with your friends.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon className="h-5 w-5 flex-none text-yellow-500" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <NavLink to={feature.href} className="text-sm font-semibold leading-6 text-yellow-500">
                      {feature.linkText} <span aria-hidden="true">→</span>
                    </NavLink>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

function WhyUseThis() {
  const features = [
    {
      name: 'No app required',
      description:
        'Better Timetable works in any modern browser, no need to install anything. Just visit the website and you are good to go. For those who would like an icon on their home screen, you can add a bookmark to your home screen.',
      icon: GlobeAltIcon,
    },
    {
      name: 'No account needed',
      description:
        'Forgot your Ticketmaster password again? We leverage browser APIs to store your configuration. After the festival, you can forget about this app and we dont have to process and sell your personal details. Win win.',
      icon: LockClosedIcon,
    },
    {
      name: 'Offline ready',
      description:
        'No internet connection? No problem. Better Timetable works fully offline. Just visit the website once and you are good to go.',
      icon: CloudIcon,
    },
  ]
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Why use this?</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We get it. Who needs another app or account to manage?
            <br />
            Better Timetable is a simple web app that works in any modern browser.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <feature.icon className="h-5 w-5 flex-none text-yellow-500" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

function Footer() {
  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Thanks for your interest in BTT!
          </h2>
          <h3 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            We are only getting started.
          </h3>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            This version of BTT is a proof of concept designed specifically for DTRH2024. We are working on a more
            polished version with more features that can be festival agnostic.
          </p>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            If you have some feedback or would like to treat us and buy us a <i>grote bier</i>, use the following links
            to do so:
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeLY6QNoQv0fS1zyir2VtD8k0m3ieHh_-ZwOV_EsCnbKhgIaw/viewform?usp=sf_link"
              className="rounded-md bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
              target="_blank"
            >
              Leave feedback
            </a>
            <a
              href="https://buymeacoffee.com/bettertimetable"
              className="text-sm font-semibold leading-6 text-gray-900"
              target="_blank"
            >
              Buy us a <i>Grote Bier</i> 🍺 <span aria-hidden="true">→</span>
            </a>
          </div>
          <h3 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">✌️</h3>
        </div>
      </div>
    </div>
  )
}

export default function Home() {
  return (
    <>
      <Header />
      <HeaderComp />
      <div className="mt-24 flex items-center justify-between gap-x-6 px-6 sm:justify-center">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeLY6QNoQv0fS1zyir2VtD8k0m3ieHh_-ZwOV_EsCnbKhgIaw/viewform?usp=sf_link"
          className="rounded-md bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
          target="_blank"
        >
          Leave feedback
        </a>
        <a
          href="https://buymeacoffee.com/bettertimetable"
          className="text-sm font-semibold leading-6 text-gray-900"
          target="_blank"
        >
          Buy us a <i>Grote Bier</i> 🍺 <span aria-hidden="true">→</span>
        </a>
      </div>
      <HowDoesThisWork />
      <WhyUseThis />
      <Footer />
    </>
  )
}
