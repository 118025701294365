import React from 'react'
import { twMerge } from 'tailwind-merge'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
}

export default function Button({ children, onClick, className, ...props }: ButtonProps) {
  return (
    <button
      type="button"
      className={twMerge(
        'rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}
