import { z } from 'zod'

import acts from './acts.json'

export const dtrhStages = [
  'hotot',
  'teddy widder',
  'fuzzy lop',
  'rex',
  'the bizarre',
  'bossa nova',
  'the croque madame',
  'idyllische veldje',
  'bermuda air',
  'tramonta',
  'desperados',
]

export type DTRHStageName = (typeof dtrhStages)[number]

export const dtrhDays = ['2024-07-05', '2024-07-06', '2024-07-07'] as const

export type Day = (typeof dtrhDays)[number] | 'all'

export const dayPickerDays: {
  [key in Day]: { label: string; value: key }
} = {
  all: { label: 'All days', value: 'all' },
  '2024-07-05': { label: 'Friday', value: '2024-07-05' },
  '2024-07-06': { label: 'Saturday', value: '2024-07-06' },
  '2024-07-07': { label: 'Sunday', value: '2024-07-07' },
} as const

export const dayPickerOptions = Object.keys(dayPickerDays)

export type DTRHDay = (typeof dtrhDays)[number]

export type SocialPlatform = 'facebook' | 'instagram' | 'twitter' | 'spotify' | 'soundcloud' | 'website'

export type ExternalLink = string

export interface DTRHAct {
  id: number
  description?: string
  socials?: Record<SocialPlatform, ExternalLink>
  name: string
  startTime: Date
  endTime: Date
  stage: DTRHStageName
}

export const dtrhDayBounds: { start: Date; end: Date }[] = [
  {
    start: new Date('2024-07-05T08:00:00Z'), // 10:00 AM in Amsterdam is 08:00 AM UTC
    end: new Date('2024-07-06T02:30:00Z'), // 04:30 AM in Amsterdam is 02:30 AM UTC
  },
  {
    start: new Date('2024-07-06T08:00:00Z'), // 10:00 AM in Amsterdam is 08:00 AM UTC
    end: new Date('2024-07-07T02:30:00Z'), // 04:30 AM in Amsterdam is 02:30 AM UTC
  },
  {
    start: new Date('2024-07-07T08:00:00Z'), // 10:00 AM in Amsterdam is 08:00 AM UTC
    end: new Date('2024-07-08T02:30:00Z'), // 04:30 AM in Amsterdam is 02:30 AM UTC
  },
]

export const actSchema = z.object({
  id: z.number(),
  description: z.string().optional(),
  socials: z.record(z.string()).optional(),
  name: z.string(),
  startTime: z.coerce.date(),
  endTime: z.coerce.date(),
  stage: z.union([
    z.literal('hotot'),
    z.literal('teddy widder'),
    z.literal('fuzzy lop'),
    z.literal('rex'),
    z.literal('the bizarre'),
    z.literal('bossa nova'),
    z.literal('the croque madame'),
    z.literal('idyllische veldje'),
    z.literal('bermuda air'),
    z.literal('tramonta'),
    z.literal('desperados'),
  ]),
  imgSrc: z.string().url(),
})

export type Act = z.infer<typeof actSchema>

export const allActs = acts.map((act) => actSchema.parse(act))

export const dtrhBitfieldSize = allActs.length
