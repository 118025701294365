import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { dtrhBitfieldSize, type DTRHDay } from '~/data/dtrh2024'
import {
  friendToLocalStorageFriend,
  localStorageFriendsToFriends,
  mockFriends,
  type Friend,
  type LocalStorageFriend,
} from '~/entities/friend'
import { BitField } from '~/lib/bitfield'

interface LocalStore {
  compactMode: boolean
  setCompactMode: (value: boolean) => void
  hidePhotos: boolean
  setHidePhotos: (value: boolean) => void

  getPersonalBitField: () => BitField
  personalConfigurationString: string
  setPersonalConfigurationString: (value: string) => void
  setArtistConfiguration: (artistId: number, value: boolean) => void
  toggleArtistConfiguration: (artistId: number) => void
  getArtistConfiguration: (artistId: number) => boolean

  localStorageFriends: LocalStorageFriend[]
  getFriends: () => Friend[]
  getInterestedFriends: (artistId: number) => Friend[]
  getInterestedFriendsCount: (artistId: number) => number
  addFriend: (friend: Friend) => void
  removeFriend: (friend: Friend) => void
  removeAllFriends: () => void

  // Timetable config
  showOnlyConfiguredActs: boolean
  setShowOnlyConfiguredActs: (value: boolean) => void
  showOnlyFriendlyConfiguredActs: boolean
  setShowOnlyFriendlyConfiguredActs: (value: boolean) => void
  filterBehaviour: 'union' | 'intersection'
  setFilterBehaviour: (value: 'union' | 'intersection') => void
  timetableDayFilter: 'all' | DTRHDay
  setTimeTableDayFilter: (value: 'all' | DTRHDay) => void
  pixelsPerMinute: number
  setPixelsPerMinute: (value: number) => void

  // Progam config
  programDayFilter: 'all' | DTRHDay
  setProgramDayFilter: (value: 'all' | DTRHDay) => void
  programShowOnlyConfiguredActs: boolean
  setProgramShowOnlyConfiguredActs: (value: boolean) => void
  programShowOnlyFriendlyConfiguredActs: boolean
  setProgramShowOnlyFriendlyConfiguredActs: (value: boolean) => void
  programFilterBehaviour: 'union' | 'intersection'
  setProgramFilterBehaviour: (value: 'union' | 'intersection') => void
}

export const useStore = create<LocalStore>()(
  persist(
    (set, get) => ({
      compactMode: false,
      setCompactMode: (value) => set({ compactMode: value }),
      hidePhotos: false,
      setHidePhotos: (value) => set({ hidePhotos: value }),
      personalConfigurationString: new BitField(dtrhBitfieldSize).asBase64Url(),
      getPersonalBitField: (): BitField => BitField.fromBase64Url(get().personalConfigurationString, dtrhBitfieldSize),
      setPersonalConfigurationString: (value) => set({ personalConfigurationString: value }),
      setArtistConfiguration: (artistId, value) => {
        const bitField = get().getPersonalBitField()
        bitField.set(artistId, value)
        set({ personalConfigurationString: bitField.asBase64Url() })
      },
      toggleArtistConfiguration: (artistId) => {
        const bitField = get().getPersonalBitField()
        bitField.set(artistId, !bitField.get(artistId))
        set({ personalConfigurationString: bitField.asBase64Url() })
      },
      getArtistConfiguration: (artistId) => get().getPersonalBitField().get(artistId),

      localStorageFriends: mockFriends,
      getFriends: () => localStorageFriendsToFriends(get().localStorageFriends),
      getInterestedFriends: (artistId) =>
        get()
          .getFriends()
          .filter((friend) => friend.bitField.get(artistId)),
      getInterestedFriendsCount: (artistId) => get().getInterestedFriends(artistId).length,
      addFriend: (friend) =>
        set({
          localStorageFriends: [...get().localStorageFriends, friendToLocalStorageFriend(friend)],
        }),
      removeFriend: (friend) =>
        set((state) => ({
          localStorageFriends: state.localStorageFriends.filter((f) => f.name !== friend.name),
        })),
      removeAllFriends: () => set({ localStorageFriends: [] }),

      showOnlyConfiguredActs: false,
      setShowOnlyConfiguredActs: (value) => set({ showOnlyConfiguredActs: value }),
      showOnlyFriendlyConfiguredActs: false,
      setShowOnlyFriendlyConfiguredActs: (value) => set({ showOnlyFriendlyConfiguredActs: value }),
      filterBehaviour: 'union',
      setFilterBehaviour: (value) => set({ filterBehaviour: value }),
      timetableDayFilter: 'all',
      setTimeTableDayFilter: (value) => set({ timetableDayFilter: value }),
      pixelsPerMinute: 2,
      setPixelsPerMinute: (value) => set({ pixelsPerMinute: value }),

      // Program
      programDayFilter: 'all',
      setProgramDayFilter: (value) => set({ programDayFilter: value }),
      programShowOnlyConfiguredActs: false,
      setProgramShowOnlyConfiguredActs: (value) => set({ programShowOnlyConfiguredActs: value }),
      programShowOnlyFriendlyConfiguredActs: false,
      setProgramShowOnlyFriendlyConfiguredActs: (value) => set({ programShowOnlyFriendlyConfiguredActs: value }),
      programFilterBehaviour: 'union',
      setProgramFilterBehaviour: (value) => set({ programFilterBehaviour: value }),
    }),
    {
      name: 'better-timetable', // name of the item in the storage (must be unique)
    }
  )
)
