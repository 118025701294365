import clsx from 'clsx'
import { format } from 'date-fns'
import React, { useState } from 'react'
import type { DTRHAct } from '~/data/dtrh2024'
import { useStore } from '~/store'
import ArtistDetailPopup from './artist-detail-pop'
import { ArtistStar } from './artist-star'
import FriendCounterBadge from './friend-counter-badge'
import { PageContainer } from './page-container'

function ArtistGridItem({ act, compactMode, isLiked }: { act: DTRHAct; compactMode: boolean; isLiked: boolean }) {
  const [isDetailPopupOpen, setIsDetailPopupOpen] = useState(false)
  const toggleArtistConfiguration = useStore((state) => state.toggleArtistConfiguration)
  const hidePhotos = useStore((state) => state.hidePhotos)
  const interestedFriends = useStore((state) => state.getInterestedFriends(act.id))
  const timeString = `${format(act.startTime, 'eeee HH:mm')} - ${format(act.endTime, 'HH:mm')}`

  const backgroundImagesStyles = hidePhotos
    ? {}
    : {
        backgroundImage: `url(${act.id}.webp)`,
        backgroundSize: 'cover',
      }

  const artistStarStyles = hidePhotos ? 'rounded-md bg-black p-1' : ''

  return (
    <>
      <ArtistDetailPopup artist={act} isOpen={isDetailPopupOpen} onClose={() => setIsDetailPopupOpen(false)} />
      <li
        className={clsx(
          'relative flex h-[300px] justify-between gap-2 bg-gradient-to-r from-yellow-500 to-red-500 bg-center p-8 bg-blend-overlay shadow-md',
          compactMode ? 'h-auto rounded-md px-8 py-4' : 'h-[300px] rounded-xl p-8',
          compactMode ? 'flex-row-reverse items-center' : 'flex-col'
        )}
        style={backgroundImagesStyles}
      >
        <button onClick={() => setIsDetailPopupOpen(!isDetailPopupOpen)} className="absolute inset-0 h-full w-full" />
        <div
          className={clsx(
            'flex justify-between',
            compactMode ? 'flex-row-reverse items-start gap-2 leading-6' : 'flex-row items-center gap-4'
          )}
        >
          <button
            className={clsx(
              'z-1 relative',
              compactMode ? '-mr-8 -mt-4 pb-2 pl-2 pr-8 pt-4' : '-ml-8 -mt-8 pb-2 pl-8 pr-2 pt-8'
            )}
            onClick={() => toggleArtistConfiguration(act.id)}
          >
            <ArtistStar className={artistStarStyles} isLiked={isLiked} />
          </button>
          {interestedFriends.length > 0 && (
            <FriendCounterBadge count={interestedFriends.length} compactMode={compactMode} />
          )}
        </div>
        <div className={clsx('flex flex-col gap-2', compactMode && 'flex-row-reverse')}>
          <h3 className="text-lg font-semibold leading-6 text-white md:text-xl">{act.name}</h3>
          {!compactMode && (
            <div className="flex flex-col gap-2 md:items-center md:justify-between">
              <p className="self-start bg-black text-sm font-semibold leading-5 text-white md:text-base">
                {timeString}
              </p>
              <p className="self-start bg-black text-sm font-semibold leading-5 text-white md:text-base">{act.stage}</p>
            </div>
          )}
        </div>
      </li>
    </>
  )
}

export default function ArtistGrid({ acts, compactMode }: { acts: DTRHAct[]; compactMode: boolean }) {
  const bitField = useStore((state) => state.getPersonalBitField())

  if (acts.length === 0) {
    return (
      <PageContainer>
        <div className="m-8 flex flex-col items-center justify-center rounded-md bg-gradient-to-r from-yellow-500 to-red-500 p-8">
          <div className="w-full rounded-md bg-white p-8 text-center text-black">
            <h3 className="mb-4 text-xl font-semibold">No acts found with these current filters</h3>
            <p className="text-md font-medium">Please adjust your filters to build a program grid</p>
          </div>
        </div>
      </PageContainer>
    )
  }

  return (
    <ul
      className={clsx(
        'mx-auto grid max-w-7xl grid-cols-1 px-4 sm:grid-cols-2 sm:px-6 md:grid-cols-3 lg:px-8 xl:grid-cols-4',
        compactMode ? 'gap-2' : 'gap-4'
      )}
    >
      {acts.map((act) => (
        <ArtistGridItem compactMode={compactMode} isLiked={bitField.get(act.id)} key={act.id} act={act} />
      ))}
    </ul>
  )
}
