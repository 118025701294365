import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowsPointingOutIcon,
  Cog8ToothIcon,
  PhotoIcon as OutlinePhotoIcon,
} from '@heroicons/react/24/outline'
import { PhotoIcon as SolidPhotoIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { ArtistStar } from '~/components/artist-star'
import Button from '~/components/button'
import FilterBehaviourIcon from '~/components/filter-behaviour-icon'
import { FriendGroupIcon } from '~/components/friend-group-icon'
import Header from '~/components/header'
import { PageContainer } from '~/components/page-container'
import PageTitle from '~/components/page-title'
import Sidebar from '~/components/sidebar'
import Timetable from '~/components/timetable'
import { dayPickerDays, dtrhStages, type Day } from '~/data/dtrh2024'
import { useFilteredActs } from '~/hooks/useFilteredActs'
import { useStore } from '~/store'

function TimetableSettingsSidebar({ isOpen, closeSidebar }: { isOpen: boolean; closeSidebar: () => void }) {
  const {
    hidePhotos,
    setHidePhotos,
    showOnlyConfiguredActs,
    showOnlyFriendlyConfiguredActs,
    setShowOnlyConfiguredActs,
    setShowOnlyFriendlyConfiguredActs,
    filterBehaviour,
    setFilterBehaviour,
    pixelsPerMinute,
    setPixelsPerMinute,
  } = useStore((state) => ({
    hidePhotos: state.hidePhotos,
    setHidePhotos: state.setHidePhotos,
    filterBehaviour: state.filterBehaviour,
    setFilterBehaviour: state.setFilterBehaviour,
    showOnlyConfiguredActs: state.showOnlyConfiguredActs,
    showOnlyFriendlyConfiguredActs: state.showOnlyFriendlyConfiguredActs,
    setShowOnlyConfiguredActs: state.setShowOnlyConfiguredActs,
    setShowOnlyFriendlyConfiguredActs: state.setShowOnlyFriendlyConfiguredActs,
    pixelsPerMinute: state.pixelsPerMinute,
    setPixelsPerMinute: state.setPixelsPerMinute,
  }))

  return (
    <Sidebar isOpen={isOpen} closeSidebar={closeSidebar}>
      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-semibold">Timetable settings</h2>
        <div className="flex flex-col gap-4">
          <h3 className="font-semibold">Filter acts from the timetable</h3>
          <p className="text-sm">
            Filter the timetable to show only acts you have liked or that your friends have liked.
          </p>
          <div className="flex items-center justify-between gap-2">
            <p className="text-sm font-medium">Show acts you have liked</p>
            <Button onClick={() => setShowOnlyConfiguredActs(!showOnlyConfiguredActs)}>
              <ArtistStar className="h-5 w-5" isLiked={showOnlyConfiguredActs} />
            </Button>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium">Show acts your friends have liked</p>
            <Button onClick={() => setShowOnlyFriendlyConfiguredActs(!showOnlyFriendlyConfiguredActs)}>
              <FriendGroupIcon className="h-5 w-5" isActive={showOnlyFriendlyConfiguredActs} />
            </Button>
          </div>
        </div>

        {showOnlyConfiguredActs && showOnlyFriendlyConfiguredActs && (
          <div className="flex flex-col gap-4">
            <h3 className="font-semibold">Choose filter behaviour</h3>
            <p className="text-sm">
              When showing both your and your friends selected acts, choose to show acts <i>both</i> you and at least
              one of your friends have chosen or show all the acts that you or your friends have chosen.
            </p>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium">Filter behaviour</p>
              <Button
                onClick={() => setFilterBehaviour(filterBehaviour === 'intersection' ? 'union' : 'intersection')}
                title="Set intersection mode"
              >
                <FilterBehaviourIcon className="h-5 w-5" filterBehaviour={filterBehaviour} />
              </Button>
            </div>
          </div>
        )}

        <div className="flex flex-col gap-4">
          <h3 className="font-semibold">Pixels per minute</h3>
          <p className="text-sm">Choose how wide we should build your timetable. The default is 2 pixels per minute.</p>
          <p className="text-xs text-gray-500">
            More pixels means more room for abnormally long act names. Looking at you "Echoes: Wes Lee, MODE, Phantom
            Wizard & Sydney Lowell"
          </p>
          <div className="flex items-center justify-between gap-2">
            <p className="text-sm font-medium">Pixels per minute</p>
            <div className="flex items-center gap-2">
              <span className="font-mono font-semibold">{pixelsPerMinute}</span>
              <input
                className="accent-yellow-500"
                type="range"
                min="2"
                max="6"
                value={pixelsPerMinute}
                onChange={(e) => setPixelsPerMinute(Number(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <h3 className="font-semibold">Show images</h3>
          <p className="text-sm">
            Choose if you want to show the artist images inside the program grid or in the timetable.{' '}
          </p>
          <p className="text-xs text-gray-500">Images are always shown when opening the details popup.</p>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium">Show/hide artist images</p>
            <Button onClick={() => setHidePhotos(!hidePhotos)} title="Toggle showing or hiding images">
              {hidePhotos ? (
                <OutlinePhotoIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              ) : (
                <SolidPhotoIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </Sidebar>
  )
}

function DayPicker({ selectedDay, setSelectedDay }: { selectedDay: Day; setSelectedDay: (day: Day) => void }) {
  const previousDay = () => {
    if (selectedDay === 'all') {
      setSelectedDay('2024-07-07')
    } else if (selectedDay === '2024-07-07') {
      setSelectedDay('2024-07-06')
    } else if (selectedDay === '2024-07-06') {
      setSelectedDay('2024-07-05')
    } else if (selectedDay === '2024-07-05') {
      setSelectedDay('all')
    }
  }

  const nextDay = () => {
    if (selectedDay === 'all') {
      setSelectedDay('2024-07-05')
    } else if (selectedDay === '2024-07-05') {
      setSelectedDay('2024-07-06')
    } else if (selectedDay === '2024-07-06') {
      setSelectedDay('2024-07-07')
    } else if (selectedDay === '2024-07-07') {
      setSelectedDay('all')
    }
  }

  return (
    <div className="flex items-center gap-x-4">
      <Button onClick={() => previousDay()} title="Previous day">
        <ArrowLeftIcon className="h-4 w-4 sm:h-5 sm:w-5" />
      </Button>
      <p className="select-none text-lg font-semibold tracking-tight sm:text-2xl">{dayPickerDays[selectedDay].label}</p>
      <Button onClick={() => nextDay()} title="Next day">
        <ArrowRightIcon className="h-4 w-4 sm:h-5 sm:w-5" />
      </Button>
    </div>
  )
}

export default function TimetablePage() {
  const {
    showOnlyConfiguredActs,
    showOnlyFriendlyConfiguredActs,
    setShowOnlyConfiguredActs,
    setShowOnlyFriendlyConfiguredActs,
    filterBehaviour,
    setFilterBehaviour,
    timetableDayFilter,
    setTimeTableDayFilter,
  } = useStore((state) => ({
    hidePhotos: state.hidePhotos,
    setHidePhotos: state.setHidePhotos,
    filterBehaviour: state.filterBehaviour,
    setFilterBehaviour: state.setFilterBehaviour,
    showOnlyConfiguredActs: state.showOnlyConfiguredActs,
    showOnlyFriendlyConfiguredActs: state.showOnlyFriendlyConfiguredActs,
    setShowOnlyConfiguredActs: state.setShowOnlyConfiguredActs,
    setShowOnlyFriendlyConfiguredActs: state.setShowOnlyFriendlyConfiguredActs,
    timetableDayFilter: state.timetableDayFilter,
    setTimeTableDayFilter: state.setTimeTableDayFilter,
  }))
  const { filteredActs, filters, setFilters } = useFilteredActs({
    likedFilterBehavior: filterBehaviour,
    liked: showOnlyConfiguredActs,
    likedByFriends: showOnlyFriendlyConfiguredActs,
    stage: dtrhStages,
  })

  const [isSettingsSidebarOpen, setIsSettingsSidebarOpen] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    setFilters({
      ...filters,
      liked: showOnlyConfiguredActs,
    })
  }, [showOnlyConfiguredActs])

  useEffect(() => {
    setFilters({
      ...filters,
      likedByFriends: showOnlyFriendlyConfiguredActs,
    })
  }, [showOnlyFriendlyConfiguredActs])

  useEffect(() => {
    setFilters({
      ...filters,
      likedFilterBehavior: filterBehaviour,
    })
  }, [filterBehaviour])

  useEffect(() => {
    setFilters({
      ...filters,
      day: timetableDayFilter === 'all' ? undefined : timetableDayFilter,
    })
  }, [timetableDayFilter])
  return (
    <>
      <Header>
        <PageTitle>Timetable</PageTitle>
      </Header>
      <PageContainer className="px-0 pb-0 sm:px-0 lg:px-8">
        <div className="mb-4 flex w-full items-center justify-between px-4 sm:px-6 lg:px-0">
          <PageTitle className="hidden sm:block">Timetable</PageTitle>
          <div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
            <DayPicker selectedDay={timetableDayFilter} setSelectedDay={setTimeTableDayFilter} />
            <Button className="hidden md:block" onClick={() => setShowOnlyConfiguredActs(!showOnlyConfiguredActs)}>
              <ArtistStar className="h-5 w-5" isLiked={showOnlyConfiguredActs} />
            </Button>
            <Button
              className="hidden md:block"
              onClick={() => setShowOnlyFriendlyConfiguredActs(!showOnlyFriendlyConfiguredActs)}
            >
              <FriendGroupIcon className="h-5 w-5" isActive={showOnlyFriendlyConfiguredActs} />
            </Button>
            <Button
              className="hidden md:block"
              onClick={() => setFilterBehaviour(filterBehaviour === 'intersection' ? 'union' : 'intersection')}
              title="Set intersection mode"
            >
              <FilterBehaviourIcon className="h-5 w-5" filterBehaviour={filterBehaviour} />
            </Button>
            <div className="flex items-center gap-4 sm:gap-2">
              <Button onClick={() => setIsSettingsSidebarOpen(!isSettingsSidebarOpen)}>
                <Cog8ToothIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              </Button>
              <Button onClick={() => setIsFullScreen(!isFullScreen)}>
                <ArrowsPointingOutIcon className="h-4 w-4 sm:h-5 sm:w-5" />
              </Button>
            </div>
          </div>
        </div>
        <Timetable acts={filteredActs} isFullScreen={isFullScreen} closeFullScreen={() => setIsFullScreen(false)} />
        <TimetableSettingsSidebar isOpen={isSettingsSidebarOpen} closeSidebar={() => setIsSettingsSidebarOpen(false)} />
      </PageContainer>
    </>
  )
}
