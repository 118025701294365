import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import React from 'react'
import { NavLink } from 'react-router-dom'

const routes = [
  { name: 'Home', href: '/' },
  { name: 'Friends', href: '/friends' },
  { name: 'Program', href: '/program' },
  { name: 'Timetable', href: '/timetable' },
]

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'

export default function NewHeader({ children }: { children?: React.ReactNode }) {
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                {children && <div className="ml-auto sm:hidden">{children}</div>}
                <NavLink to={'/'} className="ml-auto mr-2 flex flex-shrink-0 items-center sm:ml-0 sm:mr-0">
                  <img alt="Better Timetable logo" className="h-8 w-auto" src="logo.svg" />
                </NavLink>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {routes.map((route) => (
                    <NavLink
                      key={route.href}
                      to={route.href}
                      className={({ isActive }) =>
                        clsx(
                          'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
                          isActive
                            ? 'border-yellow-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )
                      }
                    >
                      {route.name}
                    </NavLink>
                  ))}
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"></div>
            </div>
          </div>

          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2">
              {routes.map((r) => (
                <NavLink
                  key={r.href}
                  to={r.href}
                  className={({ isActive }) =>
                    clsx(
                      'block border-l-4 px-3 py-2 text-base font-medium',
                      isActive
                        ? 'border-yellow-500 bg-yellow-50 text-yellow-700'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'
                    )
                  }
                >
                  {r.name}
                </NavLink>
              ))}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  )
}
