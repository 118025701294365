import React from 'react'
import { twMerge } from 'tailwind-merge'

interface PageContainerProps {
  className?: string
  children: React.ReactNode
}

export function PageContainer({ children, className = '' }: PageContainerProps) {
  return (
    <main
      className={twMerge(
        'mx-auto flex w-full max-w-7xl flex-col items-center justify-center gap-4 px-4 py-8 sm:px-6 lg:px-8',
        className
      )}
    >
      {children}
    </main>
  )
}
