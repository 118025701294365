import React from 'react'

type FilterBehaviour = 'union' | 'intersection'
interface FilterBehaviourIconProps {
  className?: string
  filterBehaviour: FilterBehaviour
}

export default function FilterBehaviourIcon({ className = '', filterBehaviour }: FilterBehaviourIconProps) {
  if (filterBehaviour === 'union') {
    return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path fill="#eab308" fill-opacity="1" fill-rule="nonzero" stroke="none" d="M17 17V6h-5V1H1v11h5v5h11z"></path>
      </svg>
    )
  } else {
    return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path
          fill="#eab308"
          fill-opacity="1"
          fill-rule="evenodd"
          stroke="none"
          d="M12 1H1v11h5v5h11V6h-5V1zM2 11V2h9v4H6v5H2zm14 5H7v-4h5V7h4v9z"
        ></path>
      </svg>
    )
  }
}
