import { ClipboardDocumentCheckIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import Button from '~/components/button'

interface CopyButtonProps {
  className?: string
  value: string
  size?: 'sm' | 'md' | 'lg'
}
export default function CopyButton({ className = '', value, size = 'md' }: CopyButtonProps) {
  const [isCopied, setIsCopied] = React.useState(false)

  const copyString = async () => {
    try {
      await navigator.clipboard.writeText(value)
    } catch (error) {
      console.error(error)
    }
  }

  const handleClick = async () => {
    try {
      await copyString()
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    } catch (error) {
      console.error(error)
    }
  }

  const iconSizes = {
    sm: 'h-4 w-4',
    md: 'h-6 w-6',
    lg: 'h-8 w-8',
  }

  return (
    <Button
      className={twMerge(
        'relative -ml-px inline-flex items-center gap-x-1.5 rounded-l-none rounded-r-md px-2 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
        className
      )}
      onClick={async () => await handleClick()}
    >
      {isCopied ? (
        <ClipboardDocumentCheckIcon className={iconSizes[size]} />
      ) : (
        <DocumentDuplicateIcon className={iconSizes[size]} />
      )}
    </Button>
  )
}
