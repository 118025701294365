import { dtrhBitfieldSize } from '../data/dtrh2024'
import { BitField } from '../lib/bitfield'

export interface Friend {
  id: number
  name: string
  bitField: BitField
}

export interface LocalStorageFriend {
  id: number
  name: string
  configurationString: string
}

export function localStorageFriendsToFriends(
  localStorageFriends: LocalStorageFriend[],
  bitFieldSize: number = dtrhBitfieldSize
): Friend[] {
  return localStorageFriends.map((f) => ({
    id: f.id,
    name: f.name,
    bitField: BitField.fromBase64Url(f.configurationString, bitFieldSize),
  }))
}

export function friendToLocalStorageFriend(friend: Friend): LocalStorageFriend {
  return {
    id: friend.id,
    name: friend.name,
    configurationString: friend.bitField.asBase64Url(),
  }
}

export function friendsToLocalStorageFriends(friends: Friend[]): LocalStorageFriend[] {
  return friends.map(friendToLocalStorageFriend)
}

export const mockFriends: LocalStorageFriend[] = [
  {
    id: 1,
    name: '🐙',
    configurationString: '2Spiq4A4AAIAgQUAACAAAAAAAAAAAAAAAA==',
  },
]
