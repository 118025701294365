import { FunnelIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { dtrhStages } from '~/data/dtrh2024'
import type { ActFilters } from '~/hooks/useFilteredActs'
import { useStore } from '~/store'
import { ArtistStar } from './artist-star'
import Button from './button'
import FilterBehaviourIcon from './filter-behaviour-icon'
import { FriendGroupIcon } from './friend-group-icon'
import Sidebar from './sidebar'

interface FiltersProps {
  filters: ActFilters
  setFilters: React.Dispatch<React.SetStateAction<ActFilters>>
}
function Filters({ filters, setFilters }: FiltersProps) {
  const {
    programShowOnlyConfiguredActs,
    setProgramShowOnlyConfiguredActs,
    programShowOnlyFriendlyConfiguredActs,
    setProgramShowOnlyFriendlyConfiguredActs,
    programFilterBehaviour,
    setProgramFilterBehaviour,
  } = useStore((state) => ({
    programShowOnlyConfiguredActs: state.programShowOnlyConfiguredActs,
    setProgramShowOnlyConfiguredActs: state.setProgramShowOnlyConfiguredActs,
    programShowOnlyFriendlyConfiguredActs: state.programShowOnlyFriendlyConfiguredActs,
    setProgramShowOnlyFriendlyConfiguredActs: state.setProgramShowOnlyFriendlyConfiguredActs,
    programFilterBehaviour: state.programFilterBehaviour,
    setProgramFilterBehaviour: state.setProgramFilterBehaviour,
  }))

  return (
    <div className="grid grid-cols-1 gap-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
          Search
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full rounded-md border-0 px-10 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
            placeholder="Search for an artist name"
            value={filters.name}
            onChange={(e) => setFilters((prevFilters) => ({ ...prevFilters, name: e.target.value }))}
          />
          {filters.name && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <button
                onClick={() => setFilters((prevFilters) => ({ ...prevFilters, name: '' }))}
                title="Clear search query"
              >
                <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h3 className="font-semibold">Filter acts from the timetable</h3>
        <p className="text-sm">
          Filter the program list to show only acts you have liked or that your friends have liked.
        </p>
        <div className="flex items-center justify-between gap-2">
          <p className="text-sm font-medium">Show acts you have liked</p>
          <Button onClick={() => setProgramShowOnlyConfiguredActs(!programShowOnlyConfiguredActs)}>
            <ArtistStar className="h-5 w-5" isLiked={programShowOnlyConfiguredActs} />
          </Button>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium">Show acts your friends have liked</p>
          <Button onClick={() => setProgramShowOnlyFriendlyConfiguredActs(!programShowOnlyFriendlyConfiguredActs)}>
            <FriendGroupIcon className="h-5 w-5" isActive={programShowOnlyFriendlyConfiguredActs} />
          </Button>
        </div>
      </div>
      {programShowOnlyConfiguredActs && programShowOnlyFriendlyConfiguredActs && (
        <div className="flex flex-col gap-4">
          <h3 className="font-semibold">Choose filter behaviour</h3>
          <p className="text-sm">
            When showing both your and your friends selected acts, choose to show acts <i>both</i> you and at least one
            of your friends have chosen or show all the acts that you or your friends have chosen.
          </p>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium">Filter behaviour</p>
            <Button
              onClick={() =>
                setProgramFilterBehaviour(programFilterBehaviour === 'intersection' ? 'union' : 'intersection')
              }
              title="Set intersection mode"
            >
              <FilterBehaviourIcon className="h-5 w-5" filterBehaviour={programFilterBehaviour} />
            </Button>
          </div>
        </div>
      )}
      <div>
        <div className="mb-2 flex items-center gap-2">
          <label className="mr-auto block text-sm font-medium leading-6 text-gray-900">Stages</label>
          <Button onClick={() => setFilters((prevFilters) => ({ ...prevFilters, stage: [] }))} title="Clear all stages">
            Clear
          </Button>
          <Button
            onClick={() => setFilters((prevFilters) => ({ ...prevFilters, stage: dtrhStages }))}
            title="Select all stages"
          >
            Select all
          </Button>
        </div>
        {dtrhStages.map((stage) => {
          const inputId = `stage-${stage}`
          const isChecked = filters.stage.includes(stage)
          return (
            <div className="relative mb-2 flex items-start">
              <div className="flex h-6 items-center">
                <input
                  name={inputId}
                  id={inputId}
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => {
                    setFilters((prevFilters) => {
                      if (isChecked) {
                        return {
                          ...prevFilters,
                          stage: prevFilters.stage.filter((s) => s !== stage),
                        }
                      } else {
                        return {
                          ...prevFilters,
                          stage: [...prevFilters.stage, stage],
                        }
                      }
                    })
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-600"
                />
              </div>
              <div className="ml-3 leading-6">
                <label htmlFor={inputId} className="font-medium text-gray-900">
                  {stage}
                </label>{' '}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function calculateNumberOfActiveFilter(filters: ActFilters): number {
  let count = 0

  if (filters.name !== undefined && filters.name !== '') {
    count++
  }

  if (filters.stage.length !== dtrhStages.length) {
    count++
  }

  if (filters.liked) {
    count++
  }

  if (filters.likedByFriends) {
    count++
  }

  return count
}

interface FilterSidebarProps {
  filters: ActFilters
  setFilters: React.Dispatch<React.SetStateAction<ActFilters>>
}

function FilterSidebar({ filters, setFilters }: FilterSidebarProps) {
  const [sidebarOpen, setSidebarOpen] = React.useState(false)
  const numberOfFilters = calculateNumberOfActiveFilter(filters)

  return (
    <>
      <Button
        className="flex items-center gap-2 px-2 py-1"
        onClick={() => setSidebarOpen(true)}
        title="Open filter sidebar"
      >
        {numberOfFilters > 0 && (
          <span className="rounded-full bg-yellow-600 px-2 py-0 text-xs font-semibold text-white">
            {numberOfFilters}
          </span>
        )}
        <FunnelIcon className="h-4 w-4 sm:h-5 sm:w-5" />
      </Button>
      <Sidebar isOpen={sidebarOpen} closeSidebar={() => setSidebarOpen(false)}>
        <h2 className="mb-2 text-lg font-semibold">Filters</h2>
        <Filters setFilters={setFilters} filters={filters} />
      </Sidebar>
    </>
  )
}

export default FilterSidebar
