import React from 'react'

import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

interface SidebarProps {
  children: React.ReactNode
  closeSidebar: () => void
  isOpen: boolean
}

function Sidebar({ children, isOpen, closeSidebar }: SidebarProps) {
  return (
    <Dialog className="relative z-50" open={isOpen} onClose={closeSidebar}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 flex">
        <DialogPanel
          transition
          className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
        >
          <TransitionChild>
            <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
              <button type="button" className="-m-2.5 p-2.5" onClick={closeSidebar}>
                <span className="sr-only">Close sidebar</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <div className="w-full max-w-2xl bg-white p-4 overflow-auto">
            {/* Sidebar component contents */}
            {children}
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}

export default Sidebar
