import React from 'react'

export default function NoFriends() {
  return (
    <div className="self-start pt-8 sm:mt-8">
      <p className="text-lg font-semibold">You have not added any friends yet.</p>
      <p className="mb-2 text-xs text-gray-500">Must be nice. You probably get all sorts of things done.</p>
      <p>Ask someone to share their configuration and add a friend to get started.</p>
    </div>
  )
}
